// ./services/TargetDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDocs, orderBy, query,
    setDoc,
    updateDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import TimelineDataService from "@/services/TimelineDataService";

class TargetDataService {
    async AddTarget(dataObj) {
        const colRef = collection(db, store.state.customer + 'targets');

        await addDoc(colRef, dataObj)
            .then((docRef) => {
                const historyObj = {
                    dossier_id: dataObj.dossier_id,
                    patient_id: dataObj.patient_id,
                    comments: 'Doel aangemaakt',
                    date: Date.now(),
                    score: dataObj.score,
                    target_score: dataObj.target_score,
                }
                this.AddTargetHistory(docRef.id, historyObj)
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateTarget(targetId, dataObj) {
        const docRef = doc(db, store.state.customer + 'targets', targetId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async UpdateTargetStatus(targetId, status) {
        const docRef = doc(db, store.state.customer + 'targets', targetId)

        await updateDoc(docRef, { "state": status })
    }
    async RemoveTarget(targetId) {
        const docRef = doc(db, store.state.customer + 'targets', targetId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Target deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetTargetHistory(targetId) {

        const targetHistoryRef = collection(db, store.state.customer + 'targets/' + targetId + '/history')
        const snapshot = await getDocs(targetHistoryRef)

        if (snapshot.empty) {
            return []
        }

        let targetHistory = []
        snapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id

            targetHistory.push(data)
        })

        return Promise.resolve(targetHistory)
    }
    async AddTargetHistory(targetId, dataObj) {
        const colRef = collection(db, store.state.customer + 'targets/' + targetId + '/history');

        await addDoc(colRef, dataObj)
            .then((docRef) => {
                const timelineObject = {
                    'patient_id': dataObj.patient_id,
                    'dossier_id': dataObj.dossier_id,
                    'target_id': docRef.id,
                    'date': Date.now(),
                    'timeline_type': 'evaluation',
                }
                TimelineDataService.AddTimelineItem(timelineObject)
            })
            .catch(e => {
                console.log(e);
            });
    }
    async RemoveTargetHistory(targetId, targetHistoryId) {
        const docRef = doc(db, store.state.customer + 'targets/' + targetId + '/history', targetHistoryId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Target history deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetTargetsFromDossier(dossierId) {
        let targets = []
        const targetQuery = await getDocs(query(collection(db, store.state.customer + 'targets'), where('dossier_id', '==', dossierId), orderBy('main_goal', 'desc')));
        targetQuery.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id
            data.goal_reached = (Number(data.score) >= Number(data.target_score))

            targets.push(data)
        })
        return Promise.resolve(targets)
    }
}

export default new TargetDataService();