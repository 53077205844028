import ClientsStore from "@/store/modules/ClientsStore";
import ClientDataService from "@/services/ClientDataService";

class ClientStoreDataService {
    async GetDossierDescription(clientId, dossierId) {
        let dossierDescription = ''

        const dossiers = await ClientDataService.GetDossiersFromClient(clientId)

        dossiers.forEach((dossier) => {
            if (dossier.id === dossierId) {
                dossierDescription = dossier.description
            }
        })

        return dossierDescription
    }
    async GetEmployeeDescription(employeeId) {
        let employeeName = ''
        ClientsStore.state.employees.forEach((employee) => {
            if (employee.id === employeeId) {
                employeeName = employee.first_name + ' ' + employee.last_name
            }
        })

        return employeeName
    }
    async GetLocationDescription(locationId) {
        let description = ''
        ClientsStore.state.locations.forEach((location) => {
            if (location.id === locationId) {
                description = location.description
            }
        })

        return description
    }
    async GetLocationColor(locationId) {
        let color = ''

        ClientsStore.state.locations.forEach((location) => {
            if (location.id === locationId) {
                color = location.calendar_color
            }
        })

        return color
    }
}

export default new ClientStoreDataService()