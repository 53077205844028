// ./services/PlannerDataService

import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import ClientDataService from "@/services/ClientDataService";
import DossierDataService from "@/services/DossierDataService";
import SessionDataService from "@/services/SessionDataService";
import ProductDataService from "@/services/ProductDataService";
import ProductInvoicingDataService from "@/services/ProductInvoicingDataService";

class PlannerDataService {
    async GetOnlineProductCategories(customerId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const productRef = collection(db, 'customers/' + customerId + '/products',)
                const productQuery = query(productRef, where('online', '==', true), orderBy('product_category'), orderBy('description'))
                const snapShot = await getDocs(productQuery);

                let products = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    products.push(data)
                })

                resolve(products.sort())

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }

    async GetOnlineEmployees(customerId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const employeesRef = collection(db, 'customers/' + customerId + '/employees',)
                const employeesQuery = query(employeesRef, orderBy('last_name'))
                const snapShot = await getDocs(employeesQuery);

                let employees = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    data.schedule.forEach((item) => {
                        if (item.online) {
                            employees.push(data)
                        }
                    })
                })

                resolve(employees.sort())

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetAllOnlineSessions(employeeId) {
        let onlineSessions = []

        let onlineSessionQuery = null

        if (employeeId === '') {
            onlineSessionQuery = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('online_status', '==', 'created'), orderBy('startDate', 'desc')))
        } else {
            onlineSessionQuery = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('employee_id', '==', employeeId), where('online_status', '==', 'created'), orderBy('startDate', 'desc')))
        }
        onlineSessionQuery.forEach((doc) => {
            const data = doc.data()
            data.id = doc.id
            onlineSessions.push(data)
        })

        if (employeeId === '') {
            onlineSessionQuery = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('online_status', '==', 'done'), orderBy('startDate', 'desc')))
        } else {
            onlineSessionQuery = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('employee_id', '==', employeeId), where('online_status', '==', 'done'), orderBy('startDate', 'desc')))
        }
        onlineSessionQuery.forEach((doc) => {
            const data = doc.data()
            data.id = doc.id
            onlineSessions.push(data)
        })

        return onlineSessions.sort((a, b) => new Date(b.startDate.seconds) - new Date(a.startDate.seconds))
    }
    async GetOnlineSessionsFromEmployee(employeeId) {
        let onlineSessions = []

        let onlineSessionQuery = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('online_status', '==', 'created'), where('employee_id', '==', employeeId), orderBy('startDate', 'desc')))
        onlineSessionQuery.forEach((doc) => {
            const data = doc.data()
            data.id = doc.id
            onlineSessions.push(data)
        })

        return onlineSessions.sort((b, a) => new Date(b.startDate.seconds) - new Date(a.startDate.seconds))
    }
    async GetSessionsOnDateFromEmployee(employeeId, startDate, endDate) {
        let sessions = []

        let sessionQuery = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('startDate', '>=', startDate), where('startDate', '<=', endDate), where('employee_id', '==', employeeId), orderBy('startDate', 'desc')))
        sessionQuery.forEach((doc) => {
            const data = doc.data()
            data.id = doc.id
            sessions.push(data)
        })

        return sessions.sort((b, a) => new Date(b.startDate.seconds) - new Date(a.startDate.seconds))
    }
    async ProcessOnlineSession(onlineSession, sessionId, selectedClientId) {
        let clientId = ''
        let dossierId = ''

        if (selectedClientId === '') {
            // Client
            const clientDataObj = {
                gender: 'unknown',
                first_name: onlineSession.online_info.first_name,
                initials: '',
                last_name: onlineSession.online_info.last_name,
                birthdate: '',
                street: '',
                house_number: '',
                house_number_suffix: '',
                postal_code: '',
                city: '',
                phone: '',
                mobile: onlineSession.online_info.mobile,
                email: onlineSession.online_info.email,
                email_invoice: false,
                email_appointment_confirmation: false,
                bsn: '',
                archive: false,
                bijzonderheden: '',
                show_bijzonderheden: false,
                general_practitioner_id: '',
                medication: '',
                stimulants: '',
            }
            clientId = await ClientDataService.AddClient(clientDataObj)

            const dossierDataObj = {
                'dossier_type': '',
                'patient_id': clientId,
                'employee_id': onlineSession.employee_id,
            }
            dossierId = await DossierDataService.CreateNewDossier(dossierDataObj)
        } else {
            clientId = selectedClientId

            const client = await ClientDataService.GetClient(clientId)
            dossierId = client.current_dossier_id
        }

        const client = await ClientDataService.GetClient(clientId)

        // Update session
        const sessionObj = onlineSession
        sessionObj.patient_id = clientId
        sessionObj.patient_name = client.first_name + ' ' + client.last_name
        sessionObj.dossier_id = dossierId
        sessionObj.dossier_description = ''
        sessionObj.online_status = 'done'

        await SessionDataService.UpdateSessionFields(sessionId, sessionObj)

        // Create product invoicing
        const productObj = await ProductDataService.GetProduct(sessionObj.product_id)
        await ProductDataService.GetProductRates(sessionObj.product_id).then((result) => {
            productObj.rate = result[0]
        })

        const productInvoicingObj = {
            amount: 1,
            amount_excl_vat: this.countAmount(productObj, productObj.rate.amount_excl_vat, sessionObj.length),
            amount_incl_vat: this.countAmount(productObj, productObj.rate.amount_incl_vat, sessionObj.length),
            amount_vat: this.countAmount(productObj, productObj.rate.amount_vat, sessionObj.length),
            creation_date: sessionObj.startDate,
            date: new Date(sessionObj.startDate.seconds * 1000).toLocaleDateString(),
            dossier_id: dossierId,
            financial_status: 'not_invoiced',
            payment_status: 'not_paid',
            patient_id: clientId,
            patient_name: sessionObj.patient_name,
            postponed: false,
            product_description: productObj.description,
            product_id: sessionObj.product_id,
            product_type: 'appointment',
            vat_percentage: productObj.rate.vat_percentage,
            employee_id: sessionObj.employee_id,
            location_id: sessionObj.location_id,
            session_id: sessionId,
        }
        await ProductInvoicingDataService.AddProductInvoicing(productInvoicingObj)
    }
    countAmount(productRate, amount, length) {
        if (productRate.rate.invoice_method === 'time') {
            return (amount / 60 * length)
        } else {
            return amount
        }
    }
}

export default new PlannerDataService();