import { reactive } from 'vue'
import ClientDataService from "@/services/ClientDataService";
import ProductInvoicingDataService from "@/services/ProductInvoicingDataService";
import store from '@/store'

const state = reactive({
    active: false,
    id: '',
    patientId: '',
    patient: null,
    productInvoicing: null,
})

// -----------------------------------
// Private Methods
// -----------------------------------
let close // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = () => {
    state.active = true
    return dialogPromise()
}
const reset = () => {
    state.active = false
}

// -----------------------------------
// Public interface
// -----------------------------------

const productInvoicingModal = {
    get state() {
        return state
    },
    async show(id, patientId) {
        await store.dispatch('ClientsStore/getProducts', 'product')
        await store.dispatch('ClientsStore/getAppointments', 'appointment')

        state.patientId = patientId
        const result = await ClientDataService.GetClient(patientId)
        state.patient = result

        state.id = id
        if (id !== '') {
            ProductInvoicingDataService.GetProductInvoicing(id).then((result) => {
                state.productInvoicing = result
            })
        } else {
            state.productInvoicing = {
                amount_excl_vat: 0,
                amount_incl_vat: 0,
                amount_vat: 0,
                amount: 1,
                product_description: '',
                product_id: '',
                postponed: false,
                vat_percentage: 0,
                employee_id: store.state.currentEmployeeId,
                location_id: store.state.ClientsStore.locations[0].id
            }
        }

        return open()
    },
    cancel() {
        close(false)
        store.dispatch('updateProductInvoicing')
        reset()
    },
}

export default productInvoicingModal