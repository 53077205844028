const questionnaire_bps_v1 = {
    'id': 'BPS_v1',
    'description': 'Boundary Protection Scale (BPS)',
    'short': 'BPS',
    'questions': [
        {
            'number': 1,
            'info': 'Betrek onderstaande uitspraken op de afgelopen maand en vink het antwoord aan dat het beste bij je past.',
            'description': 'Ik zeg liever "ja" dan "nee", als ik daarmee onenigheid kan vermijden.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'Anderen kunnen mij mijn plezier ontnemen.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Ik ben schrikachtig.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Ik heb er spijt van als ik te veel over mijzelf verteld heb.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'Ik wil anderen niet voor het hoofd stoten door “nee” te zeggen.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': 'Met mij mag het goed gaan, ook als iemand anders het op dat ogenblik moeilijk heeft.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 5,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 4,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 2,
                    'description': 'Vaak',
                },
                {
                    'value': 1,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': 'Ik kan op het juiste moment het gespreksonderwerp veranderen.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 5,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 4,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 2,
                    'description': 'Vaak',
                },
                {
                    'value': 1,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 8,
            'info': '',
            'description': 'Ik kan ad rem zijn.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 5,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 4,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 2,
                    'description': 'Vaak',
                },
                {
                    'value': 1,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 9,
            'info': '',
            'description': 'Ik denk: “ik weet wie het zegt”.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 5,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 4,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 2,
                    'description': 'Vaak',
                },
                {
                    'value': 1,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 10,
            'info': '',
            'description': 'Er is niet veel voor nodig om een “nee” van mij in een “ja” te veranderen.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 11,
            'info': '',
            'description': 'Ik beantwoord vragen die ik eigenlijk helemaal niet wil beantwoorden.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 12,
            'info': '',
            'description': 'Ik neem te veel opdrachten aan.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 13,
            'info': '',
            'description': 'Ik neem een negatieve stemming in de groep over.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 14,
            'info': '',
            'description': 'Ik heb er last van als vrienden, ouders of collega’s ruzie hebben.',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 1,
                    'description': 'Bijna nooit',
                },
                {
                    'value': 2,
                    'description': 'Zelden',
                },
                {
                    'value': 3,
                    'description': 'Soms',
                },
                {
                    'value': 4,
                    'description': 'Vaak',
                },
                {
                    'value': 5,
                    'description': 'Haast altijd',
                },
            ],
            'score': '',
        },

    ],
    'scores': [
        {
            'description': 'Totaalscore',
            'formule': 'S1+S2+S3+S4+S5+S6+S7+S8+S9+S10+S11+S12+S13+S14',
            'min': 14,
            'max': 70,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Zelfvertrouwen',
            'goal': 'Ervaren en aangeven van je eigen grens',
            'operation': '...',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_bps_v1