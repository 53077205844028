// ./services/LocationsDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class LocationsDataService {
    async AddLocation(dataObj) {
        const colRef = collection(db, store.state.customer + 'locations');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateLocation(locationId, dataObj) {
        const docRef = doc(db, store.state.customer + 'locations', locationId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveLocation(locationId) {
        const docRef = doc(db, store.state.customer + 'locations', locationId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetLocation(locationId) {
        if (locationId === '') {
            return null
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'locations', locationId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetLocations() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const locationsRef = collection(db, store.state.customer + 'locations', )
                const locationsQuery = query(locationsRef, orderBy('description'))
                const snapShot = await getDocs(locationsQuery);

                let locations = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    locations.push(data)
                })

                resolve(locations)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new LocationsDataService();