const questionnaire_qbpds_v1 = {
    'id': 'QBPDS_v1',
    'description': 'Quebec Back Pain Disability Scale (QBPDS)',
    'short': 'QBPDS',
    'questions': [
        {
            'number': 1,
            'info': 'Deze vragenlijst gaat over de manier waarop uw rugklachten uw dagelijks leven beïnvloeden. ' +
                'Mensen met rugklachten kunnen moeite hebben met het uitvoeren van sommige dagelijkse activiteiten. ' +
                'Wij willen graag weten of u moeite heeft met het uitvoeren van onderstaande activiteiten vanwege uw rugklachten. ' +
                'Wij willen u verzoeken bij alle vragen een antwoord te selecteren dat het meest uw situatie wergeeft. ' +
                'Wanneer u één of meerdere van de beschreven activiteiten eigenlijk nooit uitvoert, beeld u zich dan in hoeveel ' +
                'moeite het u zou kosten wanneer u de activiteiten op dit moment zou moeten uitvoeren.\n\n' +
                'Heeft u vandaag moeite om de volgende activiteiten uit te voeren vanwege uw rugklachten?',
            'description': 'Opstaan uit bed',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'De hele nacht slapen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Omdraaien in bed',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Autorijden',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': '20 tot 30 minuten (achter elkaar) staan',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': 'Enkele uren in een stoel zitten',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': 'Een trap oplopen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 8,
            'info': '',
            'description': 'Een klein eindje lopen (300-400 m)',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 9,
            'info': '',
            'description': 'Enkele kilometers lopen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 10,
            'info': '',
            'description': 'Naar een hoge plank reiken',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 11,
            'info': '',
            'description': 'Een bal werpen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 12,
            'info': '',
            'description': 'Een eindje hardlopen (± 100 m)',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 13,
            'info': '',
            'description': 'Iets uit de koelkast pakken',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 14,
            'info': '',
            'description': 'Het bed opmaken',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 15,
            'info': '',
            'description': 'Sokken (of panty) aantrekken',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 16,
            'info': '',
            'description': 'Voorover buigen om bijv. de badkuip of wc schoon te maken',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 17,
            'info': '',
            'description': 'Een stoel verplaatsen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 18,
            'info': '',
            'description': 'Een zware deur opentrekken of open duwen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 19,
            'info': '',
            'description': 'Dragen van 2 tassen met boodschappen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
        {
            'number': 20,
            'info': '',
            'description': 'Een zware koffer optillen en dragen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Totaal geen moeite',
                },
                {
                    'value': 1,
                    'description': 'Nauwelijks moeite',
                },
                {
                    'value': 2,
                    'description': 'Enige moeite',
                },
                {
                    'value': 3,
                    'description': 'Veel moeite',
                },
                {
                    'value': 4,
                    'description': 'Zeer veel moeite',
                },
                {
                    'value': 5,
                    'description': 'Niet in staat',
                },
            ],
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Totaalscore',
            'formule': 'S1+S2+S3+S4+S5+S6+S7+S8+S9+S10+S11+S12+S13+S14+S15+S16+S17+S18+S19+S20',
            'min': 0,
            'max': 100,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Beperkingen',
            'goal': 'Verminderen van beperkingen in ADL',
            'operation': 'Actieve en passieve mobilisatie',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_qbpds_v1