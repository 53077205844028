/*
invoice
-	id
-   description
-	invoice_date
-	invoice_number
-   invoice_type (Debet/Credit)
-	payment_status
-	export_status
-	client_id

invoice_specification
-	invoice_id
-	description
-	number
-	amount_excl_vat
-   amount_incl_vat
-	amount_vat
-	vat_percentage
-   status
-	session_id
 */

import {
    collection,
    addDoc,
    doc,
    updateDoc,
    deleteDoc, getDoc, query, where, getDocs,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import SessionDataService from "@/services/SessionDataService";

class ProductInvoicingDataService {
    async GetProductInvoicingAll() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const productsRef = collection(db, store.state.customer + 'product_invoicing')
                const productsQuery = query(productsRef)
                const snapShot = await getDocs(productsQuery)

                let productInvoicing = []
                snapShot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    data.client_id = data.patient_id
                    data.client_name = data.patient_name

                    productInvoicing.push(data)
                })

                resolve(productInvoicing)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })

    }
    async GetProductInvoicing(productInvoicingId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'product_invoicing', productInvoicingId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetProductInvoicingFromSession(sessionId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function(resolve, reject) {
            try {
                const productsRef = collection(db, store.state.customer + 'product_invoicing')
                const productsQuery = query(productsRef,
                    where('session_id', '==', sessionId))
                const snapShot = await getDocs(productsQuery)

                let productInvoicing = []
                snapShot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    data.client_id = data.patient_id
                    data.client_name = data.patient_name

                    productInvoicing.push(data)
                })

                resolve(productInvoicing)
            } catch(e) {
                reject(e)
            }
        })
    }
    async GetProductInvoicingFromClient(clientId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function(resolve, reject) {
            try {
                const productsRef = collection(db, store.state.customer + 'product_invoicing')
                const productsQuery = query(productsRef,
                    where('patient_id', '==', clientId))
                const snapShot = await getDocs(productsQuery)

                let productInvoicing = []
                snapShot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    data.client_id = data.patient_id
                    data.client_name = data.patient_name

                    productInvoicing.push(data)
                })

                resolve(productInvoicing)
            } catch(e) {
                reject(e)
            }
        })
    }
    async AddProductInvoicing(dataObj) {
        const productInvoicingRef = collection(db, store.state.customer + 'product_invoicing')

        await addDoc(productInvoicingRef, dataObj)
            .catch((e) => {
                console.log(e)
            })
    }
    async UpdateProductInvoicing(productInvoicingId, dataObj) {
        const docRef = doc(db, store.state.customer + 'product_invoicing', productInvoicingId)

        await updateDoc(docRef, dataObj)
    }
    async RemoveProductInvoicing(productInvoicingId) {
        const docRef = doc(db, store.state.customer + 'product_invoicing', productInvoicingId)
        await deleteDoc(docRef)
            .then(() => {
                console.log('Product invoicing deleted')
            })
            .catch((e) => {
                console.log(e)
            })
    }
    async SetProductInvoicingPostponeStatus(productInvoicingId, status) {
        const dataObj = {
            'postponed': status,
        }
        await this.UpdateProductInvoicing(productInvoicingId, dataObj)

        // Session update
        const productInvoicing = await this.GetProductInvoicing(productInvoicingId)
        if (productInvoicing) {
          const sessionObj = {
              'financial_status': status === true ? 'postponed' : 'not_invoiced',
          }
            await SessionDataService.UpdateSession(productInvoicing.session_id, sessionObj, productInvoicing)
        }
    }
    async SetProductInvoicingNotInvoicable(productInvoicingId) {
        const dataObj = {
            'financial_status': 'do_not_invoice',
        }
        const docRef = doc(db, store.state.customer + 'product_invoicing', productInvoicingId)
        await updateDoc(docRef, dataObj)

        // Session update
        const productInvoicing = await this.GetProductInvoicing(productInvoicingId)
        if (productInvoicing) {
            await SessionDataService.UpdateSessionFields(productInvoicing.session_id, dataObj)
        }
    }
    async SetProductInvoicingInvoicable(productInvoicingId) {
        const dataObj = {
            'financial_status': 'not_invoiced',
        }
        const docRef = doc(db, store.state.customer + 'product_invoicing', productInvoicingId)
        await updateDoc(docRef, dataObj)

        // Session update
        const productInvoicing = await this.GetProductInvoicing(productInvoicingId)
        if (productInvoicing) {
            await SessionDataService.UpdateSessionFields(productInvoicing.session_id, dataObj)
        }
    }
}

export default new ProductInvoicingDataService();