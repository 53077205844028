const insurers = {
    year: 2024,
    labels: [
        {
            id: 'asr',
            uzovi: '0403',
            description: 'A.S.R.',
            policies: [
                {
                    description: 'Start',
                    compensation: '€ 45,- per dag maximaal € 100,- per kalenderjaar',
                },
                {
                    description: 'Extra',
                    compensation: '€ 45,- per dag maximaal € 250,- per kalenderjaar',
                },
                {
                    description: 'Uitgebreid',
                    compensation: '€ 45,- per dag maximaal € 500- per kalenderjaar',
                },
            ],
        },
        {
            id: 'aevitae',
            uzovi: '3328',
            description: 'Aevitae',
            policies: [
                {
                    description: 'Laef! 1',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Laef! 2',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Laef! 3',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Laef! 4',
                    compensation: 'maximaal € 50,- per dag voor een consult tot € 550,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'anderzorg',
            uzovi: '3333',
            description: 'Anderzorg N.V.',
            policies: [
                {
                    description: 'Buitenland & Tandongeval',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 200,- per kalenderjaar',
                },
                {
                    description: 'Flex',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Fysiotherapie',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'cz',
            uzovi: '7719',
            description: 'CZ Zorgverzekeringen N.V.',
            policies: [
                {
                    description: '50 Plus',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Basis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Gezinnen',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Jongeren',
                    compensation: 'maximaal € 30,- per dag voor een consult tot € 200,- per kalenderjaar',
                },
                {
                    description: 'Plus',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'czdirectnl',
            uzovi: '7719',
            description: 'CZdirect.nl',
            policies: [
                {
                    description: 'Fysio 4',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Fysio 9',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Wereld',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'christelijk',
            uzovi: '3311',
            description: 'De Christelijke Zorgverzekeraar',
            policies: [
                {
                    description: 'Principe Polis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Smallpolis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Mediumpolis',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 320,- per kalenderjaar',
                },
                {
                    description: 'Largepolis',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 440,- per kalenderjaar',
                },
                {
                    description: 'Extra Largepolis',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 600,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'defriesland',
            uzovi: '3358',
            description: 'De Friesland Zorgverzekeraar',
            policies: [
                {
                    description: 'AV Budget',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'AV Doorstap',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'AV Extra',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 400,- per kalenderjaar',
                },
                {
                    description: 'AV Instap',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'AV Opstap',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'AV Optimaal',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 600,- per kalenderjaar',
                },
                {
                    description: 'AV Standaard',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 200,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'ditzo',
            uzovi: '0403',
            description: 'Ditzo',
            policies: [
                {
                    description: 'Zorg Basis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Bewust',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Beter',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'dsw',
            uzovi: '',
            description: 'DSW Zorgverzekeraar',
            policies: [
                {
                    description: 'AV Compact',
                    compensation: 'maximaal € 25,- per behandeling tot € 250,- per kalenderjaar',
                },
                {
                    description: 'AV Standaard',
                    compensation: 'maximaal € 35,- per behandeling tot 9 behandelingen per kalenderjaar',
                },
                {
                    description: 'AV Student',
                    compensation: 'maximaal € 25,- per behandeling tot € 450,- per kalenderjaar',
                },
                {
                    description: 'AV Top',
                    compensation: 'maximaal € 30,- per behandeling tot € 450,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'hema',
            uzovi: '',
            description: 'Hema',
            policies: [
                {
                    description: 'Aanvullend 1',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Aanvullend 2',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Aanvullend 3',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'hollandzorg',
            uzovi: '',
            description: 'Holland Zorg',
            policies: [
                {
                    description: 'Start',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Plus',
                    compensation: 'maximaal € 40,- per dag tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Top',
                    compensation: 'maximaal € 40,- per dag tot € 500,- per kalenderjaar',
                },
                {
                    description: 'No Risk',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'No Risk II',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'A.S.R.',
            uzovi: '',
            description: 'A.S.R.',
            policies: [
                {
                    description: 'Bril en lenzen module',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Buitenland',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ZorgBasis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ZorgBeter',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ZorgGoed',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'interpolis',
            uzovi: '',
            description: 'Interpolis',
            policies: [
                {
                    description: 'Zorgactief',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorgcompact',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'intwente',
            uzovi: '',
            description: 'InTwente zorgverzekeraar',
            policies: [
                {
                    description: 'AV Compact',
                    compensation: 'maximaal € 25,- per behandeling tot € 250,- per kalenderjaar',
                },
                {
                    description: 'AV Standaard',
                    compensation: 'maximaal € 35,- per behandeling tot € 450,- per kalenderjaar',
                },
                {
                    description: 'AV Student',
                    compensation: 'maximaal € 35,- per behandeling tot € 450,- per kalenderjaar',
                },
                {
                    description: 'AV Top',
                    compensation: 'maximaal € 30,- per behandeling tot € 450,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'iza',
            uzovi: '3334',
            description: 'IZA-VNG',
            policies: [
                {
                    description: 'Extra Zorg Basis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra Zorg Instap',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra Zorg 1',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra Zorg 2',
                    compensation: 'maximaal € 40,- per behandeling tot € 500,- per kalenderjaar',
                },
                {
                    description: 'Extra Zorg 3',
                    compensation: 'maximaal € 40,- per behandeling tot € 650,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'voordezorg',
            uzovi: '9015',
            description: 'VGZ voor de zorg N.V.',
            policies: [
                {
                    description: 'Zorg voor de Zorg',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg voor de Zorg Instap',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg voor de Zorg Budget',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Beter',
                    compensation: 'maximaal € 25,- per behandeling tot € 250,- per kalenderjaar',
                },
                {
                    description: 'Tand 1',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg voor de Zorg Compleet',
                    compensation: 'maximaal € 40,- per dag tot 10 behandelingen per kalenderjaar',
                },
            ],
        },
        {
            id: 'just',
            uzovi: '',
            description: 'Just',
            policies: [
                {
                    description: 'Just Live',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'menzis',
            uzovi: '',
            description: 'Menzis',
            policies: [
                {
                    description: 'Aanvullend',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra Aanvullend',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ExtraVerzorgd 1',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ExtraVerzorgd 2',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 300,- per kalenderjaar',
                },
                {
                    description: 'ExtraVerzorgd 3',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 500,- per kalenderjaar',
                },
                {
                    description: 'JongerenVerzorgd',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 200,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'nn',
            uzovi: '9664',
            description: 'Nationale Nederlanden',
            policies: [
                {
                    description: 'Compleet',
                    compensation: 'maximaal € 50,- per dag voor een consult tot € 500,- per kalenderjaar',
                },
                {
                    description: 'Extra',
                    compensation: 'maximaal € 50,- per dag voor een consult tot € 250,- per kalenderjaar',
                },
                {
                    description: 'Instap',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Jij & Compact',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Jij & Gemak',
                    compensation: 'maximaal € 25,- per dag voor een consult tot € 250,- per kalenderjaar',
                },
                {
                    description: 'Jij & Vitaal',
                    compensation: 'maximaal € 50,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Start',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Basis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Zeker',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Zeker & Tand',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Zeker Plus',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'ohra',
            uzovi: '',
            description: 'OHRA',
            policies: [
                {
                    description: 'Aanvullend',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 200,- per kalenderjaar',
                },
                {
                    description: 'Compact',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra Aanvullend',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Fysio Meenemen',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Sterk',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Uitgebreid',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 500,- per kalenderjaar',
                },
                {
                    description: 'Uitgebreid Fysio Meenemen',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 500,- per kalenderjaar',
                },
                {
                    description: 'Zorgverzekerd op reis',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'onvz',
            uzovi: '',
            description: 'ONVZ Zorgverzekeraar',
            policies: [
                {
                    description: 'Bewuste Keuze extra',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Bewuste Keuze op reis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Bewuste Keuze start',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'OntzorgPlus',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Vrije Keuze Benfit',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Vrije Keuze Optifit',
                    compensation: 'maximaal € 50,- per dag voor een consult tot € 550,- per kalenderjaar',
                },
                {
                    description: 'Vrije Keuze Startfit',
                    compensation: 'maximaal € 30,- per dag voor een consult tot € 100,- per kalenderjaar',
                },
                {
                    description: 'Vrije Keuze Topfit',
                    compensation: 'maximaal € 65,- per dag voor een consult tot € 1.000,- per kalenderjaar',
                },
                {
                    description: 'Wereldfit',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'politie',
            uzovi: '',
            description: 'Politie Zorgpolis',
            policies: [
                {
                    description: 'Jongeren PZP',
                    compensation: 'max € 30,- per dag voor een behandeling tot € 200,- per kalenderjaar',
                },
                {
                    description: 'Basis PZP',
                    compensation: 'max € 25,- per dag voor een behandeling tot € 225,- per kalenderjaar',
                },
                {
                    description: 'Plus PZP',
                    compensation: 'max € 25,- per dag voor een behandeling tot € 450,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'salland',
            uzovi: '',
            description: 'Salland',
            policies: [
                {
                    description: 'Extra',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Plus',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Start',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Top',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 500,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'stadholland',
            uzovi: '',
            description: 'Stad Holland Zorgverzekeraar',
            policies: [
                {
                    description: 'Compact AV',
                    compensation: 'maximaal € 25,- per behandeling tot € 250,- per kalenderjaar',
                },
                {
                    description: 'Extra Uitgebreide AV',
                    compensation: 'maximaal € 35,- per behandeling tot 9 behandelingen per kalenderjaar',
                },
                {
                    description: 'Standaard AV',
                    compensation: 'maximaal € 35,- per behandeling tot 9 behandelingen per kalenderjaar',
                },
                {
                    description: 'Uitgebreide AV',
                    compensation: 'maximaal € 35,- per behandeling tot 9 behandelingen per kalenderjaar',
                },
            ],
        },
        {
            id: 'umc',
            uzovi: '',
            description: 'UMC Zorgverzekering',
            policies: [
                {
                    description: 'UMC Extra Zorg Basis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'UMC Extra Zorg Instap',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'UMC Extra Zorg 1',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'UMC Extra Zorg 2',
                    compensation: 'maximaal € 40,- per behandeling tot € 300,- per kalenderjaar',
                },
                {
                    description: 'UMC Extra Zorg 3',
                    compensation: 'maximaal € 40,- per behandeling tot € 500,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'uc',
            uzovi: '',
            description: 'United Consumers',
            policies: [
                {
                    description: 'UC ZOrgBasis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'UC ZorgCombi',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'UC ZorgStart',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ZorgBasis',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ZorgZeker 1 (incl. Tand Ongevallen)',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'ZorgZeker 2 (incl. Tand Ongevallen)',
                    compensation: 'maximaal € 40,- per dag tot € 250,- per kalenderjaar',
                },
                {
                    description: 'ZorgZeker 3 (incl. Tand Ongevallen)',
                    compensation: 'maximaal € 40,- per dag tot € 300,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'unive',
            uzovi: '',
            description: 'Unive',
            policies: [
                {
                    description: 'Aanvullend Best',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 500,- per kalenderjaar',
                },
                {
                    description: 'Aanvullend Beter',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 300,- per kalenderjaar',
                },
                {
                    description: 'Aanvullend Goed',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Budget pakket',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Budget Plus pakket',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Zorg Budget',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'vgz',
            uzovi: '',
            description: 'VGZ',
            policies: [
                {
                    description: 'VGZ Aanvullend Best',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 500,- per kalenderjaar',
                },
                {
                    description: 'VGZ Aanvullend Beter',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 300,- per kalenderjaar',
                },
                {
                    description: 'VGZ Aanvullend Goed',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'VGZ Aanvullend Instap',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'vgzbewuzt',
            uzovi: '',
            description: 'VGZ bewuzt',
            policies: [
                {
                    description: 'bewuzt actief',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'bewuzt fit',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'bewuzt gezond',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'vvaa',
            uzovi: '',
            description: 'VvAA',
            policies: [
                {
                    description: 'Ontzorgplus',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Optimaal',
                    compensation: 'maximaal € 50,- per dag voor een consult tot € 550,- per kalenderjaar',
                },
                {
                    description: 'Plus',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 350,- per kalenderjaar',
                },
                {
                    description: 'Start',
                    compensation: 'maximaal € 30,- per dag voor een consult tot € 100,- per kalenderjaar',
                },
                {
                    description: 'Student',
                    compensation: 'maximaal € 30,- per dag voor een consult tot € 100,- per kalenderjaar',
                },
                {
                    description: 'Top',
                    compensation: 'maximaal € 65,- per dag voor een consult tot € 1.000,- per kalenderjaar',
                },
                {
                    description: 'Excellent',
                    compensation: 'maximaal € 75,- per dag voor een consult tot € 1500,- per kalenderjaar',
                },
                {
                    description: 'Zorg op reis',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'zekur',
            uzovi: '',
            description: 'Zekur',
            policies: [
                {
                    description: 'Aanvullend ZEKUR Buitenland',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Aanvullend ZEKUR Fysiotherapie',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Extra ZEKUR Zorg',
                    compensation: 'maximaal € 25,- per dag voor een consult tot € 250,- per kalenderjaar',
                },
            ],
        },
        {
            id: 'zk',
            uzovi: '',
            description: 'Zilveren Kruis',
            policies: [
                {
                    description: 'Aanvullend 1 ster',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'Aanvullend 2 sterren',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 250,- per kalenderjaar',
                },
                {
                    description: 'Aanvullend 3 sterren',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 450,- per kalenderjaar',
                },
                {
                    description: 'Aanvullend 4 sterren',
                    compensation: 'maximaal € 40,- per dag voor een consult tot € 650,- per kalenderjaar',
                },
                {
                    description: 'Basis Plus Module',
                    compensation: 'Geen vergoeding',
                },
            ],
        },
        {
            id: 'zenz',
            uzovi: '',
            description: 'Zorg en Zekerheid',
            policies: [
                {
                    description: 'AV Basis',
                    compensation: 'max € 25,- per dag voor een behandeling tot € 250,- per kalenderjaar',
                },
                {
                    description: 'AV Gemak',
                    compensation: 'Geen vergoeding',
                },
                {
                    description: 'AV GeZZin',
                    compensation: 'max € 40,- per dag voor een behandeling tot € 460,- per kalenderjaar',
                },
                {
                    description: 'AV Plus',
                    compensation: 'max € 40,- per dag voor een behandeling tot € 460,- per kalenderjaar',
                },
                {
                    description: 'AV Standaard',
                    compensation: 'max € 25,- per dag voor een behandeling tot € 250,- per kalenderjaar',
                },
                {
                    description: 'AV Sure',
                    compensation: 'max € 25,- per dag voor een behandeling tot € 250,- per kalenderjaar',
                },
                {
                    description: 'AV Top',
                    compensation: 'max € 40,- per dag voor een behandeling tot € 460,- per kalenderjaar',
                },
                {
                    description: 'AV Totaal',
                    compensation: 'max € 40,- per dag voor een behandeling tot € 600,- per kalenderjaar',
                },
            ],
        },
    ],
}

export default insurers