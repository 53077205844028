const questionnaire_ysq_v1 = {
    'id': 'YSQ-S3_v1',
    'description': 'Young Schema Questionnaire (YSQ-S3)',
    'short': 'YSQ',
    'questions': [
        {
            'number': 1,
            'info': 'Hieronder volgen een aantal beweringen die men kan gebruiken om zichzelf te beschrijven. '+
                    'Lees elke bewering en kijk hoe goed deze u, in het afgelopen jaar beschrijft. Als u niet zeker bent '+
                    'van uw antwoord, baseer uw antwoord dan op wat u emotioneel voelt en niet op wat u denkt dat waar is.\n\n'+
                    'Een aantal bewerkingen vragen naar uw relaties met uw ouders of partner. Als één of meerdere van deze personen '+
                    'inmiddels overleden zijn, baseer dan uw antwoord op hoe uw relatie was toen zij nog leefden. Als u momenteel '+
                    'geen partner heeft, maar wel partners in het verleden hebt gehad, baseer dan uw antwoord op uw meest recente '+
                    'betekenisvolle partner.\n'+
                    'Kies vervolgens bij iedere bewering het antwoord dat op u van toepassing is.',
            'description': 'Meestal was er niemand die voor me zorgde, dingen met me deelde of die het echt kon schelen wat er met me gebeurde.',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'Ik merk dat ik mij vastklamp aan mensen om wie ik veel geef, omdat ik bang ben dat ze me zullen verlaten.',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Ik denk dat mensen misbruik van me zullen maken.',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Ik pas er niet bij.',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'Geen enkele man of vrouw tot wie ik mij aangetrokken voel, zou nog van me kunnen houden, zodra hij of zij mijn tekortkomingen leert kennen.',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 8,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 9,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 10,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 11,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 12,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 13,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 14,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 15,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 16,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 17,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 18,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 19,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 20,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 21,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 22,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 23,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 24,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
        {
            'number': 25,
            'info': '',
            'description': '...',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Helemaal niet op mij van toepassing',
                },
                {
                    'value': 2,
                    'description': 'Meestal niet op mij van toepassing',
                },
                {
                    'value': 3,
                    'description': 'Iets meer wel dan niet op mij van toepassing',
                },
                {
                    'value': 4,
                    'description': 'Gemiddeld op mij van toepassing',
                },
                {
                    'value': 5,
                    'description': 'Meestal op mij van toepassing',
                },
                {
                    'value': 6,
                    'description': 'Geheel op mij van toepassing'
                }
            ],
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Emotioneel tekort',
            'formule': '(S1+S19)/2',
            'min': 0,
            'max': 6,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Emotioneel',
            'goal': 'Verbeteren van emotioneel tekort',
            'operation': '...',
            'goalscore': 0,
        },
        {
            'description': 'Verlating',
            'formule': '(S2+S20)/2',
            'min': 0,
            'max': 6,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Verlating',
            'goal': 'Verbeteren van verlating',
            'operation': '...',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_ysq_v1