// store/index.js
import { createStore } from 'vuex'
import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore"
import {db} from '@/firebase/init.js'
import ClientsStore from "@/store/modules/ClientsStore";
import EmployeeStore from "@/store/modules/EmployeeStore";
import PlannerStore from "@/store/modules/PlannerStore";
import TimelineDataService from "@/services/TimelineDataService";
import TargetDataService from "@/services/TargetDataService";
import DossierMessagesDataService from "@/services/DossierMessagesDataService";
import EmployeeDataService from "@/services/EmployeeDataService";
import StoreDataService from "@/services/StoreDataService";
import ThirdPartyDataService from "@/services/ThirdPartyDataService";
import TemplateDataService from "@/services/TemplateDataService";
import ExercisesDataService from "@/services/ExercisesDataService";

export default createStore({
    state: {
        mobileDevice: false,
        currentEmployeeId: null,
        currentInstituteId: null,
        patientId: '',
        dossierId: '',
        employeeId: '',
        employees: [],
        thirdParties: [],
        reportTemplates: [],
        messageTemplates: [],
        invoiceTemplates: [],
        reminderInvoiceTemplates: [],
        exercisesTemplates: [],
        invoiceEmailTemplates: [],
        reminderInvoiceEmailTemplates: [],
        exercisesEmailTemplates: [],
        appointmentConfirmationTemplates: [],
        changedAppointmentConfirmationTemplates: [],
        appointmentCancelationTemplates: [],
        patient: {
            archive: false,
        },
        dossiers: [],
        dossierMessages: [],
        exercises: [],
        clientPortalAlerts: [],
        dossier: {
            dossier_type: '',
            authentication: true,
        },
        invoiceId: '',
        productInvoicing: [],
        sessions: [],
        targets: [],
        targetId: '',
        mainTargetId: '',
        reports: [],
        todos: [],
        timeline: [],
        calendarStart: null,
        calendarLength: 0,
        calendarLocationId: '',
        customer: null,
        financial: {
            amountInvoiced: 0,
            amountNotInvoiced: 0,
            amountPaid: 0,
            amountNotPaid: 0,
            amountExpired: 0,
            sessionsInvoiced: 0,
            sessionsNotInvoiced: 0,
            sessionsPaid: 0,
            sessionsNotPaid: 0,
        },
        invoiceUpdateTrigger: false,
        invoicingUpdateTrigger: false,
        settings: {
            financialSettings: null,
            calendarSettings: null,
        },
        mainScreen: {
            showTargetTile: true,
            showSessionTile: true,
            showFinancialTile: true,
            calendar: {
                showSaturday: true,
                showSunday: true,
            },
        },
        countNoraMessages: 0,
        noraClipboard: {
            text: '',
        },
        overviewFilter:  {
            session: '',
        },
    },
    mutations: {
        async SET_PATIENT_ID(state, id) {
            state.patient = {
                archive: false,
            }
            state.patientId = id

            if (id !== '') {
                // Patient vullen
                const docSnap = await getDoc(doc(db, this.state.customer + 'patients', id))
                state.patient = docSnap.data()
            }

            // Dossiers zoeken
            //state.dossier = null
            state.dossier = {
                dossier_type: '',
                authentication: true,
            }
            state.dossierId = ''
            state.dossiers = []
            if (id !== '') {
                if ((state.patient.current_dossier_id !== '') && (state.patient.current_dossier_id !== undefined)) {
                    state.dossierId = state.patient.current_dossier_id
                } else {
                    state.dossierId = ''
                }

                // Normale dossiers
                const querySnap = await getDocs(query(collection(db, state.customer + 'dossiers'), where('patient_id', '==', state.patientId)));
                querySnap.forEach((doc) => {

                    let data = doc.data();

                    this.state.dossiers.push({
                        'id': doc.id,
                        'dossier_type': data.dossier_type,
                        'complaint_id': data.complaint_id,
                        'active': data.active,
                        'authentication': (data.employee_id === '' || data.employee_id === state.currentEmployeeId || data.employee_id === undefined)
                    })
                })

                // Partner dossiers
                const partnerQuerySnap = await getDocs(query(collection(db, state.customer + 'dossiers'), where('partner_id', '==', state.patientId)));
                partnerQuerySnap.forEach((doc) => {

                    let data = doc.data();

                    this.state.dossiers.push({
                        'id': doc.id,
                        'dossier_type': data.dossier_type,
                        'complaint_id': data.complaint_id,
                        'active': data.active,
                        'authentication': (data.employee_id === '' || data.employee_id === state.currentEmployeeId || data.employee_id === undefined)
                    })
                })

                let dossierExists = false
                this.state.dossiers.forEach((dossier) => {
                    if (dossier.id === this.state.dossierId) {
                        dossierExists = true
                    }
                })

                // Dossier
                if (dossierExists) {
                    await StoreDataService.SetDossierId(state, state.dossierId)
                }

                // Product invoicing
                await StoreDataService.getProductInvoicing(state, state.patientId)
            }
        },
        async SET_DOSSIER_ID(state, id) {
            await StoreDataService.SetDossierId(state, id)
        },
        async SET_CURRENT_EMPLOYEE_ID(state, id) {
            state.currentEmployeeId = id
        },
        async SET_CURRENT_INSTITUTE_ID(state, id) {
            state.currentInstituteId = id
        },
        async SET_CUSTOMER(state, id) {
            state.customer = id;
        },
        async SET_SESSION_ID(state, id) {
            state.session = null;
            state.sessionId = id;

            const sessionQuery = await getDocs(query(collection(db, state.customer + 'sessions'), where('patient_id', '==', state.patientId)));
            sessionQuery.forEach((doc) => {
                if (doc.id === id) {
                    state.session = doc.data();
                }
            })
        },
        async SET_TARGET_ID(state, id) {
            state.target = {
                clinimetrics_id: '',
            }

            if (id === '') {
                return null
            }

            const docSnap = await getDoc(doc(db, state.customer + 'targets', id));
            state.target = docSnap.data()
            state.target.id = id
            state.target.goal_reached = (Number(state.target.score) >= Number(state.target.target_score))
            state.target.history = []

            const result = await TargetDataService.GetTargetHistory(id)
            result.forEach((doc) => {
                state.target.history.id = doc.id
                state.target.history.push(doc)
            })

            state.targetId = id;
        },
        async UPDATE_PRODUCT_INVOICING(state) {
            await StoreDataService.getProductInvoicing(state, state.patientId)
        },
        async UPDATE_REPORTS(state) {
            await StoreDataService.getReports(state, state.dossierId)
        },
        async UPDATE_SESSIONS(state) {
            await StoreDataService.getSessions(state, state.dossierId)

            // Timeline
            state.timeline = []
            const items = await TimelineDataService.GetTimelineItems(state.dossierId)
            items.forEach((item) => {
                item.dateText = new Date(item.date.seconds * 1000).toLocaleDateString()

                this.state.timeline.push(item)
            })
        },
        async UPDATE_TARGETS(state) {
            await StoreDataService.getTargets(state, state.dossierId)

            /*state.targets = [];

            const targetQuery = await getDocs(query(collection(db, state.customer + 'targets'), where('dossier_id', '==', state.dossierId)));
            targetQuery.forEach((doc) => {
                let data = doc.data();
                data.id = doc.id;
                data.goal_reached = (Number(data.score) >= Number(data.target_score))

                this.state.targets.push(data);
            })*/
        },
        async UPDATE_TIMELINE(state) {
            state.timeline = [];

            const items = await TimelineDataService.GetTimelineItems(state.dossierId)
            items.forEach((item) => {
                item.dateText = new Date(item.date.seconds * 1000).toLocaleDateString()

                this.state.timeline.push(item)
            })
        },
        async UPDATE_DOSSIER_MESSAGES(state) {
            state.dossierMessages = [];

            const dossierMessages = await DossierMessagesDataService.GetDossierMessages(state.dossierId)
            dossierMessages.forEach((item) => {
                this.state.dossierMessages.push(item)
            })
        },
        async UPDATE_CLIENT_EXERCISES(state) {
            this.state.exercises = await ExercisesDataService.GetExercisesFromClient(state.patientId)
        },
        async UPDATE_TODOS(state) {
            state.todos = [];
            const todoQuery = await getDocs(query(collection(db, state.customer + 'todos'), where('dossier_id', '==', state.dossierId)));
            todoQuery.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;

                state.todos.push(data);
            })
        },
        RESET_PATIENT() {
            this.state.dossierId = '';
            this.state.dossier = {
                dossier_type: '',
                authentication: true,
            }
            this.state.sessions = []
            this.state.targets = []
        },
        SET_CALENDAR_START(state, start) {
            this.state.calendarStart = start
        },
        SET_CALENDAR_LENGTH(state, length) {
            this.state.calendarLength = length
        },
        SET_CALENDAR_LOCATION_ID(state, locationId) {
            this.state.calendarLocationId = locationId
        },
        async UPDATE_EMPLOYEES(state) {
            state.employees = await EmployeeDataService.GetEmployees()
        },
        async UPDATE_THIRD_PARTIES(state) {
            state.thirdParties = await ThirdPartyDataService.GetThirdParties()
        },
        async UPDATE_TEMPLATES(state) {
            state.reportTemplates = await TemplateDataService.GetTemplates('report')
            state.messageTemplates = await TemplateDataService.GetTemplates('message')
            state.invoiceTemplates = await TemplateDataService.GetTemplates('invoice')
            state.exercisesTemplates = await TemplateDataService.GetTemplates('exercises')
            state.reminderInvoiceTemplates = await TemplateDataService.GetTemplates('reminder_invoice')
            state.invoiceEmailTemplates = await TemplateDataService.GetTemplates('invoice_mail')
            state.reminderInvoiceEmailTemplates = await TemplateDataService.GetTemplates('reminder_invoice_mail')
            state.exercisesEmailTemplates = await TemplateDataService.GetTemplates('exercises_mail')
            state.appointmentConfirmationTemplates = await TemplateDataService.GetTemplates('appointment_confirmation')
            state.changedAppointmentConfirmationTemplates = await TemplateDataService.GetTemplates('changed_appointment_confirmation')
            state.appointmentCancelationTemplates = await TemplateDataService.GetTemplates('appointment_cancelation')
        },
    },
    actions: {
        setPatientId(context, id) {
            context.commit('SET_PATIENT_ID', id)
        },
        setDossierId(context, id) {
            context.commit('SET_DOSSIER_ID', id)
        },
        setCurrentEmployeeId(context, id) {
            context.commit('SET_CURRENT_EMPLOYEE_ID', id)
        },
        setCurrentInstituteId(context, id) {
            context.commit('SET_CURRENT_INSTITUTE_ID', id)
        },
        setCustomer(context, id) {
            context.commit('SET_CUSTOMER', id)
        },
        setSessionId(context, id) {
            context.commit('SET_SESSION_ID', id)
        },
        setTargetId(context, id) {
            context.commit('SET_TARGET_ID', id)
        },
        updateProductInvoicing(context) {
            context.commit('UPDATE_PRODUCT_INVOICING')
        },
        updateReports(context) {
            context.commit('UPDATE_REPORTS')
        },
        updateSessions(context) {
            context.commit('UPDATE_SESSIONS')
        },
        updateTodos(context) {
            context.commit('UPDATE_TODOS')
        },
        updateTargets(context) {
            context.commit('UPDATE_TARGETS')
        },
        updateTimeline(context) {
            context.commit('UPDATE_TIMELINE')
        },
        updateDossierMessages(context) {
            context.commit('UPDATE_DOSSIER_MESSAGES')
        },
        updateClientExercises(context) {
            context.commit('UPDATE_CLIENT_EXERCISES')
        },
        resetPatient(context) {
            context.commit('RESET_PATIENT')
        },
        setCalendarStart(context, start) {
            context.commit('SET_CALENDAR_START', start)
        },
        setCalendarLength(context, length) {
            context.commit('SET_CALENDAR_LENGTH', length)
        },
        setCalendarLocationId(context, locationId) {
            context.commit('SET_CALENDAR_LOCATION_ID', locationId)
        },
        updateEmployees(context) {
            context.commit('UPDATE_EMPLOYEES')
        },
        updateThirdParties(context) {
            context.commit('UPDATE_THIRD_PARTIES')
        },
        updateTemplates(context) {
            context.commit('UPDATE_TEMPLATES')
        },
    },
    modules: {
        ClientsStore,
        EmployeeStore,
        PlannerStore,
    },
})
