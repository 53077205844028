import store from '@/store';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import {db} from '@/firebase/init';
import SettingsDataService from '@/services/SettingsDataService';
import EmployeeDataService from "@/services/EmployeeDataService";

export async function login(email, password) {
    const auth = getAuth();
    const result = await signInWithEmailAndPassword(auth, email, password)

    await setCurrentEmployeeAndCustomer(result.user.email);
    await EmployeeDataService.GetUserInterfaceSettings(store.state.currentEmployeeId);
    await SettingsDataService.GetSettings();
    await store.dispatch('ClientsStore/getComplaints')
    await store.dispatch('ClientsStore/getInstitute')
    await store.dispatch('ClientsStore/getEmployees')
    await store.dispatch('ClientsStore/getLocations')
    await store.dispatch('updateTemplates')
}

export async function reset(email) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email, {
        url: `${window.location.origin}/login`,
    })
}

export async function logout() {
    await EmployeeDataService.SetUserInterfaceSettings(store.state.currentEmployeeId);
    await getAuth().signOut();
    await clear();
}

export async function setCurrentEmployeeAndCustomer(email) {
    const customers = await getDocs(collection(db, 'users', email, 'customers'))
    const firstCustomer = customers.docs.length ? customers.docs[0] : null

    if (!firstCustomer) {
        throw new Error(`Could not authenticate ${email}`)
    }

    await store.dispatch('setCurrentEmployeeId', firstCustomer.data().employee_id)
    await store.dispatch('setCurrentInstituteId', firstCustomer.data().institute_id)
    await store.dispatch('setCustomer', `customers/${firstCustomer.id}/`)
}

export async function clear() {
    await store.dispatch('setCurrentEmployeeId', null);
    await store.dispatch('setCustomer', null);
}
