// ./services/ComplaintDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class ComplaintDataService {
    async AddComplaint(dataObj) {
        const colRef = collection(db, store.state.customer + 'complaints');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Complaint added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateComplaint(complaintId, dataObj) {
        const docRef = doc(db, store.state.customer + 'complaints', complaintId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Complaint updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveComplaint(complaintId) {
        console.log('id to be removed: ' + complaintId)
        const docRef = doc(db, store.state.customer + 'complaints', complaintId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Complaint deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async AddClinimetrics(complaintId, dataObj) {
        const colRef = collection(db, store.state.customer + 'complaints/' + complaintId + '/clinimetrics');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Complaint clinimetrics added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateClinimetrics(complaintId, id, dataObj) {
        const docRef = doc(db, store.state.customer + 'complaints/' + complaintId + '/clinimetrics', id)

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Clinimetrics updated')
            })
            .catch((e) => {
                console.log(e)
            })
    }
    async AddTasks(complaintId, dataObj) {
        const colRef = collection(db, store.state.customer + 'complaints/' + complaintId + '/tasks');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateTask(complaintId, id, dataObj) {
        const docRef = doc(db, store.state.customer + 'complaints/' + complaintId + '/tasks', id)

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Task updated')
            })
            .catch((e) => {
                console.log(e)
            })
    }
    async RemoveClinimetrics(complaintId, id) {
        console.log('id to be removed: ' + id)
        const docRef = doc(db, store.state.customer + 'complaints/' + complaintId + '/clinimetrics', id)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Complaint clinimetrics deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async RemoveTask(complaintId, id) {
        const docRef = doc(db, store.state.customer + 'complaints/' + complaintId + '/tasks', id)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetComplaint(complaintId) {
        if (complaintId === '') {
            return
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'complaints', complaintId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetComplaintClinimetrics(complaintId) {
        if (complaintId === '') {
            return
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const clinimetricsRef = collection(db, store.state.customer + 'complaints/' + complaintId + '/clinimetrics')
                const clinimetricsQuery = query(clinimetricsRef)
                const snapShot = await getDocs(clinimetricsQuery)

                let clinimetrics = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    clinimetrics.push(data)
                })

                resolve(clinimetrics.sort((a, b) => a.number - b.number))
            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetComplaintTasks(complaintId) {
        if (complaintId === '') {
            return
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const tasksRef = collection(db, store.state.customer + 'complaints/' + complaintId + '/tasks')
                const tasksQuery = query(tasksRef)
                const snapShot = await getDocs(tasksQuery)

                let tasks = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    tasks.push(data)
                })

                resolve(tasks.sort((a, b) => a.number - b.number))
            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetDefaultTasks() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const tasksRef = collection(db, store.state.customer + 'tasks')
                const tasksQuery = query(tasksRef)
                const snapShot = await getDocs(tasksQuery)

                let tasks = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    if (data.default_task === true) {
                        tasks.push(data)
                    }

                })

                resolve(tasks)
            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetComplaints() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const complaintsRef = collection(db, store.state.customer + 'complaints', )
                const complaintsQuery = query(complaintsRef, orderBy('description'))
                const snapShot = await getDocs(complaintsQuery)

                let complaints = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    complaints.push(data)
                })

                resolve(complaints.sort())

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    GetComplaintDescription(complaintId) {
        let description = ''
        store.state.ClientsStore.complaints.forEach((item) => {
            if (item.id === complaintId) {
                description = item.description
            }
        })

        return description
    }
    GetTaskLinkDescription(taskLink) {
        switch(taskLink) {
            case 'report': return 'Rapportage'
            case 'message': return 'Bericht'
            case 'permission': return 'Toestemming'
            case 'intake': return 'Intake'
            case 'exercise': return 'Oefening'
            default: return ''
        }
    }
}

export default new ComplaintDataService();