import {jsPDF} from "jspdf";
//import html2canvas from "html2canvas";
import ReportDataService from "@/services/ReportDataService";
import ThirdPartyDataService from "@/services/ThirdPartyDataService";
import InvoicingDataService from "@/services/InvoicingDataService";
import ClientDataService from "@/services/ClientDataService";
import TemplateDataService from "@/services/TemplateDataService";
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import store from "@/store";
import MailService from "@/services/MailService";
import ExercisesDataService from "@/services/ExercisesDataService";

class PdfService {
    async invoice2pdf(invoiceId, templateId, emailTemplateId, mode) {
        const doc = new jsPDF({
            format: 'A4',
            orientation: "portrait",
            unit: "pt",
        })
        doc.setFontSize(10)
        doc.setFont('Courier')

        const invoice = await InvoicingDataService.GetInvoice(invoiceId)
        const invoiceSpecification = await InvoicingDataService.GetInvoiceSpecification(invoiceId)
        let template = null
        try {
            if (mode === 'pdf_reminder' || mode === 'email_reminder') {
                template = await TemplateDataService.GetTemplate(invoice.reminder_template_id)
            } else {
                template = await TemplateDataService.GetTemplate(invoice.template_id)
            }
        } catch {
            template = await TemplateDataService.GetTemplate(templateId)
        }

        // logo
        if (template.full_path && template.full_path !== '') {
            let imgLogo = new Image()

            const storage = getStorage()
            const storageRef = ref(storage, template.full_path)
            await getDownloadURL(storageRef)
                .then((download_url) => {
                    imgLogo.src = download_url
                })
                .catch((error) => {
                    console.log(error)
                })
            doc.addImage(
                imgLogo,
                '*',
                this.mm2pt(template.logo_margin_left),
                this.mm2pt(template.logo_margin_top),
                this.mm2pt(210) - this.mm2pt(template.logo_margin_left) - this.mm2pt(template.logo_margin_right),
                this.mm2pt(280) - this.mm2pt(template.logo_margin_top) - this.mm2pt(template.logo_margin_bottom),
            )

        }

        // Address
        const clientHtml = await this.getHtmlClientAddress(invoice.client_id)

        await doc.html(String(clientHtml), {
            callback: function (doc) {
                return doc
            },
            width: this.mm2pt(210) - this.mm2pt(template.address_margin_left) - this.mm2pt(template.address_margin_right),
            height: this.mm2pt(210) - this.mm2pt(template.address_margin_left) - this.mm2pt(template.address_margin_right),
            windowWidth: this.mm2pt(210) - this.mm2pt(template.address_margin_left) - this.mm2pt(template.address_margin_right),
            html2canvas: {
                //backgroundColor: 'lightyellow',
                width: this.mm2pt(210) - this.mm2pt(template.address_margin_left) - this.mm2pt(template.address_margin_right),
            },
            //backgroundColor: 'lightblue',
            x: this.mm2pt(template.address_margin_left),
            y: this.mm2pt(template.address_margin_top),
            autoPaging: 'text',
        })

        // report
        let reportText = await TemplateDataService.ConvertInvoiceTemplate(template.text, invoice, invoiceSpecification)
        reportText = reportText.replaceAll('<p>', '<div style="display: block; font-family: \'Helvetica\';">')
        reportText = reportText.replaceAll('</p>', '</div>')
        await doc.html(String(reportText), {
            callback: function (doc) {
                return doc
            },
            margin: [
                this.mm2pt(template.report_margin_top),
                this.mm2pt(template.report_margin_right),
                this.mm2pt(template.report_margin_bottom),
                this.mm2pt(template.report_margin_left)
            ],
            width: this.mm2pt(210) - this.mm2pt(template.report_margin_left) - this.mm2pt(template.report_margin_right),
            windowWidth: this.mm2pt(210) - this.mm2pt(template.report_margin_left) - this.mm2pt(template.report_margin_right),
            html2canvas: {
                //backgroundColor: 'lightyellow',
                width: this.mm2pt(210) - this.mm2pt(template.report_margin_left) - this.mm2pt(template.report_margin_right),
                letterRendering: true,
            },
            //backgroundColor: 'lightblue',
            //x: this.mm2pt(report.report_margin_left),
            //y: this.mm2pt(report.report_margin_top),
            autoPaging: 'text',
        })

        // footer
        let footerText = await TemplateDataService.ConvertInvoiceTemplate(template.footer, invoice, invoiceSpecification)
        footerText = footerText.replaceAll('<p>', '<div style="display: block; font-family: \'Helvetica\';">')
        footerText = footerText.replaceAll('</p>', '</div>')
        await doc.html(String(footerText), {
            callback: function (doc) {
                doc.setFont('Courier', 'normal')
                return doc
            },
            margin: [
                this.mm2pt(template.footer_margin_top),
                this.mm2pt(template.footer_margin_right),
                this.mm2pt(template.footer_margin_bottom),
                this.mm2pt(template.footer_margin_left)
            ],
            width: this.mm2pt(210) - this.mm2pt(template.footer_margin_left) - this.mm2pt(template.footer_margin_right),
            windowWidth: this.mm2pt(210) - this.mm2pt(template.footer_margin_left) - this.mm2pt(template.footer_margin_right),
            html2canvas: {
                width: this.mm2pt(210) - this.mm2pt(template.footer_margin_left) - this.mm2pt(template.footer_margin_right),
                scale: 1,
            },
            autoPaging: 'text',
        })

        // Save invoice in storage
        let mailId = ''
        let storageInfo = ''
        const storage = getStorage()
        let storageRef = ref(storage, store.state.customer + '/invoices/' + invoice.invoice_number + '.pdf')
        await uploadBytes(storageRef, doc.output('blob')).then(async (snapshot) => {
            storageInfo = snapshot.metadata

            if (mode === 'email' || mode === 'email_reminder') {
                storageRef = ref(storage, storageInfo.fullPath)
                await getDownloadURL(storageRef)
                    .then(async (download_url) => {
                        let invoiceEmailTemplateId = null
                        try {
                            if (mode === 'email_reminder') {
                                invoiceEmailTemplateId = invoice.reminder_email_template_id
                            } else {
                                invoiceEmailTemplateId = invoice.email_template_id
                            }
                        } catch {
                            invoiceEmailTemplateId = emailTemplateId
                        }

                        mailId = await MailService.SendInvoiceMail(storageInfo.name, download_url, invoice, invoiceEmailTemplateId)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            }
        })

        if (mode === 'pdf' || mode === 'pdf_reminder') {
            doc.save('Factuur_' + invoice.invoice_number + '.pdf')
        }

        const invoiceHistoryObj = {
            date: new Date(),
            action: (mode === 'pdf' || mode === 'pdf_reminder') ? 'document' : 'mail',
            mail_id: mailId,
            document_path: storageInfo ? storageInfo.fullPath : '',
        }
        await InvoicingDataService.AddInvoiceHistory(invoiceId, invoiceHistoryObj)

    }
    async message2pdf(message) {
        const doc = new jsPDF({
            format: 'A4',
            orientation: "portrait",
            unit: "pt",
        })
        doc.setFontSize(10)

        await doc.html(String(TemplateDataService.getMessageHeaderHtml(message)), {
            callback: function (doc) {
                return doc
            },
            margin: [
                this.mm2pt(30), //top
                this.mm2pt(30), //right
                this.mm2pt(30), //bottom
                this.mm2pt(30), //left
            ],
            width: this.mm2pt(210) - this.mm2pt(30) - this.mm2pt(30),
            windowWidth: this.mm2pt(210) - this.mm2pt(30) - this.mm2pt(30),
            html2canvas: {
                width: this.mm2pt(210) - this.mm2pt(30) - this.mm2pt(30),
            },
            autoPaging: 'text',
        })

        const strArr = doc.splitTextToSize(message.text, this.mm2pt(150))
        doc.text(strArr, this.mm2pt(30), this.mm2pt(70));

        doc.save('Bericht.pdf')
    }
    async report2pdf(reportId) {
        const doc = new jsPDF({
            format: 'A4',
            orientation: "portrait",
            unit: "pt",
        })
        doc.setFontSize(10)

        const report = await ReportDataService.GetReport(reportId)

        // logo
        if (report.full_path && report.full_path !== '') {
            let imgLogo = new Image()

            const storage = getStorage()
            const storageRef = ref(storage, report.full_path)
            await getDownloadURL(storageRef)
                .then((download_url) => {
                    imgLogo.src = download_url
                })
                .catch((error) => {
                    console.log(error)
                })
            console.log('logo', imgLogo)
            doc.addImage(
                imgLogo,
                '*',
                this.mm2pt(report.logo_margin_left),
                this.mm2pt(report.logo_margin_top),
                this.mm2pt(210) - this.mm2pt(report.logo_margin_left) - this.mm2pt(report.logo_margin_right),
                this.mm2pt(280) - this.mm2pt(report.logo_margin_top) - this.mm2pt(report.logo_margin_bottom),
            )

        }

        // Address
        if (report.receiver_id !== '') {
            const receiverHtml = await this.getHtmlThirdPartyAddress(report.receiver_id)

            await doc.html(String(receiverHtml), {
                callback: function (doc) {
                    return doc
                },
                width: this.mm2pt(210) - this.mm2pt(report.address_margin_left) - this.mm2pt(report.address_margin_right),
                height: this.mm2pt(210) - this.mm2pt(report.address_margin_left) - this.mm2pt(report.address_margin_right),
                windowWidth: this.mm2pt(210) - this.mm2pt(report.address_margin_left) - this.mm2pt(report.address_margin_right),
                html2canvas: {
                    //backgroundColor: 'lightyellow',
                    width: this.mm2pt(210) - this.mm2pt(report.address_margin_left) - this.mm2pt(report.address_margin_right),
                },
                //backgroundColor: 'lightblue',
                x: this.mm2pt(report.address_margin_left),
                y: this.mm2pt(report.address_margin_top),
                autoPaging: 'text',
            })
        }

        // Report
        /*
        this.text = report.text; // The HTML content from the report
        this.margins = {
            left: report.report_margin_left || 10, // Default margin if not provided
            right: report.report_margin_right || 10,
            top: report.report_margin_top || 10,
            bottom: report.report_margin_bottom || 10,
        };

        // Step 1: Create a temporary container for rendering the HTML
        const tempContainer = document.createElement("div");
        tempContainer.style.width = "800px"; // Approximate A4 width
        tempContainer.style.padding = "0"; // No padding here
        tempContainer.innerHTML = this.text;
        document.body.appendChild(tempContainer);

        try {
            // Step 2: Use html2canvas to render the HTML content into a canvas
            const canvas = await html2canvas(tempContainer, {
                scale: 2, // Improve resolution
                useCORS: true, // Allow cross-origin images
            });

            // Step 3: Remove the temporary container
            document.body.removeChild(tempContainer);

            // Step 4: Get the canvas height, width, and calculate scaling
            //const imgData = canvas.toDataURL("image/png");
            const pageWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgWidth = pageWidth - this.margins.left - this.margins.right;
            const canvasHeight = (canvas.height * imgWidth) / canvas.width;
            const scale = canvas.width / 800; // Scale content to fit A4 width
            const pageContentHeight = pageHeight - this.margins.top - this.margins.bottom;

            let currentY = 0;

            // Step 6: Handle multi-page content while respecting margins
            while (currentY < canvasHeight) {
                const sliceCanvas = await this.createCanvasSlice(canvas, currentY, pageContentHeight / scale);
                const sliceImgData = sliceCanvas.toDataURL("image/png");
                const sliceHeight = (sliceCanvas.height * imgWidth) / sliceCanvas.width;

                doc.addImage(sliceImgData, "PNG", this.margins.left, this.margins.top, imgWidth, sliceHeight);

                currentY += pageContentHeight / scale;

                // Add a new page if there's more content
                if (currentY < canvasHeight) {
                    doc.addPage();
                }
            }

            //doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
        } catch (error) {
            console.error("Error generating PDF:", error);
        }*/


        // Report
        let reportText = report.text
        reportText = reportText.replaceAll('<p>', '<div style="display: block; font-family: \'Helvetica\';">')
        reportText = reportText.replaceAll('</p>', '</div>')

        await doc.html(String(reportText), {
            callback: function (doc) {
                return doc
            },
            margin: [
                this.mm2pt(report.report_margin_top),
                this.mm2pt(report.report_margin_right),
                this.mm2pt(report.report_margin_bottom),
                this.mm2pt(report.report_margin_left)
            ],
            width: this.mm2pt(210) - this.mm2pt(report.report_margin_left) - this.mm2pt(report.report_margin_right),
            windowWidth: this.mm2pt(210) - this.mm2pt(report.report_margin_left) - this.mm2pt(report.report_margin_right),
            html2canvas: {
                width: this.mm2pt(210) - this.mm2pt(report.report_margin_left) - this.mm2pt(report.report_margin_right),
            },
            autoPaging: 'text',
        })


        // Address
        /*const client = await ClientDataService.GetClient(this.invoice.client_id)
        autoTable(doc, {
            columnStyles: {
                0: { cellWidth: 100 },
            },
            body: [
                [ client.first_name + ' ' + client.last_name ],
                [ client.street + ' ' + client.house_number + client.house_number_suffix ],
                [ client.postal_code + '  ' + client.city ],
            ],
            startY: 30,
            margin: 100,
            theme: 'plain',
        })*/

        // Header
        /*autoTable(doc, {
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 60 },
            },
            body: [
                [ 'Factuurnummer:',  String("00000000" + this.invoice.invoice_number).slice(-8), '', '' ],
                [ 'Factuurdatum:', new Date(this.invoice.invoice_date).toLocaleDateString() ],
                [ 'Omschrijving:', this.invoice.description ],
            ],
            startY: 80,
            margin: 30,
            theme: 'plain',
        })*/

        // Invoice specification
        /*let invoiceSpecification = []
        this.invoiceSpecification.forEach((spec) => {
            const data = spec

            let pdfSpec = {
                description: data.description,
                amount: data.amount,
                amount_incl_vat: this.showAmount(data.amount_incl_vat),
                amount_vat: this.showAmount(data.amount_vat),
                amount_excl_vat: this.showAmount(data.amount_excl_vat),
            }
            invoiceSpecification.push(pdfSpec)
        })

        autoTable(doc, {
            //head: [['Omschrijving', 'Aantal', 'Bedrag']],
            columns: [
                { title: 'Omschrijving', dataKey: 'description' },
                { title: 'Aantal', dataKey: 'amount' },
                { title: 'Bedrag excl.', dataKey: 'amount_excl_vat'},
                { title: 'BTW', dataKey: 'amount_vat' },
                { title: 'Bedrag incl.', dataKey: 'amount_incl_vat' },
            ],
            body: invoiceSpecification,
            startY: 120,
            margin: 30,
            headStyles: {
                fillColor: [18, 160, 215],
                textColor: '#FFFFFF',
                fontStyle: ['bold'],
                halign: 'right',
            },
            columnStyles: {
                //'description': { width: 20, fillColor: [0, 255, 0] },
                'description': { width: 20 },
                'amount': { halign: 'right' },
                'amount_excl_vat': { halign: 'right' },
                'amount_vat': { halign: 'right' },
                'amount_incl_vat': { halign: 'right' },
            },
            theme: 'plain',
        })*/


        //const blob = doc.output('blob')
        //console.log(blob)
        doc.save('a4.pdf')
    }

    /**
     * Create a sliced canvas from a larger canvas
     * @param {HTMLCanvasElement} canvas - Original canvas
     * @param {number} startY - Starting Y coordinate for the slice
     * @param {number} height - Height of the slice
     * @returns {HTMLCanvasElement} - Sliced canvas
     */
    async createCanvasSlice(canvas, startY, height) {
        const sliceCanvas = document.createElement("canvas");
        sliceCanvas.width = canvas.width;
        sliceCanvas.height = height * (canvas.width / 800); // Scale to match original width

        const ctx = sliceCanvas.getContext("2d");
        ctx.drawImage(canvas, 0, -startY * (canvas.width / 800)); // Adjust Y offset based on scale

        return sliceCanvas;
    }

    async exercises2pdf(clientId, templateId, emailTemplateId) {
        const doc = new jsPDF({
            format: 'A4',
            orientation: "portrait",
            unit: "pt",
        })
        doc.setFontSize(10)

        const clientExercises = await ExercisesDataService.GetExercisesFromClient(clientId)
        const template = await TemplateDataService.GetTemplate(templateId)

        // logo
        if (template.full_path && template.full_path !== '') {
            let imgLogo = new Image()

            const storage = getStorage()
            const storageRef = ref(storage, template.full_path)
            await getDownloadURL(storageRef)
                .then((download_url) => {
                    imgLogo.src = download_url
                })
                .catch((error) => {
                    console.log(error)
                })
            doc.addImage(
                imgLogo,
                '*',
                this.mm2pt(template.logo_margin_left),
                this.mm2pt(template.logo_margin_top),
                this.mm2pt(210) - this.mm2pt(template.logo_margin_left) - this.mm2pt(template.logo_margin_right),
                this.mm2pt(280) - this.mm2pt(template.logo_margin_top) - this.mm2pt(template.logo_margin_bottom),
            )
        }

        // Exercises
        let exerciseHtml = await TemplateDataService.ConvertExercisesTemplate(template.text, clientId, clientExercises)
        exerciseHtml = exerciseHtml.replaceAll('<p>', '<div style="display: block; font-family: \'Helvetica\';">')
        exerciseHtml = exerciseHtml.replaceAll('</p>', '</div>')
        await doc.html(String(exerciseHtml), {
            callback: function (doc) {
                return doc
            },
            margin: [
                this.mm2pt(template.report_margin_top),
                this.mm2pt(template.report_margin_right),
                this.mm2pt(template.report_margin_bottom),
                this.mm2pt(template.report_margin_left)
            ],
            width: this.mm2pt(210) - this.mm2pt(template.report_margin_left) - this.mm2pt(template.report_margin_right),
            windowWidth: this.mm2pt(210) - this.mm2pt(template.report_margin_left) - this.mm2pt(template.report_margin_right),
            html2canvas: {
                width: this.mm2pt(210) - this.mm2pt(template.report_margin_left) - this.mm2pt(template.report_margin_right),
            },
            autoPaging: 'text',
        })

        // Save invoice in storage
        let mailId = ''
        let storageInfo = ''
        const storage = getStorage()
        let storageRef = ref(storage, store.state.customer + '/exercises/' + clientId + '.pdf')
        await uploadBytes(storageRef, doc.output('blob')).then(async (snapshot) => {
            storageInfo = snapshot.metadata

            storageRef = ref(storage, storageInfo.fullPath)
            await getDownloadURL(storageRef)
                .then(async (download_url) => {
                    console.log(emailTemplateId)
                    mailId = await MailService.SendExerciseMail(storageInfo.name, download_url, clientId, emailTemplateId)
                })
                .catch((error) => {
                    console.log(error)
                })
        })

        console.log(mailId)
        doc.save('Exercise_test.pdf')

    }
    async getHtmlThirdPartyAddress(thirdPartyId) {
        let thirdParty = []
        await ThirdPartyDataService.GetThirdParty(thirdPartyId)
            .then((result) => {
                thirdParty = result
            })
            .catch(() => {
                thirdParty = []
            })

        return '<p><span style="font-size: 11px; line-height: 60%; font-family: \'Helvetica\';">' + thirdParty.first_name + ' ' + thirdParty.last_name + '<br>' +
            thirdParty.street + ' ' + thirdParty.house_number + thirdParty.house_number_suffix + '<br>' +
            thirdParty.postal_code + '  ' + thirdParty.city + '</span></p>'
    }
    async getHtmlClientAddress(clientId) {
        let client = []
        await ClientDataService.GetClient(clientId)
            .then((result) => {
                client = result
            })
            .catch(() => {
                client = []
            })

        if (client.alternative_billing_address === true) {
            return '<p style="font-size: 11px; font-family: \'Helvetica\';">' + client.alt_name + '<br>' +
                ((client.alt_name_2 !== '') ? client.alt_name_2 + '<br>' : '') +
                client.alt_street + ' ' + client.alt_house_number + client.alt_house_number_suffix + '<br>' +
                client.alt_postal_code + '  ' + client.alt_city + '</p>'
        } else {
            return '<p style="font-size: 11px; font-family: \'Helvetica\';">' + client.first_name + ' ' + client.last_name + '<br>' +
                client.street + ' ' + client.house_number + client.house_number_suffix + '<br>' +
                client.postal_code + '  ' + client.city + '</p>'
        }
    }
    mm2pt(value) {
        return (value / 0.3527777778)
    }
}

export default new PdfService()