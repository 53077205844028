// ./services/SalesBookingDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, limit, orderBy, query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

async function GetSalesBookingNumber() {
    const costsSalesRef = collection(db, store.state.customer + 'sales_bookings', )
    const costsSalesQuery = query(costsSalesRef, orderBy('number', 'desc'), limit(1))
    const snapShot = await getDocs(costsSalesQuery);

    let number = 0
    snapShot.forEach(doc => {
        let data = doc.data()
        number = data.number
    })
    return number + 1
}

class SalesBookingDataService {
    async AddSalesBooking(dataObj) {
        const colRef = collection(db, store.state.customer + 'sales_bookings')
        dataObj.number = await GetSalesBookingNumber()

        let salesBookingId
        await addDoc(colRef, dataObj)
            .then((docRef) => {
                salesBookingId = docRef.id
            })
            .catch(e => {
                console.log(e);
                salesBookingId = ''
            })
        return salesBookingId
    }
    async UpdateSalesBooking(salesBookingId, dataObj) {
        const docRef = doc(db, store.state.customer + 'sales_bookings', salesBookingId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveSalesBooking(salesBookingId) {
        const docRef = doc(db, store.state.customer + 'sales_bookings', salesBookingId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetSalesBooking(salesBookingId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'sales_bookings', salesBookingId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetSalesBookings() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const salesBookingRef = collection(db, store.state.customer + 'sales_bookings', )
                const salesBookingQuery = query(salesBookingRef, orderBy('invoice_date'))
                const snapShot = await getDocs(salesBookingQuery);

                let salesBookings = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    salesBookings.push(data)
                })

                resolve(salesBookings)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new SalesBookingDataService();