const questionnaire_nprs_v1 = {
    'id': 'NPRS_v1',
    'description': 'Numeric Pain Rating Scale (NPRS)',
    'short': 'NPRS',
    'online': true,
    'questions': [
        {
            'number': 1,
            'info': 'Selecteer het cijfer dat het best de ernst van uw pijn weergeeft',
            'description': 'Hoe hevig was uw pijn (gemiddeld) de afgelopen week (7 dagen)?',
            'question_type': 'slider_min_max',
            'min_value': 0,
            'min_description': 'Geen enkele pijn',
            'max_value': 10,
            'max_description': 'Meest voorstelbare pijn',
            'score': '',
            'required': true,
        },
        {
            'number': 2,
            'info': 'Selecteer het cijfer dat het best de ernst van uw pijn weergeeft',
            'description': 'Hoe hevig was uw pijn op de slechtste momenten in de afgelopen week (7 dagen)?',
            'question_type': 'slider_min_max',
            'min_value': 0,
            'min_description': 'Geen enkele pijn',
            'max_value': 10,
            'max_description': 'Meest voorstelbare pijn',
            'score': '',
            'required': true,
        },
    ],
    'scores': [
        {
            'description': 'Gemiddelde pijn',
            'formule': 'S1',
            'min': 0,
            'max': 10,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Pijn',
            'goal': 'Verminderen van pijn',
            'operation': '',
            'goalscore': 0,
        },
        {
            'description': 'Hevigste pijn',
            'formule': 'S2',
            'min': 0,
            'max': 10,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Pijn',
            'goal': 'Verminderen van pijn',
            'operation': '',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_nprs_v1