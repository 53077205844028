// ./services/ReportDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class ReportDataService {
    async AddReport(dataObj) {
        const colRef = collection(db, store.state.customer + 'reports')

        let reportId = ''
        await addDoc(colRef, dataObj)
            .then((doc) => {
                reportId = doc.id
            })
            .catch(e => {
                console.log(e)
                reportId = ''
            })

        return reportId
    }
    async UpdateReport(reportId, dataObj) {
        const docRef = doc(db, store.state.customer + 'reports', reportId)

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            })
    }
    async RemoveReport(reportId) {
        const docRef = doc(db, store.state.customer + 'reports', reportId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetReport(reportId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'reports', reportId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetReports(dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const templateRef = collection(db, store.state.customer + 'templates', )
                const templateQuery = query(templateRef, where('dossierId', '==', dossierId), orderBy('date'))
                const snapShot = await getDocs(templateQuery);

                let reports = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    reports.push(data)
                })

                resolve(reports)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new ReportDataService()