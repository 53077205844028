import {db} from "@/firebase/init";
import store from "@/store";
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, where} from "firebase/firestore";
import ClientDataService from "@/services/ClientDataService";
import ComplaintDataService from "@/services/ComplaintDataService";
import DossierMessagesDataService from "@/services/DossierMessagesDataService";

class DossierDataService {
    async GetDossiers() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const dossiersRef = collection(db, store.state.customer + 'dossiers', )
                const dossiersQuery = query(dossiersRef)
                const snapShot = await getDocs(dossiersQuery);

                let clients = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    clients.push(data)
                })

                resolve(clients)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetDossier(dossierId) {
        if (dossierId === '') {
            return {
                dossier_type: '',
                authentication: true,
            }
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'dossiers', dossierId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async CreateNewDossier(dataObj) {
        const colRef = collection(db, store.state.customer + 'dossiers');

        let dossierId = ''
        await addDoc(colRef, dataObj)
            .then(async (docRef) => {
                dossierId = docRef.id

                const clientObj = {
                    current_dossier_id: dossierId,
                }
                await ClientDataService.UpdateClient(dataObj.patient_id, clientObj)

                await this.AddDefaultTasksToDossier(dataObj.patient_id, dossierId)

            })
            .catch(e => {
                console.log(e)
                dossierId = ''
            })

        return dossierId
    }
    async UpdateDossier(dataObj, dossierId) {
        const docRef = doc(db, store.state.customer + 'dossiers', dossierId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveDossier(dossierId) {

        const clinimetricQuery = await getDocs(query(collection(db, store.state.customer + 'clinimetrics'), where('dossier_id', '==', dossierId)));
        clinimetricQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const dossierDocumentQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_documents'), where('dossier_id', '==', dossierId)));
        dossierDocumentQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const dossierMessageQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_messages'), where('dossier_id', '==', dossierId)));
        dossierMessageQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const dossierPermissionsRef = doc(db, store.state.customer + 'dossier_permissions', dossierId)
        await deleteDoc(dossierPermissionsRef)
            .catch((e) => {
                console.log(e)
            })

        const onlineRequestQuery = await getDocs(query(collection(db, store.state.customer + 'online_requests'), where('dossier_id', '==', dossierId)));
        onlineRequestQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const productInvoicingQuery = await getDocs(query(collection(db, store.state.customer + 'product_invoicing'), where('dossier_id', '==', dossierId)));
        productInvoicingQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const reportQuery = await getDocs(query(collection(db, store.state.customer + 'reports'), where('dossier_id', '==', dossierId)));
        reportQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const sessionQuery = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('dossier_id', '==', dossierId)));
        sessionQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const targetQuery = await getDocs(query(collection(db, store.state.customer + 'targets'), where('dossier_id', '==', dossierId)));
        targetQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const todoQuery = await getDocs(query(collection(db, store.state.customer + 'todos'), where('dossier_id', '==', dossierId)));
        todoQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const timelineQuery = await getDocs(query(collection(db, store.state.customer + 'timeline'), where('dossier_id', '==', dossierId)));
        timelineQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        const docRef = doc(db, store.state.customer + 'dossiers', dossierId)
        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    GetDossierTypeDescription(dossierType) {
        if (dossierType.toLowerCase() === 'haptotherapie') {
            return 'Haptotherapie'
        } else if (dossierType.toLowerCase() === 'psychosociaal_therapie') {
            return 'Psychosociaal therapie'
        } else if (dossierType.toLowerCase() === 'fysiotherapie') {
            return 'Fysiotherapie'
        } else if (dossierType.toLowerCase() === 'manueeltherapie') {
            return 'Manuele therapie'
        } else if (dossierType.toLowerCase() === 'relatietherapie') {
            return 'Relatietherapie'
        } else if (dossierType.toLowerCase() === 'zwanger') {
            return 'Zwangerschapsbegeleiding'
        } else if (dossierType.toLowerCase() === 'dietetiek') {
            return 'Diëtetiek'
        } else {
            return 'Nieuw dossier'
        }
    }
    GetComplaintDescription(id) {
        let description = ''
        store.state.ClientsStore.complaints.forEach((item) => {
            if (item.id === id) {
                description = item.description
            }
        })
        return String(description)
    }
    async AddPermissions(dossierId, dataObj) {

        const docRef = doc(db, store.state.customer + 'dossier_permissions', dossierId)

        await setDoc(docRef, dataObj)
            .catch(e => {
                console.log(e)
                return ''
            });
    }
    async UpdatePermissions(dossierId, dataObj) {
        const docRef = doc(db, store.state.customer + 'dossier_permissions', dossierId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async GetPermissions(dossierId) {
        if (dossierId === '') {
            return null
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'dossier_permissions', dossierId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetPermissionsStatus(dossierId) {
        if (dossierId === '') {
            return false
        }

        const docSnap = await getDoc(doc(db, store.state.customer + 'dossier_permissions', dossierId))
        const dossierPermissions = docSnap.data()

        if (dossierPermissions === undefined) {
            return false
        } else {
            return dossierPermissions.permissionRequested
        }
    }
    async AddDefaultTasksToDossier(clientId, dossierId) {
        // Set up tasks
        this.complaintTasks = await ComplaintDataService.GetDefaultTasks()
        const client = await ClientDataService.GetClient(clientId)

        for (const item of this.complaintTasks) {

            const messageObj = {
                dossier_id: dossierId,
                client_id: clientId,
                client_name: client.first_name + ' ' + client.last_name,
                message_type: 'task',
                creation_date: new Date(Date.now()).getTime(),
                show_type: 'hidden',
                task_id: item.id,
                task_description: item.description,
                task_link: item.task_link ? item.task_link : '',
                session: 1,
                status: 'open',
                employee_id: store.state.currentEmployeeId,
                template_id: item.template_id ? item.template_id : '',
            }

            if (await DossierMessagesDataService.CheckOfTaskExists(dossierId, messageObj) === false) {
                await DossierMessagesDataService.AddDossierMessage(messageObj)
            }
        }


    }
}

export default new DossierDataService()