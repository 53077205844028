<template>
  <div v-if="clientModal.state.active" class="modal show" id="clientModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header gradient">
          <div class="row text-secondary text-center">
            <h5 class="modal-title text-secondary" id="clientModalLabel">
              <img v-show="form.gender === 'male'" src="@/assets/blue/male-24.png" alt="Target" class="img-fluid">
              <img v-show="form.gender === 'female'" src="@/assets/blue/female-24.png" alt="Target" class="img-fluid">
              <img v-show="form.gender === 'nonbinair'" src="@/assets/blue/nonbinair-24.png" alt="Target" class="img-fluid">
              <img v-show="form.gender === 'unknown'" src="@/assets/blue/help-24.png" alt="Target" class="img-fluid">
              Cliënt - <b>{{ (patient ? patient.first_name : '') +  ' ' + (patient ? patient.last_name : '') }}</b>
            </h5>
          </div>
          <button type="button" class="btn-close" @click="closeModal()"></button>
        </div>
        <div class="modal-body overflow-auto" :style="isMobile ? 'min-height: 480px; max-height: 480px;' : 'min-height: 580px; max-height: 580px;'">
          <div class="form-group text-secondary mb-0 pb-0" style="min-height: 60vh;">
            <div class="row mt-2 m-0 text-secondary">
              <div
                  class="pt-1 pb-1"
                  @click="currentTab = 'general'"
                  :style="currentTab === 'general' ? 'border-bottom: 3px solid #199fd6; color: #199fd6;'  : 'border-bottom: 3px solid lightgray;'"
                  :class="[currentTab === 'general' ? 'selected-tab' : 'tab', isMobile ? 'col' : 'col-2']"
              >
                <img src="@/assets/blue/general-24.png" alt="general" class="img-fluid" >
                Algemeen
              </div>
              <div
                  class="pt-1 pb-1"
                  @click="currentTab = 'additional'"
                  :style="currentTab === 'additional' ? 'border-bottom: 3px solid #199fd6; color: #199fd6;'  : 'border-bottom: 3px solid lightgray;'"
                  :class="[currentTab === 'additional' ? 'selected-tab' : 'tab', isMobile ? 'col' : 'col-2']"
              >
                <img src="@/assets/blue/additional-24.png" alt="additional" class="img-fluid" >
                Aanvullend
              </div>
              <div
                  class="pt-1 pb-1"
                  @click="currentTab = 'permissions'"
                  :style="currentTab === 'permissions' ? 'border-bottom: 3px solid #199fd6; color: #199fd6;'  : 'border-bottom: 3px solid lightgray;'"
                  :class="[currentTab === 'permissions' ? 'selected-tab' : 'tab', isMobile ? 'col' : 'col-2']"
              >
                <img src="@/assets/blue/permissions-24.png" alt="permissions" class="img-fluid" >
                Toestemmingen
              </div>
              <div
                  class="pt-1 pb-1"
                  @click="currentTab = 'policies'"
                  :style="currentTab === 'policies' ? 'border-bottom: 3px solid #199fd6; color: #199fd6;'  : 'border-bottom: 3px solid lightgray;'"
                  :class="[currentTab === 'policies' ? 'selected-tab' : 'tab', isMobile ? 'col' : 'col-2']"
              >
                <img src="@/assets/blue/umbrella-24.png" alt="policies" class="img-fluid" >
                Verzekering
              </div>
              <div
                  class="pt-1 pb-1"
                  @click="currentTab = 'financial'"
                  :style="currentTab === 'financial' ? 'border-bottom: 3px solid #199fd6; color: #199fd6;'  : 'border-bottom: 3px solid lightgray;'"
                  :class="[currentTab === 'financial' ? 'selected-tab' : 'tab', isMobile ? 'col' : 'col-2']"
              >
                <img src="@/assets/blue/coins-24.png" alt="financial" class="img-fluid" >
                Financieel
              </div>
              <div v-if="!isMobile" class="col" style="border-bottom: 3px solid lightgray;"></div>
            </div>
            <div class="row m-0 pt-3 pb-3 ps-2 pe-1 tab-content">
              <div class="col">
                <div v-show="verificationMessage.length !== 0" class="alert alert-danger p-1" role="alert">
                  <div v-for="(message) in verificationMessage" :key="message.index" class="row">
                    <div class="col">
                      {{ message }}
                    </div>
                  </div>
                </div>

                <div v-show="currentTab === 'general'">
                  <div class="row mt-2 mb-2 text-start">
                    <div class="col-sm-6">
                      <div class="row">
                        <label for="inputLastName" class="col-4 col-form-label text-end">Geslacht</label>
                        <div class="col-8 mt-1">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineGender" id="inlineGender1" value="male" v-model="form.gender">
                            <label class="form-check-label" for="inlineGender1">Man</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineGender" id="inlineGender2" value="female" v-model="form.gender">
                            <label class="form-check-label" for="inlineGender2">Vrouw</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineGender" id="inlineGender3" value="nonbinair" v-model="form.gender">
                            <label class="form-check-label" for="inlineGender3">Non-binair</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineGender" id="inlineGender4" value="unknown" v-model="form.gender">
                            <label class="form-check-label" for="inlineGender4">Onb.</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-4"/>
                        <div class="col-8 text-start">
                          <input type="checkbox" class="form-check-input me-2"  id="inputArchive" v-model="form.archive" checked>
                          <label class="form-check-label text-start" for="inputArchive">
                            Archief
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputLastName" class="col-4 col-form-label text-end">Achternaam</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" :class="verification['last_name'] ? 'required' : 'verification-false'" id="inputLastName" v-model="form.last_name"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputFirstName" class="col-4 col-form-label text-end">Voornaam</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" :class="verification['first_name'] ? 'required' : 'verification-false'" id="inputFirstName" v-model="form.first_name"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputInitials" class="col-4 col-form-label text-end">Voorletters</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputInitials" v-model="form.initials"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputBirthday" class="col-4 col-form-label text-end text-truncate">Geboortedatum</label>
                        <div class="col-8">
                          <input type="date" class="form-control text-secondary" id="inputBirthday" v-model="form.birthdate"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputStreet" class="col-4 col-form-label text-end">Straat</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputStreet" v-model="form.street"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputHouseNumber" class="col-4 col-form-label text-end">Huisnummer</label>
                        <div class="col-3">
                          <input type="text" class="form-control text-secondary" id="inputHouseNumber" v-model="form.house_number"/>
                        </div>
                        <div class="col-4">
                          <input type="text" class="form-control text-secondary" id="inputHouseNumberSuffix" v-model="form.house_number_suffix"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputPostalCode" class="col-4 col-form-label text-end">Postcode</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputPostalCode" v-model="form.postal_code"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputCity" class="col-4 col-form-label text-end">Plaats</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputCity" v-model="form.city"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputPhone" class="col-4 col-form-label text-end">Telefoon</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputPhone" v-model="form.phone"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputMobile" class="col-4 col-form-label text-end">Mobiel</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputMobile" v-model="form.mobile"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputEmail" class="col-4 col-form-label text-end">Email</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" :class="[verification['email'] ? '' : 'verification-false', form.email_invoice || form.email_appointment_confirmation ? 'required' : '']" id="inputEmail" v-model="form.email"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputBSN" class="col-4 col-form-label text-end">BSN</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputBSN" v-model="form.bsn"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputSalutation" class="col-4 col-form-label text-end">Aanhef</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputSalutation" v-model="form.salutation"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="currentTab === 'additional'">
                  <div class="row mt-2 text-start">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label class="col-4 col-form-label text-end" :for="form.general_practitioner">Huisarts</label>
                        <div class="col-7">
                          <select class="form-select text-secondary" v-model="form.general_practitioner_id">
                            <option :value="''"/>
                            <option v-for="(thirdParty, thirdPartyIndex) in thirdParties" :key="thirdPartyIndex" :value="thirdParty.id">
                              {{ thirdParty.first_name + ' ' + thirdParty.last_name }}
                            </option>
                          </select>
                        </div>
                        <div class="col-1 text-start">
                          <img src="@/assets/add-24.png" alt="" class="img-fluid" @click="showThirdPartyModal('')" v-tippy="{ content: 'Nieuwe huisarts', }"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label class="col-4 col-form-label text-end" >Medicatie</label>
                        <div class="col-8">
                          <textarea class="form-control text-secondary" rows="3" v-model="form.medication"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label class="col-4 col-form-label text-end text-truncate" >Genotsmiddelen</label>
                        <div class="col-8">
                          <textarea class="form-control text-secondary" rows="3" v-model="form.stimulants"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label class="col-4 col-form-label text-end text-truncate" >Bijzonderheden</label>
                        <div class="col-8">
                          <textarea class="form-control text-secondary scrollbar" rows="3" v-model="form.bijzonderheden"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <div class="col-4"></div>
                        <div class="col text-start text-truncate">
                          <input type="checkbox" class="form-check-input opacity-50 me-2"  id="inputShowParticularities" v-model="form.show_bijzonderheden" checked>
                          <label class="form-check-label text-start" for="inputShowParticularities">
                            Toon bijzonderheden in hoofdscherm
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="currentTab === 'financial'">
                  <div class="row mt-3">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <div class="col-4"></div>
                        <div class="col text-start text-truncate">
                          <input type="checkbox" class="form-check-input opacity-50 me-2"  id="inputAlternativeBillingAddress" v-model="form.alternative_billing_address">
                          <label class="form-check-label text-start" for="inputAlternativeBillingAddress">
                            Gebruik alternatief factuuradres
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.alternative_billing_address === true" class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputAltName" class="col-4 col-form-label text-end">Naam</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputAltName" v-model="form.alt_name"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputAltName2" class="col-4 col-form-label text-end"></label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputAltName2" v-model="form.alt_name_2"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.alternative_billing_address === true" class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputAltStreet" class="col-4 col-form-label text-end">Straat</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputAltStreet" v-model="form.alt_street"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputAltHouseNumber" class="col-4 col-form-label text-end">Huisnummer</label>
                        <div class="col-3">
                          <input type="text" class="form-control text-secondary" id="inputAltHouseNumber" v-model="form.alt_house_number"/>
                        </div>
                        <div class="col-4">
                          <input type="text" class="form-control text-secondary" id="inputAltHouseNumberSuffix" v-model="form.alt_house_number_suffix"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.alternative_billing_address === true" class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputAltPostalCode" class="col-4 col-form-label text-end">Postcode</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputAltPostalCode" v-model="form.alt_postal_code"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputAltCity" class="col-4 col-form-label text-end">Plaats</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputAltCity" v-model="form.alt_city"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-show="form.alternative_billing_address === true" class="row">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label for="inputAltEmail" class="col-4 col-form-label text-end">Email</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" :class="[verification['alt_email'] ? '' : 'verification-false']" id="inputAltEmail" v-model="form.alt_email"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="currentTab === 'policies'">
                  <div class="row mt-2 text-start">
                    <div class="col-sm-6">
                      <div class="row mb-3">
                        <label class="col-4 col-form-label text-end" :for="form.insurer_label">Verzekeraar</label>
                        <div class="col-8">
                          <select class="form-select text-secondary" v-model="form.insurer_label" @change="getCompensation(form.insurer_label)">
                            <option :value="''"/>
                            <option v-for="(insurer, insurerIndex) in insurers.labels" :key="insurerIndex" :value="insurer.id">
                              {{ insurer.description }}
                            </option>
                          </select>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <label for="inputInsurerNumber" class="col-4 col-form-label text-end">Verzekerdenummer</label>
                        <div class="col-8">
                          <input type="text" class="form-control text-secondary" id="inputInsurerNumber" v-model="form.insurer_number"/>
                        </div>
                      </div>


                    </div>
                    <div class="col-sm-6">
                      <div v-show="policies.length > 0" class="row">
                        <div class="col">
                          <div class="card" style="background-color: rgba(25, 159, 214, 0.075);">
                            <div class="card-body text-secondary pb-1">
                              <div v-for="(policy) in policies" :key="policy.description">
                                <div class="row fw-bold">
                                  <div class="col">
                                    {{ policy.description }}
                                  </div>
                                </div>
                                <div class="row mb-2">
                                  <div class="col">
                                    {{ policy.compensation }}
                                  </div>
                                </div>
                              </div>
                              <div class="row small mt-2 mb-1">
                                <div class="col text-center opacity-50">
                                  Aan de gegeven informatie kunnen geen rechten worden ontleend
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

                <div v-show="currentTab === 'permissions'" class="row">
                  <div class="row mt-2 mb-3">
                    <div class="col">
                      <div class="row mb-3">
                        <div class="col-1"/>
                        <div class="col-10 text-start">
                          <input type="checkbox" class="form-check-input opacity-50 me-2"  id="inputEmailInvoice" v-model="form.email_invoice">
                          <label class="form-check-label text-start" for="inputEmailInvoice">
                            Cliënt geeft toestemming voor het versturen van facturen per email
                          </label>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-1"/>
                        <div class="col-10 text-start">
                          <input type="checkbox" class="form-check-input opacity-50 me-2"  id="inputEmailAppointmentConfirmation" v-model="form.email_appointment_confirmation">
                          <label class="form-check-label text-start" for="inputEmailAppointmentConfirmation">
                            Cliënt geeft toestemming voor het versturen van afspraakbevestigingen per email
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-light pt-2 pb-2">
          <button @click="savePatient()" class="btn btn-success opacity-75 align-middle me-2">
            <i class="bi bi-check-circle me-2" style="font-size: 1rem; color: whitesmoke"></i>
            Opslaan
          </button>
          <button type="button" class="btn btn-secondary opacity-75" @click="closeModal()">
            <i class="bi bi-x-circle me-2" style="font-size: 1rem; color: whitesmoke"></i>
            Sluiten
          </button>
        </div>
      </div>
    </div>
  </div>
  <ThirdPartyModal/>

  <div v-show="clientModal.state.active" class="dialog-bg"></div>
</template>

<script>
import clientModal from "@/services/modals/ClientModalService";
import ClientModalService from "@/services/modals/ClientModalService";
import NoraUtils from "@/services/NoraUtils";
import ClientDataService from "@/services/ClientDataService";
import insurers from "@/services/InsurerService";
import thirdPartyModal from "@/services/modals/ThirdPartyModalService";
import ThirdPartyModal from "@/components/master/thirdparty/ThirdPartyModal";
import {directive} from "vue-tippy";

export default {
  name: "ClientModal",
  components: {ThirdPartyModal},
  directives: {
    tippy: directive,
  },
  emits: ['modalEvent'],
  data() {
    return {
      form: {
        gender: 'unknown',
        last_name: '',
        first_name: '',
        initials: '',
        street: '',
        house_number: '',
        house_number_suffix: '',
        postal_code: '',
        city: '',
        phone: '',
        mobile: '',
        email: '',
        email_invoice: true,
        email_appointment_confirmation:  false,
        bsn: '',
        salutation: '',
        birthdate: '',
        archive: false,
        general_practitioner_id: '',
        bijzonderheden: '',
        show_bijzonderheden: false,
        medication: '',
        stimulants: '',
        alternative_billing_address: false,
        alt_name: '',
        alt_name_2: '',
        alt_street: '',
        alt_house_number: '',
        alt_house_number_suffix: '',
        alt_postal_code: '',
        alt_city: '',
        alt_email: '',
        insurer_number: '',
        insurer_label: '',
      },
      verification: {
        'last_name': true,
        'first_name': true,
        'email': true,
        'alt_email': true,
      },
      verificationMessage: [],
      clientModal,
      insurers,
      policies: [],
      currentTab: 'general',
    };
  },
  computed: {
    patientId() {
      return ClientModalService.state.id
    },
    patient() {
      return ClientModalService.state.client
    },
    thirdParties() {
      return this.$store.state.ClientsStore.thirdParties
    },
    isMobile() {
      return this.$store.state.mobileDevice
    },
  },
  watch: {
    patient: function(patient) {
      this.form = { ...patient };
    },
    patient_id: async function(id) {
      console.log(id)
    },
  },
  created() {
    //console.log('patientId=' + this.patientId)

  },
  methods: {
    async closeModal() {
      this.currentTab = 'general'
      this.verification['last_name'] = true
      this.verification['first_name'] = true
      this.verification['email'] = true
      this.verification['alt_email'] = true

      if (this.patientId === '') {
        this.$store.state.dossierId = ''
      }

      //this.$store.dispatch('setPatientId', this.patientId)

      await clientModal.cancel()
    },
    doVerification() {
      let result = true
      this.verificationMessage = []

      if (this.form.last_name === '') {
        result = false
        this.verification['last_name'] = false
        this.verificationMessage.push('Geen achternaam ingevuld')
        this.currentTab = 'general'
      } else {
        this.verification['last_name'] = true
      }

      if (this.form.first_name === '') {
        result = false
        this.verification['first_name'] = false
        this.verificationMessage.push('Geen voornaam ingevuld')
        this.currentTab = 'general'
      } else {
        this.verification['first_name'] = true
      }

      if (this.form.email_appointment_confirmation === true || this.form.email_invoice === true) {
        if (this.form.email === '' || NoraUtils.isValidEmail(this.form.email) === false) {
          result = false
          this.verification['email'] = false
          this.verificationMessage.push('Geen of ongeldig emailadres')
          this.currentTab = 'general'
        } else {
          this.verification['email'] = true
        }
      }

      if (this.form.email !== '') {
        if (NoraUtils.isValidEmail(this.form.email) === false) {
          result = false
          this.verification['email'] = false
          this.verificationMessage.push('Geen geldig emailadres')
        } else {
          this.verification['email'] = true
        }
      } else {
        this.verification['email'] = true
      }

      if (this.form.alt_email !== '' && this.form.alternative_billing_address === true) {
        if (NoraUtils.isValidEmail(this.form.alt_email) === false) {
          result = false
          this.verification['alt_email'] = false
          this.verificationMessage.push('Geen geldig alternatief emailadres')
        } else {
          this.verification['alt_email'] = true
        }
      } else {
        this.verification['alt_email'] = true
      }

      return result
    },
    async savePatient() {
      if (this.doVerification() === false) {
        return
      }

      const dataObj = {
        created_by: this.$store.state.currentEmployeeId,
        gender: this.form.gender || 'unknown',
        first_name: this.form.first_name || '',
        initials: this.form.initials || '',
        last_name: this.form.last_name,
        birthdate: this.form.birthdate || '',
        street: this.form.street || '',
        house_number: this.form.house_number || '',
        house_number_suffix: this.form.house_number_suffix || '',
        postal_code: this.form.postal_code || '',
        city: this.form.city || '',
        phone: this.form.phone || '',
        mobile: this.form.mobile || '',
        email: this.form.email || '',
        email_invoice: this.form.email_invoice || false,
        email_appointment_confirmation: this.form.email_appointment_confirmation || false,
        bsn: this.form.bsn || '',
        salutation: this.form.salutation || '',
        archive: this.form.archive || false,
        bijzonderheden: this.form.bijzonderheden || '',
        show_bijzonderheden: this.form.show_bijzonderheden || false,
        general_practitioner_id: this.form.general_practitioner_id || '',
        medication: this.form.medication || '',
        stimulants: this.form.stimulants || '',
        alternative_billing_address: this.form.alternative_billing_address || false,
        alt_name: this.form.alt_name || '',
        alt_name_2: this.form.alt_name_2 || '',
        alt_street: this.form.alt_street || '',
        alt_house_number: this.form.alt_house_number || '',
        alt_house_number_suffix: this.form.alt_house_number_suffix || '',
        alt_postal_code: this.form.alt_postal_code || '',
        alt_city: this.form.alt_city || '',
        alt_email: this.form.alt_email || '',
        insurer_label: this.form.insurer_label || '',
        insurer_number: this.form.insurer_number || '',
      };

      if (this.patientId === '') {
        const clientId = await ClientDataService.AddClient(dataObj)
        ClientModalService.state.id = clientId
        this.$store.dispatch('setPatientId', clientId)
      } else {
        await ClientDataService.UpdateClient(this.patientId, dataObj).then(() => {
          this.$store.dispatch('setPatientId', this.patientId);
        })
      }

      this.closeModal()
    },
    getCompensation(insurerId) {
      this.insurers.labels.forEach((insurer) => {
        if (insurer.id === insurerId) {
          this.policies = insurer.policies
        }
      })
    },
    async showThirdPartyModal(id) {
      await thirdPartyModal
        .show(id)
        .then(() => {
          this.$store.dispatch('ClientsStore/getThirdParties').then(() => {
            this.form.general_practitioner_id = thirdPartyModal.state.id
          })
        })
    },
  },
  mounted() {
    if (this.patient) {
      this.form = this.patient;
      if (this.form.insurer_label !== '') {
        this.getCompensation(this.form.insurer_label)
      }
    }
  },
}
</script>

<style scoped>
.required {
  border-bottom: 3px solid rgba(25, 159, 214, 0.8);
}
.verification-false {
  border-bottom: 3px solid rgb(243, 17, 17);
}
.tab {
  background-color: white;
  border-color: lightgray;
  border-radius: 5px 5px 0px 0px;
  border-style: solid;
  border-width: 1px;
  border-bottom: 3px solid lightgray;
}
.tab:hover {
  background-color: rgba(55, 155, 237, 0.05);
}
.selected-tab {
  background-color: rgba(25, 159, 214, 0.075);
  border-color: lightgray;
  border-radius: 5px 5px 0px 0px;
  border-style: solid;
  border-width: 1px;
  border-bottom: 3px solid #199fd6;
}
.selected-tab:hover {
  background-color: rgba(55, 155, 237, 0.05);
}
</style>