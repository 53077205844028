const questionnaire_koos_ps_v1 = {
    'id': 'KOOS_PS_v1',
    'description': 'Knee Injury and Osteoarthritis Outcome Score (KOOS-PS)',
    'short': 'KOOS-PS',
    'questions': [
        {
            'number': 1,
            'info': 'Wilt u voor elk van de onderstaande activiteiten aangeven hoeveel moeite u de afgelopen week ' +
                'heeft ervaren tijdens deze activiteiten vanwege uw knie.',
            'description': 'Opstaan vanuit bed',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'Sokken/kousen aantrekken',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Opstaan vanuit een stoel',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Bukken naar de grond/iets oppakken van de grond',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'Draaien op een belaste knie',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': 'Knielen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': 'Op uw hurken zitten',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Totaalscore',
            'formule': 'S1+S2+S3+S4+S5+S6+S7',
            'min': 0,
            'max': 100,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Beperkingen',
            'goal': 'Verminderen van beperkingen in ADL',
            'operation': 'Actieve en passieve mobilisatie',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_koos_ps_v1