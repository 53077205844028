// ./services/GeneralOnlineRequestsDataService

import {
    deleteDoc,
    doc,
    getDoc,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";

class GeneralOnlineRequestsDataService {
    async AddGeneralOnlineRequest(dataObject, onlineRequestId) {
        const docRef = doc(db, 'online_requests', onlineRequestId)
        await setDoc(docRef, dataObject)
            .catch(e => {
                console.log(e)
            })

        return onlineRequestId
    }
    async UpdateGeneralOnlineRequest(dataObject, onlineRequestId) {
        const docRef = doc(db, 'online_requests', onlineRequestId);

        await setDoc(docRef, dataObject, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveGeneralOnlineRequest(onlineRequestId) {
        const docRef = doc(db, 'online_requests', onlineRequestId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetGeneralOnlineRequest(onlineRequestId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db,'online_requests', onlineRequestId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new GeneralOnlineRequestsDataService()