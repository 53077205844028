/*
invoice
-	id
-   description
-	invoice_date
-	invoice_number
-   invoice_type (Debet/Credit)
-	payment_status
-	export_status
-	client_id

invoice_specification
-	invoice_id
-	description
-	number
-	amount_excl_vat
-   amount_incl_vat
-	amount_vat
-	vat_percentage
-   status
-	session_id
 */

import {
    collection,
    getDocs,
    query,
    orderBy,
    limit,
    addDoc,
    getDoc,
    doc,
    updateDoc,
    deleteDoc,
    where, setDoc
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import SessionDataService from "@/services/SessionDataService";
import ProductDataService from "@/services/ProductDataService";
import ProductInvoicingDataService from "@/services/ProductInvoicingDataService";
import LocationsDataService from "@/services/LocationsDataService";
import ClientDataService from "@/services/ClientDataService";
import PdfService from "@/services/pdf/PdfService";
import DebtorDataService from "@/services/DebtorDataService";
import SalesBookingDataService from "@/services/SalesBookingDataService";

class InvoicingDataService {
    async CreateInvoice(dataObj) {

        // Get invoice number
        let invoiceNumber = 1
        invoiceNumber = await InvoicingDataService.getInvoiceNumber()

        // Expiration date
        let expirationDate = new Date()
        expirationDate.setDate(expirationDate.getDate() + store.state.settings.financialSettings.term_of_payment)

        // Templates
        let invoiceTemplateId = ''
        let reminderInvoiceTemplateId = ''
        let invoiceEmailTemplateId = ''
        let reminderInvoiceEmailTemplateId = ''

        const location = await LocationsDataService.GetLocation(dataObj[0].location_id)
        if (location !== null) {
            invoiceTemplateId = location.invoice_template_id || ''
            reminderInvoiceTemplateId = location.reminder_invoice_template_id || ''
            invoiceEmailTemplateId = location.invoice_email_template_id || ''
            reminderInvoiceEmailTemplateId = location.reminder_invoice_email_template_id || ''
        }

        // Create invoice
        const invoiceObj = {
            'description': 'Factuur - ' + dataObj[0].client_name,
            'invoice_date': Date.now(),
            'invoice_number': invoiceNumber,
            'invoice_type': 'debit',
            'payment_status': 'not_paid',
            'export_status': 'not_exported',
            'client_id': dataObj[0].client_id,
            'client_name': dataObj[0].client_name,
            'expiration_date': expirationDate,
            'status': 'created',
            'template_id': invoiceTemplateId,
            'reminder_template_id': reminderInvoiceTemplateId,
            'email_template_id': invoiceEmailTemplateId,
            'reminder_email_template_id': reminderInvoiceEmailTemplateId,
        }

        const invoiceRef = collection(db, store.state.customer + 'invoices')

        let invoiceId = -1
        await addDoc(invoiceRef, invoiceObj)
            .then((docRef) => {
                invoiceId = docRef.id
            })
            .catch((e) => {
                console.log(e)
            })

        // Create history item
        const historyObj = {
            date: new Date(),
            action: 'created',
            mail_id: '',
            document_path: '',
        }
        await InvoicingDataService.AddInvoiceHistory(invoiceId, historyObj)

        // Create invoice specification and update payment status of session
        let totalInvoiceAmountInclVat = 0
        let totalInvoiceAmountExclVat = 0
        let totalInvoiceAmountVat = 0
        let invoiceSpecOrdering = 1

        for (let i = 0; i < dataObj.length; i++) {
            // product
            const product = await ProductDataService.GetProduct(dataObj[i].product_id)
            let productDescription = dataObj[i].session_id !== '' ? dataObj[i].date + ' - ' + dataObj[i].product_description : dataObj[i].product_description
            if (product.product_code !== '') {
                productDescription = productDescription + ' (' + product.product_code + ')'
            }

            // Create invoice specification
            const invoiceSpecObj = {
                'number': invoiceSpecOrdering,
                'description': productDescription,
                'amount': dataObj[i].amount,
                'amount_excl_vat': dataObj[i].amount_excl_vat,
                'amount_incl_vat': dataObj[i].amount_incl_vat,
                'amount_vat': dataObj[i].amount_vat,
                'vat_percentage': dataObj[i].vat_percentage,
                'product_id': dataObj[i].product_id,
                'product_invoicing_id': dataObj[i].id,
                'status': '',
                'session_id': dataObj[i].session_id,
            }

            const invoiceSpecRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/specification')

            await addDoc(invoiceSpecRef, invoiceSpecObj)
                .catch((e) => {
                    console.log(e)
                })

            // Update payment status of session
            if (dataObj[i].product_type === 'appointment') {
                const sessionObj = {
                    'financial_status': 'invoiced',
                    'payment_status': 'not_paid',
                    'session_amount': invoiceSpecObj.amount_incl_vat,
                }
                await SessionDataService.UpdateSessionFields(dataObj[i].session_id, sessionObj)
            }

            // Update payment status
            const productInvoicingObj = {
                'financial_status': 'invoiced',
                'payment_status': 'not_paid',
            }
            await ProductInvoicingDataService.UpdateProductInvoicing(dataObj[i].id, productInvoicingObj)

            invoiceSpecOrdering++
            totalInvoiceAmountInclVat += invoiceSpecObj.amount_incl_vat
            totalInvoiceAmountExclVat += invoiceSpecObj.amount_excl_vat
            totalInvoiceAmountVat += invoiceSpecObj.amount_vat
        }

        const updateInvoiceObj = {
            total_amount_incl_vat: totalInvoiceAmountInclVat,
            total_amount_excl_vat: totalInvoiceAmountExclVat,
            total_amount_vat: totalInvoiceAmountVat,
        }
        const docRef = doc(db, store.state.customer + 'invoices', invoiceId)
        await updateDoc(docRef, updateInvoiceObj)

        return invoiceId
    }
    async CreateCreditInvoice(dataObj, originalInvoiceId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            // Get invoice number
            let invoiceNumber = 1
            await InvoicingDataService.getInvoiceNumber().then((value) => {
                invoiceNumber = value
            }, (error) => {
                console.log(error)
                invoiceNumber = 1
            })

            // Expiration date
            let expirationDate = new Date()
            expirationDate.setDate(expirationDate.getDate() + store.state.settings.financialSettings.term_of_payment)


            // Create invoice
            const creditInvoiceObj = {
                'description': 'Creditfactuur - ' + dataObj[0].client_name,
                'invoice_date': Date.now(),
                'invoice_number': invoiceNumber,
                'invoice_type': 'credit',
                'payment_status': 'not_paid',
                'export_status': 'not_exported',
                'client_id': dataObj[0].client_id,
                'expiration_date': expirationDate,
            }

            const creditInvoiceRef = collection(db, store.state.customer + 'invoices')

            let invoiceId = -1
            await addDoc(creditInvoiceRef, creditInvoiceObj)
                .then((docRef) => {
                    invoiceId = docRef.id
                })
                .catch((e) => {
                    reject(e)
                })

            let totalInvoiceAmountInclVat = 0
            let totalInvoiceAmountExclVat = 0
            let totalInvoiceAmountVat = 0

            // Create invoice specification and update payment status of session
            for (let i = 0; i < dataObj.length; i++) {

                // Create invoice specification
                const invoiceSpecObj = {

                    'amount': dataObj[i].amount,
                    'amount_excl_vat': dataObj[i].amount_excl_vat * -1,
                    'amount_incl_vat': dataObj[i].amount_incl_vat * -1,
                    'amount_vat': dataObj[i].amount_vat * -1,
                    'description': dataObj[i].description,
                    'product_id': dataObj[i].product_id,
                    'product_invoicing_id': dataObj[i].product_invoicing_id,
                    'session_id': dataObj[i].session_id,
                    'status': '',
                    'vat_percentage': dataObj[i].vat_percentage,
                }

                const invoiceSpecRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/specification')

                await addDoc(invoiceSpecRef, invoiceSpecObj)
                    .catch((e) => {
                        reject(e)
                    })

                // Update payment status of session
                if (dataObj[i].session_id !== '') {
                    const sessionObj = {
                        'financial_status': 'not_invoiced',
                        'payment_status': 'not_paid',
                    }
                    await SessionDataService.UpdateSessionFields(dataObj[i].session_id, sessionObj)
                }

                // Update status of product invoicing item
                const productInvoicingObj = {
                    'financial_status': 'not_invoiced',
                    'payment_status': 'not_paid',
                }
                await ProductInvoicingDataService.UpdateProductInvoicing(dataObj[i].product_invoicing_id, productInvoicingObj)

                // Update invoicing specification
                const invoiceSpecificationObj = {
                    'credited': true,
                }

                await InvoicingDataService.UpdateInvoiceSpecification(originalInvoiceId, dataObj[i].id, invoiceSpecificationObj)


                // Total amounts
                totalInvoiceAmountInclVat += invoiceSpecObj.amount_incl_vat
                totalInvoiceAmountExclVat += invoiceSpecObj.amount_excl_vat
                totalInvoiceAmountVat += invoiceSpecObj.amount_vat
            }

            const updateInvoiceObj = {
                total_amount_incl_vat: totalInvoiceAmountInclVat,
                total_amount_excl_vat: totalInvoiceAmountExclVat,
                total_amount_vat: totalInvoiceAmountVat,
            }
            const docRef = doc(db, store.state.customer + 'invoices', invoiceId)
            await updateDoc(docRef, updateInvoiceObj)

            resolve('ok')
        })
    }
    async UpdateInvoice(invoiceId, dataObj) {
        const docRef = doc(db, store.state.customer + 'invoices', invoiceId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    static async UpdateInvoice(invoiceId, invoiceStatusObj) {
        const docRef = doc(db, store.state.customer + 'invoices', invoiceId);

        await setDoc(docRef, invoiceStatusObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async CreateInvoice_backup(dataObj) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            // Get invoice number
            let invoiceNumber = 1
            await InvoicingDataService.getInvoiceNumber().then((value) => {
                invoiceNumber = value
            }, () => {
                invoiceNumber = 1
            })

            // Expiration date
            let expirationDate = new Date()
            expirationDate.setDate(expirationDate.getDate() + store.state.settings.financialSettings.term_of_payment)


            // Create invoice
            const invoiceObj = {
                'description': 'Factuur - ' + dataObj[0].client_name,
                'invoice_date': Date.now(),
                'invoice_number': invoiceNumber,
                'invoice_type': 'debit',
                'payment_status': 'not_paid',
                'export_status': 'not_exported',
                'client_id': dataObj[0].client_id,
                'expiration_date': expirationDate,
            }

            const invoiceRef = collection(db, store.state.customer + 'invoices')

            let invoiceId = -1
            await addDoc(invoiceRef, invoiceObj)
                .then((docRef) => {
                    invoiceId = docRef.id
                })
                .catch((e) => {
                    reject(e)
                })

            let totalInvoiceAmountInclVat = 0
            let totalInvoiceAmountExclVat = 0
            let totalInvoiceAmountVat = 0

            // Create invoice specification and update payment status of session
            for (let i = 0; i < dataObj.length; i++) {
                // Get product and product rates
                const spec = dataObj[i]
                let product = null
                await ProductDataService.GetProduct(spec.product_id).then(async function(result) {
                    product = result

                    product.rates = []
                    await ProductDataService.GetProductRates(spec.product_id).then((result) => {
                        result.forEach((rate) => {
                            product.rates.push(rate)
                        })
                    })
                })

                if (product.rates.length === 0) {
                    reject('Geen tarief bekend bij dit product')
                    await InvoicingDataService.RemoveInvoice(invoiceId)
                    return null
                }

                // Create invoice specification
                const invoiceSpecObj = {
                    'description': dataObj[i].date + ' - ' + product.description,//dataObj[0].client_name,
                    'amount': 1,
                    'amount_excl_vat': product.rates[0].amount_excl_vat,
                    'amount_incl_vat': product.rates[0].amount_incl_vat,
                    'amount_vat': product.rates[0].amount_vat,
                    'vat_percentage': product.rates[0].vat_percentage,
                    'status': '',
                    'session_id': spec.session_id,
                }

                const invoiceSpecRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/specification')

                await addDoc(invoiceSpecRef, invoiceSpecObj)
                    .catch((e) => {
                        reject(e)
                    })

                // Update payment status of session
                const sessionObj = {
                    'financial_status': 'invoiced',
                    'payment_status': 'not_paid',
                    'session_amount': invoiceSpecObj.amount_incl_vat,
                }
                await SessionDataService.UpdateSessionFields(spec.session_id, sessionObj)

                totalInvoiceAmountInclVat += invoiceSpecObj.amount_incl_vat
                totalInvoiceAmountExclVat += invoiceSpecObj.amount_excl_vat
                totalInvoiceAmountVat += invoiceSpecObj.amount_vat
            }

            const updateInvoiceObj = {
                total_amount_incl_vat: totalInvoiceAmountInclVat,
                total_amount_excl_vat: totalInvoiceAmountExclVat,
                total_amount_vat: totalInvoiceAmountVat,
            }
            const docRef = doc(db, store.state.customer + 'invoices', invoiceId)
            await updateDoc(docRef, updateInvoiceObj)

            resolve('ok')
        })
    }
    async GetProductsToBeInvoiced(employeeId, productId, locationId) {
        const productsRef = collection(db, store.state.customer + 'product_invoicing')
        let productsQuery = null

        if (employeeId === '') {
            if (productId === '') {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'not_invoiced'),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            } else {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'not_invoiced'),
                    where('product_id', '==', productId),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            }
        } else {
            if (productId === '') {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'not_invoiced'),
                    where('employee_id', '==', employeeId),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            } else {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'not_invoiced'),
                    where('employee_id', '==', employeeId),
                    where('product_id', '==', productId),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            }

        }

        const snapShot = await getDocs(productsQuery)

        if (snapShot.empty) {
            console.log('empty')
            return []
        }

        let products = []
        snapShot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id

            if (!('location_id' in data)) {
                data.location_id = ''
            }

            if (locationId === '' || data.location_id === locationId) {
                products.push(data)
            }
        })

        for (let i = 0; i < products.length; i++) {
            products[i].totalAmount = products[i].amount_incl_vat
            products[i].incomplete = []
        }

        return products
    }
    async GetProductsDoNotInvoice(employeeId, productId, locationId) {
        const productsRef = collection(db, store.state.customer + 'product_invoicing')
        let productsQuery = null

        if (employeeId === '') {
            if (productId === '') {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'do_not_invoice'),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            } else {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'do_not_invoice'),
                    where('product_id', '==', productId),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            }
        } else {
            if (productId === '') {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'do_not_invoice'),
                    where('employee_id', '==', employeeId),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            } else {
                productsQuery = query(productsRef,
                    where('financial_status', '==', 'do_not_invoice'),
                    where('employee_id', '==', employeeId),
                    where('product_id', '==', productId),
                    orderBy('patient_name'),
                    orderBy('creation_date'))
            }

        }

        const snapShot = await getDocs(productsQuery)

        if (snapShot.empty) {
            return []
        }

        let products = []
        snapShot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id

            if (!('location_id' in data)) {
                data.location_id = ''
            }

            if (locationId === '' || data.location_id === locationId) {
                products.push(data)
            }
        })

        for (let i = 0; i < products.length; i++) {
            products[i].totalAmount = products[i].amount_incl_vat
            products[i].incomplete = []
        }

        return products
    }
    async GetSessionsToBeInvoiced() {
        const sessionRef = collection(db, store.state.customer + 'sessions',)
        const sessionQuery = query(sessionRef,
            where('financial_status', '==', 'not_invoiced'),
            orderBy('patientName'),
            orderBy('startDate', 'desc'))
        const snapShot = await getDocs(sessionQuery);

        if (snapShot.empty) {
            return []
        }

        let sessions = []
        snapShot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id

            sessions.push(data)
        })

        for (let i = 0; i < sessions.length; i++) {
            await InvoicingDataService.GetSessionAmount(sessions[i]).then((result) => {
                sessions[i].amount = result
            })
            sessions[i].incomplete = InvoicingDataService.GetIncomplete(sessions[i])
        }

        return sessions
    }
    static async GetSessionAmount(session) {
        if (session.product_id) {
            let rates = []
            await ProductDataService.GetProductRates(session.product_id).then((result) => {
                result.forEach((rate) => {
                    rates.push(rate)
                })
            })

            if (rates.length === 0) {
                return Promise.resolve(0)
            }

            return Promise.resolve(rates[0].amount_incl_vat)
        } else {
            return Promise.resolve(0)
        }
    }
    static GetIncomplete(session) {
        let incomplete = []

        if (new Date(session.textStartDatum) > Date.now()) {
            incomplete.push('Sessie datum ligt in de toekomst')
        }

        if (session.amount) {
            if (session.amount === 0)
            incomplete.push('Geen geldig tarief ' + session.amount)
        } else {
            incomplete.push('Geen tarief bij product ' + session.amount)
        }

        return incomplete
    }
    async GetInvoices() {
        const invoiceRef = collection(db, store.state.customer + 'invoices', )
        const invoiceQuery = query(invoiceRef, orderBy('invoice_number', 'desc'))
        const snapShot = await getDocs(invoiceQuery);

        if (snapShot.empty) {
            return []
        }

        let invoices = []
        snapShot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id
            /*
            data.specification = []

            InvoicingDataService.GetInvoiceSpecification(doc.id).then((result) => {
                result.forEach(spec => {

                    data.specification.push(spec)
                })
            })

            console.log('data:', '=>', data)

             */
            invoices.push(data)
        })
        //const sortedSessions = sessions.sort((b, a) => new Date(b.startDate.seconds) - new Date(a.startDate.seconds))
        const sortedInvoices = invoices.sort((b, a) => new Date(b.invoice_date) - new Date(a.invoice_date))

        return Promise.resolve(sortedInvoices)
    }
    async GetInvoicesForClient(clientId) {
        const invoiceRef = collection(db, store.state.customer + 'invoices', )
        const invoiceQuery = query(invoiceRef, where('client_id', '==', clientId), orderBy('invoice_number', 'desc'))
        const snapShot = await getDocs(invoiceQuery);

        if (snapShot.empty) {
            return []
        }

        let invoices = []
        snapShot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id
            invoices.push(data)
        })

        return Promise.resolve(invoices)
    }
    async GetInvoice(invoiceId) {
        if (invoiceId === '' || invoiceId === '-1') {
            return
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'invoices', invoiceId));
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }

        })

    }
    async RemoveInvoice(invoiceId) {
        const invoiceDoc = doc(db, store.state.customer + 'invoices', invoiceId)
        await deleteDoc(invoiceDoc)
            .then(() => {
                console.log('invoice deleted ' + invoiceId)
            })
            .catch((e) => {
                console.log(e)
            })
    }
    static async RemoveInvoice(invoiceId) {
        const invoiceDoc = doc(db, store.state.customer + 'invoices', invoiceId)
        await deleteDoc(invoiceDoc)
            .then(() => {
                console.log('invoice deleted ' + invoiceId)
            })
            .catch((e) => {
                console.log(e)
            })
    }
    async GetInvoiceSpecification(invoiceId) {

        const invoiceSpecRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/specification')
        const snapshot = await getDocs(invoiceSpecRef)

        if (snapshot.empty) {
            return []
        }

        let invoiceSpecification = []
        let number = 1
        snapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id
            data.selected = false
            if (!('number' in data)) {
                data.number = number
                number++
            }

            invoiceSpecification.push(data)
        })

        return Promise.resolve(invoiceSpecification.sort((b,a) => b.number - a.number))
    }
    async GetInvoiceHistory(invoiceId) {

        const invoiceHistoryRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/history')
        const snapshot = await getDocs(invoiceHistoryRef)

        if (snapshot.empty) {
            return []
        }

        let invoiceHistory = []
        snapshot.forEach(doc => {
            let data = doc.data()
            data.id = doc.id

            invoiceHistory.push(data)
        })

        return Promise.resolve(invoiceHistory.sort((a, b) => b.date - a.date))
    }
    async SetInvoicePaymentStatus(invoiceId, status) {
        const paymentStatus = (status) ? 'paid' : 'not_paid'
        const docObj = {
            payment_status: paymentStatus,
        }

        const invoiceSpecRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/specification')
        const invoiceSpecDocs = await getDocs(invoiceSpecRef)

        invoiceSpecDocs.forEach((invoiceSpec) => {
            const data = invoiceSpec.data()

            if (data.product_invoicing_id !== '') {
                ProductInvoicingDataService.UpdateProductInvoicing(data.product_invoicing_id, docObj)
            }
            if (data.session_id !== '') {
                SessionDataService.UpdateSessionFields(data.session_id, docObj)
            }

        })

        const invoiceRef = doc(db, store.state.customer + 'invoices', invoiceId)
        await updateDoc(invoiceRef, docObj)

        // Create history item
        const historyObj = {
            date: new Date(),
            action: status ? 'paid' : 'not_paid',
            mail_id: '',
            document_path: '',
        }
        await InvoicingDataService.AddInvoiceHistory(invoiceId, historyObj)
    }
    static async getInvoiceNumber() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                if (store.state.settings.financialSettings.reset_invoice_number_every_year !== undefined &&
                    store.state.settings.financialSettings.reset_invoice_number_every_year === true) {

                    const start = new Date(new Date().getFullYear(), 0, 1).getTime()
                    const end = new Date(new Date().getFullYear() + 1, 0, 1).getTime()

                    const invoiceRef = collection(db, store.state.customer + 'invoices')
                    const invoiceQuery = query(invoiceRef, where('invoice_date', '>=', start), where('invoice_date', '<', end), orderBy('invoice_date', 'desc'))
                    const querySnap = await getDocs(invoiceQuery)

                    let invoices = []
                    querySnap.forEach((doc) => {
                        const data = doc.data()
                        invoices.push(data)
                        resolve(data.invoice_number + 1)
                    })

                    resolve(1)
                } else {
                    const invoiceRef = collection(db, store.state.customer + 'invoices')
                    const invoiceQuery = query(invoiceRef, orderBy('invoice_number', 'desc'), limit(1))
                    const querySnap = await getDocs(invoiceQuery)

                    querySnap.forEach((doc) => {
                        const data = doc.data()
                        resolve(data.invoice_number + 1)
                    })

                    resolve(1)
                }

            } catch (e) {
                console.log(e)
                reject(9999)
            }
        })
    }
    async getInvoiceNumberFromYear(year) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const start = new Date(year, 0, 1).getTime()
                const end = new Date(year + 1, 0, 1).getTime()

                const invoiceRef = collection(db, store.state.customer + 'invoices')
                const invoiceQuery = query(invoiceRef, where('invoice_date', '>=', start), where('invoice_date', '<', end), orderBy('invoice_date', 'desc'))
                const querySnap = await getDocs(invoiceQuery)

                let invoices = []
                querySnap.forEach((doc) => {
                    const data = doc.data()
                    invoices.push(data)
                    resolve(data.invoice_number + 1)
                })

                resolve(1)
            } catch (e) {
                console.log(e)
                reject(9999)
            }
        })
    }
    static async getSomething() {
        return new Promise(function (resolve, reject) {
            let value = 50 + 1
            if (value === 51) {
                resolve(value)
            } else {
                reject(99)
            }
        })
    }
    static async UpdateInvoiceSpecification(invoiceId, invoiceSpecificationId, dataObj) {
        const docRef = doc(db, store.state.customer + 'invoices/' + invoiceId + '/specification', invoiceSpecificationId)
        await updateDoc(docRef, dataObj)
        console.log('specification updated')
    }
    static async AddInvoiceHistory(invoiceId, dataObj) {
        const docRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/history')

        await addDoc(docRef, dataObj)
            .then(async () => {
                const invoiceStatusObj = {
                    status: dataObj.action === 'mail' || dataObj.action === 'document' || dataObj.action === 'not_paid' ? 'send' : dataObj.action === 'paid' ? 'paid' : dataObj.action === 'created' ? 'created': '',
                }
                await InvoicingDataService.UpdateInvoice(invoiceId, invoiceStatusObj)
            })
            .catch((e) => {
                console.log(e)
            })
    }
    async AddInvoiceHistory(invoiceId, dataObj) {
        const docRef = collection(db, store.state.customer + 'invoices/' + invoiceId + '/history')

        await addDoc(docRef, dataObj)
            .then(async () => {
                const invoiceStatusObj = {
                    status: dataObj.action === 'mail' || dataObj.action === 'document' || dataObj.action === 'not_paid' ? 'send' : dataObj.action === 'paid' ? 'paid' : '',
                }
                await InvoicingDataService.UpdateInvoice(invoiceId, invoiceStatusObj)
            })
            .catch((e) => {
                console.log(e)
            })
    }
    async InvoiceSession(sessionId) {
        const productInvoicing = await ProductInvoicingDataService.GetProductInvoicingFromSession(sessionId)

        if (productInvoicing[0].financial_status !== 'not_invoiced') {
            return null
        }

        let invoiceObj = []
        invoiceObj.push({
            'amount_excl_vat': productInvoicing[0].amount_excl_vat,
            'amount_incl_vat': productInvoicing[0].amount_incl_vat,
            'amount_vat': productInvoicing[0].amount_vat,
            'amount': 1,
            'client_id': productInvoicing[0].patient_id,
            'client_name': productInvoicing[0].patient_name,
            'date': productInvoicing[0].date,
            'id': productInvoicing[0].id,
            'location_id': productInvoicing[0].location_id,
            'product_description': productInvoicing[0].product_description,
            'product_id': productInvoicing[0].product_id,
            'product_type': productInvoicing[0].product_type,
            'session_id': productInvoicing[0].session_id ? productInvoicing[0].session_id : '',
            'vat_percentage': productInvoicing[0].vat_percentage,
        })

        await this.CreateInvoice(invoiceObj).then(async (invoiceId) => {
            const invoice = await this.GetInvoice(invoiceId)
            const client = await ClientDataService.GetClient(invoice.client_id)

            if (client.email_invoice === true) {
                await PdfService.invoice2pdf(invoiceId, '', '', 'email')
            } else {
                await PdfService.invoice2pdf(invoiceId, '', '', 'pdf')
            }
        })
    }
    async InvoiceToSalesBooking(invoiceId) {
        const invoice = await this.GetInvoice(invoiceId)

        const client = await ClientDataService.GetClient(invoice.client_id)

        // check of debtor exists
        let debtorId = await DebtorDataService.GetDebtorIdFromClientId(invoice.client_id)
        if (debtorId === '') {
            const debtorObj = {
                client_id: invoice.client_id,
                name: client.first_name + ' ' + client.last_name,
                street: client.street,
                house_number: client.house_number,
                house_number_suffix: client.house_number_suffix,
                postal_code: client.postal_code,
                city: client.city,
                phone: client.phone,
                email: client.email,
                bank_account: '',
            }
            debtorId = await DebtorDataService.AddDebtor(debtorObj)
        }

        const debtor = await DebtorDataService.GetDebtor(debtorId)

        // add sales booking
        const salesBookingObj = {
            amount_incl_vat: Number(invoice.total_amount_incl_vat / 100),
            amount_vat: Number(invoice.total_amount_vat / 100),
            vat_type: 'NL',
            debtor: debtorId,
            debtor_name: debtor.name,
            description: invoice.description,
            invoice_date: new Date(new Date(invoice.invoice_date)),//toLocaleDateString()
            invoice_number: invoice.invoice_number,
            invoice_id: invoiceId,
            payment_status: false,
            payment_date: null,
            payment_method: '',
            sales_category: '',
            sales_category_description: '',
        }
        const salesBookingId = await SalesBookingDataService.AddSalesBooking(salesBookingObj)

        // update invoice with salesBookingId
        const invoiceObj = {
            sales_booking_id: salesBookingId,
        }
        await this.UpdateInvoice(invoiceId, invoiceObj)
    }
}

export default new InvoicingDataService();