const questionnaire_4dkl_v1 = {
    'id': '4DKL_v1',
    'description': 'VierDimensionale KlachtenLijst (4DKL)',
    'short': '4DKL',
    'questions': [
        {
            'number': 1,
            'info': 'Hebt u de afgelopen week last van ...',
            'description': 'duizeligheid of een licht gevoel in het hoofd?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'pijnlijke spieren?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'flauw vallen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'pijn in de nek?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'pijn in de rug?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': 'overmatige transpiratie?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': 'hartkloppingen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 8,
            'info': '',
            'description': 'hoofdpijn?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 9,
            'info': '',
            'description': 'een opgeblazen gevoel in de buik?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 10,
            'info': '',
            'description': 'wazig zien of vlekken voor de ogen zien?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 11,
            'info': '',
            'description': 'benauwdheid?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 12,
            'info': '',
            'description': 'misselijkheid of een maag die ‘van streek’ is?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 13,
            'info': 'Hebt u de afgelopen week last van ...',
            'description': 'pijn in de buik of maagstreek?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 14,
            'info': '',
            'description': 'tintelingen in de vingers?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 15,
            'info': '',
            'description': 'een drukkend of beklemmend gevoel op de borst?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 16,
            'info': '',
            'description': 'pijn in de borst?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 17,
            'info': '',
            'description': 'neerslachtigheid?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 18,
            'info': '',
            'description': 'zomaar plotseling schrikken?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 19,
            'info': '',
            'description': 'piekeren?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 20,
            'info': '',
            'description': 'onrustig slapen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 21,
            'info': '',
            'description': 'onbestemde angst-gevoelens?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 22,
            'info': '',
            'description': 'lusteloosheid?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 23,
            'info': '',
            'description': 'beven in gezelschap van andere mensen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 24,
            'info': '',
            'description': 'angst- of paniek-aanvallen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 25,
            'info': 'Voelt u zich de afgelopen week ...',
            'description': 'gespannen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 26,
            'info': '',
            'description': 'snel geïrriteerd?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 27,
            'info': '',
            'description': 'angstig?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 28,
            'info': 'Hebt u de afgelopen week het gevoel ...',
            'description': 'dat alles zinloos is?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 29,
            'info': '',
            'description': 'dat u tot niets meer kunt komen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 30,
            'info': '',
            'description': 'dat het leven niet de moeite waard is?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 31,
            'info': '',
            'description': 'dat u geen belangstelling meer kunt opbrengen voor de mensen en dingen om u heen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 32,
            'info': '',
            'description': 'dat u ’t niet meer aankunt?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 33,
            'info': '',
            'description': 'dat het beter zou zijn als u maar dood was?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 34,
            'info': '',
            'description': 'dat u nergens meer plezier in kunt hebben?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 35,
            'info': '',
            'description': 'dat er geen uitweg is uit uw situatie?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 36,
            'info': '',
            'description': 'dat u er niet meer tegenop kunt?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 37,
            'info': '',
            'description': 'dat u nergens meer zin in hebt?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 38,
            'info': 'Hebt u de afgelopen week ...',
            'description': 'moeite met helder denken?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 39,
            'info': '',
            'description': 'moeite om in slaap te komen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 40,
            'info': '',
            'description': 'angst om alleen het huis uit te gaan?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 41,
            'info': 'Bent u de afgelopen week ...',
            'description': 'snel emotioneel?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 42,
            'info': '',
            'description': 'angstig voor iets waarvoor u helemaal niet bang zou hoeven te zijn?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 43,
            'info': '',
            'description': 'bang om te reizen in bussen, treinen of trams?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 44,
            'info': '',
            'description': 'bang om in verlegenheid te raken in gezelschap van andere mensen? ',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 45,
            'info': '',
            'description': 'Hebt u de afgelopen week weleens een gevoel of u door een onbekend gevaar bedreigd wordt?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 46,
            'info': '',
            'description': 'Denkt u de afgelopen week weleens “was ik maar dood”?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 47,
            'info': '',
            'description': 'Schieten u de afgelopen week weleens beelden in gedachten over (een) aangrijpende gebeurtenis(sen) die u hebt meegemaakt?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 48,
            'info': '',
            'description': 'Moet u de afgelopen week weleens uw best doen om gedachten of herinneringen aan (een) aangrijpende gebeurtenis(sen) van u af te zetten?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 49,
            'info': '',
            'description': 'Moet u de afgelopen week bepaalde plaatsen vermijden omdat u er angstig van wordt?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
        {
            'number': 50,
            'info': '',
            'description': 'Moet u de afgelopen week sommige handelingen een aantal keren herhalen voordat u iets anders kunt gaan doen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },


    ],
    'scores': [
        {
            'description': 'Distress',
            'formule': 'S17+S19+S20+S22+S25+S26+S29+S31+S32+S36+S37+S38+S39+S41+S47+S48',
            'min': 0,
            'max': 32,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Distress',
            'goal': 'Verminderen van emotionele stress',
            'operation': '...',
            'goalscore': 0,
        },
        {
            'description': 'Depressie',
            'formule': 'S28+S30+S33+S34+S35+S46',
            'min': 0,
            'max': 12,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Depressie',
            'goal': 'Verminderen van depressieve gevoelens',
            'operation': '...',
            'goalscore': 0,
        },

        {
            'description': 'Angst',
            'formule': 'S18+S21+S23+S24+S27+S40+S42+S43+S44+S45+S49+S50',
            'min': 0,
            'max': 24,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Angst',
            'goal': 'Verminderen van angstige gevoelens',
            'operation': '...',
            'goalscore': 0,
        },

        {
            'description': 'Somatisatie',
            'formule': 'S1+S2+S3+S4+S5+S6+S7+S8+S9+S10+S11+S12+S13+S14+S15+S16',
            'min': 0,
            'max': 32,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Somatisatie',
            'goal': 'Verminderen van lichamelijk onverklaarde klachten',
            'operation': '...',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_4dkl_v1