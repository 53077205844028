<template>
  <div v-if="customerOverviewModal.state.active" class="modal show" id="clientModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header gradient">
          <div class="row text-center">
            <h5 class="modal-title text-center" id="clientModalLabel">
              Login
            </h5>
          </div>
          <button type="button" class="btn-close" @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div v-show="showWarning" class="alert alert-danger" role="alert">
              {{ warning }}
            </div>

            <div class="form-group text-secondary">
              <div class="row">
                <div class="col">
                  <div class="row mb-3">
                    <label for="inputLogin" class="col-sm-3 mt-2 form-label text-end">Login</label>
                    <div class="col">
                      <input type="text" class="form-control" id="inputLogin" v-model="form.login">
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputPassword" class="col-sm-3 mt-2 form-label text-end">Wachtwoord</label>
                    <div class="col">
                      <input type="password" class="form-control" id="inputPassword" v-model="form.password"/>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row mb-3">
                <div class="col">
                  <button @click="login()" class="btn btn-success opacity-75 align-middle me-2">
                    Login
                  </button>
                  <button type="button" class="btn btn-secondary opacity-75" @click="closeModal">Annuleren</button>
                </div>
              </div>
            </div>

            <!--ul class="list-group mb-3">
              <li class="list-group-item" style="background-color: rgba(25, 159, 214, 0.10);">
                <img src="@/assets/blue/male-24.png" class="img-fluid">
                Klanten
              </li>
              <li class="list-group-item" @click="setCustomer('customers/test_customer/')">
                Test
              </li>
              <li class="list-group-item" @click="setCustomer('customers/ronald/')">
                Ronald
              </li>
              <li class="list-group-item text-secondary" v-for="(customer, index) in customers" :key="index">
                <div class="row">
                </div>
              </li>
            </ul-->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="customerOverviewModal.state.active" class="dialog-bg"></div>
</template>

<script>
import CustomerDataService from "@/services/CustomerDataService";
import customerOverviewModal from "@/services/modals/CustomerOverviewModalService";

export default {
  name: "customer_overview_modal",
  data() {
    return {
      form: {
        login: '',
        password: '',
      },
      showWarning: false,
      warning: '',
      customers: [],
      customerOverviewModal,
    };
  },
  methods: {
    closeModal() {
      customerOverviewModal.cancel()
    },
    async login() {
      const loginState = (await CustomerDataService.CheckPassword(this.form.login, this.form.password))
      console.log(loginState)

      if (loginState === 'valid') {
        this.showWarning = false
        this.setCustomer('customers/' + this.form.login + '/')
        this.closeModal()
      } else {
        this.warning = loginState
        this.showWarning = true
      }
    },
    setCustomer(customer) {
      this.$store.state.customer = customer
      this.closeModal()
    }
  },
  watch: {

  },
  computed: {

  },
  mounted() {

  },
}
</script>

<style scoped>
body {
  background-color: rgba(240, 244, 250, 1);
}
</style>