<template>
  <div v-if="thirdPartyModal.state.active" class="modal show" id="todoModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header gradient">
          <div class="row text-secondary text-center">
            <h5 class="modal-title text-center" id="todoModalLabel">
              <img src="@/assets/blue/employees-24.png" alt="ThirdParty" class="img-fluid">
              Derden - <b>{{ form.first_name + ' ' + form.last_name }}</b>
            </h5>
          </div>
          <button type="button" class="btn-close" @click="hideModal()"></button>
        </div>

        <div class="modal-body text-secondary" :style="isMobile ? 'min-height: 480px; max-height: 480px;' : 'min-height: 580px; max-height: 580px;'">
          <!-- Menu -->
          <div class="row mt-2 m-0">
            <div class="col-2 pb-1 pt-1" @click="showAlgemeen = true" :class="showAlgemeen ? 'selected-tab'  : 'tab'  ">
              <img src="@/assets/blue/employees-24.png" alt="general" class="img-fluid" >
              Algemeen
            </div>
            <div class="col-2 pb-1 pt-1" @click="showAlgemeen = false" :class="!showAlgemeen ? 'selected-tab' : 'tab'">
              <img src="@/assets/blue/additional-24.png" alt="additional" class="img-fluid" >
              Additioneel
            </div>
            <div class="col" style="border-bottom: 3px solid lightgray;"></div>
          </div>
          <div class="row m-0 pt-3 pb-3 ps-2 pe-2 tab-content">
            <div class="col">

              <!-- Form -->
              <div class="form-group">
                <!-- Tab algemeen -->
                <div v-show="showAlgemeen" class="container">
                  <div class="row mb-2 pt-3">
                    <label class="col-2 col-form-label text-end text-truncate">Soort</label>
                    <div class="col-4">
                      <select class="form-select text-secondary" v-model="form.caregiver_type">
                        <option :value="'1'">Huisarts</option>
                        <option :value="'2'">Apotheker</option>
                        <option :value="'3'">Medisch specialist</option>
                        <option :value="'4'">Fysiotherapeut</option>
                        <option :value="'5'">Logopedist</option>
                        <option :value="'6'">Ziekenhuis</option>
                        <option :value="'7'">Oefentherapeut</option>
                        <option :value="'8'">Verloskundige</option>
                        <option :value="'94'">Psychotherapeut</option>
                        <option :value="'99'">Anders</option>
                      </select>
                    </div>
                    <!--label class="col-2 col-form-label text-end text-truncate">Soort</label>
                    <div class="col-4 text-start">
                      <Dropdown
                          class="text-secondary fr-width-100"
                          style="z-index: 9999;"
                          v-model="form.caregiver_type"
                          :options="caregiverTypes"
                          :optionLabel="caregiverTypes.description"
                          :optionValue="caregiverTypes.value"
                          placeholder="Selecteer"
                      />
                    </div-->
                  </div>
                  <div class="row mb-2 mt-4">
                    <label for="inputLastName" class="col-2 col-form-label text-end">Achternaam</label>
                    <div class="col-4">
                      <input type="text" class="form-control text-secondary required" id="inputLastName" v-model="form.last_name"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label for="inputFirstName" class="col-2 col-form-label text-end">Voornaam</label>
                    <div class="col-4">
                      <input type="text" class="form-control text-secondary required" id="inputFirstName" v-model="form.first_name"/>
                    </div>
                    <label for="inputInitials" class="col-2 col-form-label text-end">Voorletters</label>
                    <div class="col-4">
                      <input type="text" class="form-control text-secondary required" id="inputInitials" v-model="form.initials"/>
                    </div>
                  </div>
                  <div class="row mt-4 mb-2">
                    <label for="inputSalutation" class="col-2 col-form-label text-end">Aanhef</label>
                    <div class="col-10">
                      <input type="text" class="form-control text-secondary" id="inputSalutation" v-model="form.salutation"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label for="inputAddressing" class="col-2 col-form-label text-end">Adressering</label>
                    <div class="col-10">
                      <input type="text" class="form-control text-secondary" id="inputAddressing" v-model="form.addressing"/>
                    </div>
                  </div>
                </div>

                <!-- Tab additioneel -->
                <div v-show="!showAlgemeen" class="container">
                  <div class="row mt-3 mb-2">
                    <label for="inputStreet" class="col-2 col-form-label text-end">Straat</label>
                    <div class="col-4">
                      <input type="text" class="form-control text-secondary" id="inputStreet" v-model="form.street"/>
                    </div>
                    <label for="inputHouseNumber" class="col-2 col-form-label text-end">Huisnummer</label>
                    <div class="col-2">
                      <input type="text" class="form-control text-secondary" id="inputHouseNumber" v-model="form.house_number"/>
                    </div>
                    <div class="col-2">
                      <input type="text" class="form-control text-secondary" id="inputHouseNumberSuffix" v-model="form.house_number_suffix"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label for="inputPostalCode" class="col-2 col-form-label text-end">Postcode</label>
                    <div class="col-4">
                      <input type="text" class="form-control text-secondary" id="inputPostalCode" v-model="form.postal_code"/>
                    </div>
                    <label for="inputCity" class="col-2 col-form-label text-end">Plaats</label>
                    <div class="col-4">
                      <input type="text" class="form-control text-secondary" id="inputCity" v-model="form.city"/>
                    </div>
                  </div>
                  <div class="row mb-2 mt-4">
                    <label for="inputEmail" class="col-2 col-form-label text-end">Email</label>
                    <div class="col-4">
                      <input type="text" class="form-control text-secondary" id="inputEmail" v-model="form.email"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="modal-footer bg-light pt-2 pb-2">
          <button @click="saveThirdParty()" class="btn btn-success opacity-75 align-middle m-2">
            Opslaan
          </button>
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">Sluiten</button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="thirdPartyModal.state.active" class="dialog-bg"></div>
</template>

<script>
import thirdPartyModal from "@/services/modals/ThirdPartyModalService";
import ThirdPartyDataService from "@/services/ThirdPartyDataService";
import store from "@/store";
//import Dropdown from 'primevue/dropdown';

export default {
  name: "ThirdPartyModal",
  //components: { Dropdown, },
  data() {
    return {
      form: {
        caregiver_type: '',
        last_name: '',
        first_name: '',
        initials: '',
        salutation: '',
        addressing: '',
        street: '',
        house_number: '',
        house_number_suffix: '',
        postal_code: '',
        city: '',
        email: '',
      },
      caregiverTypes: [
        { description: 'Huisarts', value: '1' },
        { description: 'Apotheker', value: '2' },
        { description: 'Medisch specialist', value: '3' },
        { description: 'Fysiotherapeut', value: '4' },
        { description: 'Logopedist', value: '5' },
        { description: 'Ziekenhuis', value: '6' },
        { description: 'Oefentherapeut', value: '7' },
        { description: 'Verloskundige', value: '8' },
        { description: 'Anders', value: '99' },
      ],
      showAlgemeen: true,
      showAddRate: false,
      thirdPartyModal,
    }
  },
  computed: {
    thirdPartyId() {
      return thirdPartyModal.state.id
    },
    thirdParty() {
      return thirdPartyModal.state.thirdParty
    },
    isMobile() {
      return this.$store.state.mobileDevice
    },
  },
  watch: {
    thirdParty: function(thirdParty) {
      this.form = { ...thirdParty }
    },
    thirdPartyId() {
      this.showAlgemeen = true
    }
  },
  methods: {
    hideModal() {
      thirdPartyModal.cancel()
    },
    async saveThirdParty() {
      const dataObj = {
        caregiver_type: this.form.caregiver_type || 99,
        last_name: this.form.last_name || '',
        first_name: this.form.first_name || '',
        initials: this.form.initials || '',
        salutation: this.form.salutation || '',
        addressing: this.form.addressing || '',
        street: this.form.street || '',
        house_number: this.form.house_number || '',
        house_number_suffix: this.form.house_number_suffix || '',
        postal_code: this.form.postal_code || '',
        city: this.form.city || '',
        email: this.form.email || '',
      }

      if (thirdPartyModal.state.id === '') {
        const thirdPartyId = await ThirdPartyDataService.AddThirdParty(dataObj)
        thirdPartyModal.state.id = thirdPartyId
      } else {
        await ThirdPartyDataService.UpdateThirdParty(thirdPartyModal.state.id, dataObj)
      }

      await store.dispatch('ClientsStore/getThirdParties')
      thirdPartyModal.cancel()
    },
  },
}
</script>

<style scoped>

</style>