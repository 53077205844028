// ./services/OnlineRequestsService

import DossierMessagesDataService from "@/services/DossierMessagesDataService";
import GeneralOnlineRequestsDataService from "@/services/GeneralOnlineRequestsDataService";
import ClinimetricsDataService from "@/services/ClinimetricsDataService";
import OnlineRequestsDataService from "@/services/OnlineRequestsDataService";
import permission_hapto_v1 from "@/clinimetrics/permission_hapto_v1";
import DossierDataService from "@/services/DossierDataService";

class OnlineRequestsService {
    async RemoveClinimetricsFromOnlineRequest(onlineRequestId, clinimetricsId, tMoment) {

        let onlineRequest = await OnlineRequestsDataService.GetOnlineRequest(onlineRequestId)

        let clinimetricsArray = []
        if (onlineRequest.clinimetrics) {
            onlineRequest.clinimetrics.forEach((item) => {
                if (!(item.id === clinimetricsId && item.t_moment === tMoment)) {
                    clinimetricsArray.push(item)
                }
            })
        }

        let permissionsArray = []
        if (onlineRequest.permissions) {
            onlineRequest.permissions.forEach((item) => {
                permissionsArray.push(item)
            })
        }

        if (clinimetricsArray.length === 0 && permissionsArray.length === 0) {
            await OnlineRequestsDataService.RemoveOnlineRequest(onlineRequestId)
            await GeneralOnlineRequestsDataService.RemoveGeneralOnlineRequest(onlineRequestId)
        } else {
            const updateObj = {
                clinimetrics: clinimetricsArray,
            }
            await OnlineRequestsDataService.UpdateOnlineRequest(updateObj, onlineRequestId)
            await GeneralOnlineRequestsDataService.UpdateGeneralOnlineRequest(updateObj, onlineRequestId)
        }
    }
    async RemovePermissionsFromOnlineRequest(onlineRequestId, permissionsId) {

        let onlineRequest = await OnlineRequestsDataService.GetOnlineRequest(onlineRequestId)

        let clinimetricsArray = []
        if (onlineRequest.clinimetrics) {
            onlineRequest.clinimetrics.forEach((item) => {
                clinimetricsArray.push(item)
            })
        }

        let permissionsArray = []
        if (onlineRequest.permissions) {
            onlineRequest.permissions.forEach((item) => {
                if (!(item.id === permissionsId)) {
                    permissionsArray.push(item)
                }
            })
        }

        if (clinimetricsArray.length === 0 && permissionsArray.length === 0) {
            await OnlineRequestsDataService.RemoveOnlineRequest(onlineRequestId)
            await GeneralOnlineRequestsDataService.RemoveGeneralOnlineRequest(onlineRequestId)
        } else {
            const updateObj = {
                permissions: permissionsArray,
            }
            await OnlineRequestsDataService.UpdateOnlineRequest(updateObj, onlineRequestId)
            await GeneralOnlineRequestsDataService.UpdateGeneralOnlineRequest(updateObj, onlineRequestId)
        }
    }
    async processClinimetricsRequest(clinimetricsItem, onlineRequestId) {

        const dossierMessage = await DossierMessagesDataService.GetDossierMessage(clinimetricsItem.dossier_message_id)

        // Add clinimetrics (data) record
        let clinimetricsObject = {
            'dossier_id': dossierMessage.dossier_id,
            'client_id': dossierMessage.client_id,
            'clinimetric_id': dossierMessage.clinimetrics_id,
            'date': new Date(),
            'description': dossierMessage.clinimetrics_description,
            'short': '',
            'scores': clinimetricsItem.scores,
            'answers': clinimetricsItem.answers,
            't_moment': clinimetricsItem.t_moment,
        }
        clinimetricsObject.clinimetric_data_id = await ClinimetricsDataService.AddClinimetrics(clinimetricsObject)

        // Edit dossier message
        await DossierMessagesDataService.UpdateClinimetricMoment(dossierMessage.dossier_id, clinimetricsObject, 'done')

        // Update dossier message, hoeft waarschijnlijk niet. Wordt nu hierboven gedaan
        const dossierMessageObj = {
            status: 'done',
        }
        await DossierMessagesDataService.UpdateDossierMessage(clinimetricsItem.dossier_message_id, dossierMessageObj)

        // Remove item from online request
        await this.RemoveClinimetricsFromOnlineRequest(onlineRequestId, dossierMessage.clinimetrics_id, clinimetricsItem.t_moment)
    }
    async processPermissionsRequest(permissionsItem, onlineRequestId) {
        //const dossierMessage = await DossierMessagesDataService.GetDossierMessage(permissionsItem.dossier_message_id)
        const onlineRequest = await OnlineRequestsDataService.GetOnlineRequest(onlineRequestId)
        const permission = permission_hapto_v1

        // Add clinimetrics (data) record
        let permissionsObject = {
            signatureDate: new Date(),
            signaturePlace: 'online',
            permissionRequested: true,
        }

        permissionsItem.answers.forEach((answer, index) => {
            if (permissionsObject[permission.questions[index].name] === 'signatureDate') {
                permissionsObject[permission.questions[index].name] = new Date(permissionsItem.answers[index].answer)
            } else {
                permissionsObject[permission.questions[index].name] = permissionsItem.answers[index].answer
            }
            permissionsObject[permission.questions[index].name] = permissionsItem.answers[index].answer
        })

        await DossierDataService.AddPermissions(onlineRequest.dossier_id, permissionsObject)

        if (permissionsItem.dossier_message_id !== '') {
            // Update dossier message
            const dossierMessageObj = {
                status: 'done',
            }
            await DossierMessagesDataService.UpdateDossierMessage(permissionsItem.dossier_message_id, dossierMessageObj)
        }

        // Remove item from online request
        await this.RemovePermissionsFromOnlineRequest(onlineRequestId, permissionsItem.id)
    }
}

export default new OnlineRequestsService()