const questionnaire_csi_v1 = {
    'id': 'CSI_v1',
    'description': 'Central Sensitization Inventory (CSI)',
    'short': 'CSI',
    'questions': [
        {
            'number': 1,
            'info': 'Geef aan in welke mate u de volgende klachten heeft.',
            'description': 'Ik voel me niet uitgeslapen ’s morgens als ik wakker word',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'Mijn spieren voelen stijf en pijnlijk',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Ik heb angstaanvallen',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Ik knars of klem met mijn tanden',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'Ik heb last van diarree en/of constipatie',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': 'Ik heb hulp nodig bij het uitvoeren van dagelijkse activiteiten',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': 'Ik ben gevoelig voor fel licht',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 8,
            'info': '',
            'description': 'Ik ben snel moe bij fysieke activiteiten',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 9,
            'info': '',
            'description': 'Ik heb pijn over mijn gehele lichaam',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 10,
            'info': '',
            'description': 'Ik heb last van hoofdpijn',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 11,
            'info': '',
            'description': 'Ik heb een ongemakkelijk gevoel in mijn blaas en/of een branderig gevoel bij het plassen',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 12,
            'info': '',
            'description': 'Ik slaap niet goed',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 13,
            'info': '',
            'description': 'Ik kan me moeilijk concentreren',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 14,
            'info': '',
            'description': 'Ik heb huidproblemen zoals droge huid, jeuk of huiduitslag',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 15,
            'info': '',
            'description': 'Stress verergert mijn lichamelijke klachten',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 16,
            'info': '',
            'description': 'Ik voel me neerslachtig of depressief',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 17,
            'info': '',
            'description': 'Ik heb weinig energie',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 18,
            'info': '',
            'description': 'Ik heb spierspanning in mijn nek en schouders',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 19,
            'info': '',
            'description': 'Ik heb pijn in mijn kaak',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 20,
            'info': '',
            'description': 'Bepaalde geuren, zoals parfums, maken me duizelig en misselijk',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 21,
            'info': '',
            'description': 'Ik moet vaak plassen',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 22,
            'info': '',
            'description': 'Mijn benen voelen ongemakkelijk en rusteloos wanneer ik ’s avonds wil gaan slapen',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 23,
            'info': '',
            'description': 'Ik heb moeite om dingen te onthouden',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 24,
            'info': '',
            'description': 'Als kind heb ik traumatische gebeurtenissen meegemaakt',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
        {
            'number': 25,
            'info': '',
            'description': 'Ik heb pijn in mijn bekkenregio',
            'question_type': 'multiple_choice_horizontal',
            'items': [
                {
                    'value': 0,
                    'description': 'Nooit',
                },
                {
                    'value': 1,
                    'description': 'Zelden',
                },
                {
                    'value': 2,
                    'description': 'Soms',
                },
                {
                    'value': 3,
                    'description': 'Vaak',
                },
                {
                    'value': 4,
                    'description': 'Altijd',
                },
            ],
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Totaalscore',
            'formule': 'S1+S2+S3+S4+S5+S6+S7+S8+S9+S10+S11+S12+S13+S14+S15+S16+S17+S18+S19+S20+S21+S22+S23+S24+S25',
            'min': 0,
            'max': 100,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Somatisatie',
            'goal': 'Verminderen van somatische en emotionele symptomen',
            'operation': '...',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_csi_v1