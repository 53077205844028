<template>
  <div class="card shadow-sm card-item mb-3">
    <div class="card-body text-secondary" style="min-height: 110px; max-height: 110px; background-color: rgba(25, 159, 214,0.04);">
      <!--h6 class="card-subtitle mb-2 text-start text-muted">Cliënt</h6-->
      <div class="card-bg"/>
      <div class="opacity-25 position-absolute">
        <img v-if="client.gender === 'male'" src="@/assets/blue/male-24.png" alt="" class="img-fluid"/>
        <img v-else-if="client.gender === 'female'" src="@/assets/blue/female-24.png" alt="" class="img-fluid"/>
        <img v-else src="@/assets/blue/nonbinair-24.png" alt="" class="img-fluid"/>
      </div>
      <div class="row">
        <div class="col fs-5 fw-bold">
          {{ client ? client.first_name : '' }} {{ client ? client.last_name : '' }}
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          {{ showClientBirthdate() }} {{ clientAge }}
        </div>
      </div>
    </div>
  </div>
  <!--div class="alert alert-warning shadow-sm mb-3" role="alert" style="background-color: rgba(49, 163, 0, 0.1); border-color: rgba(49, 163, 0, 0.1);">

  </div-->
</template>

<script>
import DossierDataService from "@/services/DossierDataService";

export default {
  name: "ClientHeader",
  props: ['client', 'dossier'],
  data() {
    return {

    }
  },
  methods: {
    showClientBirthdate() {
      if (this.client.birthdate === '') {
        return ''
      } else {
        if (this.client.birthdate != null) {
          return new Date(this.client.birthdate).toLocaleDateString()
        } else {
          return ''
        }
      }
    },
    getComplaintDescription(id) {
      return DossierDataService.GetComplaintDescription(this.dossier.dossier_type, id)
    },
  },
  watch: {

  },
  computed: {
    clientAge() {
      try {
        if (this.client.birthdate === '') {
          return ''
        }

        const splittedDate = this.client.birthdate.split('-');
        const date = [splittedDate[0], splittedDate[1], splittedDate[2]].join('/');

        const dateOfBirth = new Date(date)

        //calculate month difference from current date in time
        const month_diff = Date.now() - dateOfBirth.getTime();

        //convert the calculated difference in date format
        const age_dt = new Date(month_diff);

        //extract year from date
        const year = age_dt.getUTCFullYear();

        //now calculate the age of the user
        return '(' + Math.abs(year - 1970) + ' jaar)';
      } catch {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.card-item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
  overflow: hidden;
}
.card-bg {
  height: 128px;
  width: 128px;
  background-color: rgba(25, 159, 214, 0.2);

  z-index: 1;
  position: absolute;
  top: -100px;
  right: -55px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
</style>