// ./services/TemplateDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import ThirdPartyDataService from "@/services/ThirdPartyDataService";
import DossierDataService from "@/services/DossierDataService";
import EmployeeDataService from "@/services/EmployeeDataService";
import TargetDataService from "@/services/TargetDataService";
import InstituteDataService from "@/services/InstituteDataService";
import LocationsDataService from "@/services/LocationsDataService";
import ClientDataService from "@/services/ClientDataService";
import ExercisesDataService from "@/services/ExercisesDataService";

class TemplateDataService {
    async AddTemplate(dataObj) {
        const colRef = collection(db, store.state.customer + 'templates');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateTemplate(templateId, dataObj) {
        const docRef = doc(db, store.state.customer + 'templates', templateId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveTemplate(templateId) {
        const docRef = doc(db, store.state.customer + 'templates', templateId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetTemplate(templateId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'templates', templateId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetTemplates(templateType) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const templateRef = collection(db, store.state.customer + 'templates', )
                const templateQuery = query(templateRef, where('template_type', '==', templateType), orderBy('description'))
                const snapShot = await getDocs(templateQuery);

                let templates = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    templates.push(data)
                })

                resolve(templates)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async ConvertGwbTemplate(text, dossierId, receiver, thirdPartyId, form) {

        let convertedText = String(await this.ConvertTemplate(text, dossierId, receiver, thirdPartyId))

        convertedText = convertedText.replaceAll('{{ GWB gevonden }}', form.textGwbGevonden)
        convertedText = convertedText.replaceAll('{{ GWB richtpunt }}', form.textGwbRichtpuntBehandeling)
        convertedText = convertedText.replaceAll('{{ GWB verloop }}', form.textGwbVerloop)
        convertedText = convertedText.replaceAll('{{ GWB overwegingen }}', form.textGwbOverwegingen)
        convertedText = convertedText.replaceAll('{{ GWB bijstellingen }}', form.textGwbBijstellingen)
        convertedText = convertedText.replaceAll('{{ GWB stop behandeling }}', form.textGwbStopBehandeling)
        convertedText = convertedText.replaceAll('{{ GWB advisering }}', form.textGwbAdvisering)
        convertedText = convertedText.replaceAll('{{ GWB verslag }}', form.textGwbVerslag)
        convertedText = convertedText.replaceAll('{{ GWB leerdoel }}', form.textGwbLeerdoel)

        const sessions = store.state.sessions.reverse()
        convertedText = convertedText.replaceAll('{{ aantal sessies }}', sessions.length.toString())
        if (sessions.length > 0) {
            convertedText = convertedText.replaceAll('{{ datum eerste sessie }}', new Date(sessions[0].startDate.seconds * 1000).toLocaleDateString() || 'onbekend')
        } else {
            convertedText = convertedText.replaceAll('{{ datum eerste sessie }}', 'Geen datum')
        }

        // goals
        try {
            let targetsText = ''
            const targets = await TargetDataService.GetTargetsFromDossier(dossierId)
            targets.forEach((target) => {
                targetsText += '- ' + target.goal + ' - ' + target.score + '/' + target.target_score + '\n'
            })
            convertedText = convertedText.replaceAll('{{ doelen }}', targetsText)
        } catch(e) {
            console.log(e)
        }

        // third party
        if (thirdPartyId !== '' && receiver !== 'client') {
            const thirdParty = await ThirdPartyDataService.GetThirdParty(thirdPartyId)
            convertedText = convertedText.replaceAll('{{ aanhef }}', thirdParty.salutation)
        }


        return convertedText
    }
    async ConvertTemplate(text, dossierId, receiver, thirdPartyId) {

        let convertedText = String(text)

        // client data
        try {
            convertedText = convertedText.replaceAll('{{ client }}', store.state.patient.first_name + ' ' + store.state.patient.last_name)
            convertedText = convertedText.replaceAll('{{ client voornaam }}', store.state.patient.first_name)
            convertedText = convertedText.replaceAll('{{ client achternaam }}', store.state.patient.last_name)
            convertedText = convertedText.replaceAll('{{ adres }}', store.state.patient.street + ' ' + store.state.patient.house_number + store.state.patient.house_number_suffix)
            convertedText = convertedText.replaceAll('{{ postcode }}', store.state.patient.postal_code)
            convertedText = convertedText.replaceAll('{{ woonplaats }}', store.state.patient.city)
            convertedText = convertedText.replaceAll('{{ geboortedatum }}', new Date(store.state.patient.birthdate).toLocaleDateString())
            convertedText = convertedText.replaceAll('{{ verzekerdenummer }}', store.state.patient.insurer_number)
            if (receiver === 'client') {
                convertedText = convertedText.replaceAll('{{ aanhef }}', store.state.patient.salutation || '')
            }
        } catch(e) {
            console.log(e)
        }

        // dossier data
        let dossier = []
        try {
            dossier = await DossierDataService.GetDossier(dossierId)
            convertedText = convertedText.replaceAll('{{ hulpvraag }}', dossier.textHulpvraag || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ relatie }}', dossier.textRelatie || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ gezin }}', dossier.textGezin || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ opleiding }}', dossier.textOpleiding || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ vrije tijd }}', dossier.textVrijeTijd || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ herkomst }}', dossier.textHerkomst || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ gebeurtenissen }}', dossier.textGebeurtenissen || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ anders }}', dossier.textAndersBelangrijk || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ hulpverlening }}', dossier.textEerdereHulpverlening || 'niet gevonden')

            convertedText = convertedText.replaceAll('{{ haptomimiek }}', dossier.textHaptomimiek || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ waarneming }}', dossier.textEigenWaarneming || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ vermogens }}', dossier.textBeschikbareHaptonomischeVermogens || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ hechtingsstijl }}', dossier.textHechtingsstijl || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ drieluik }}', dossier.textDrieluik || 'niet gevonden')

            convertedText = convertedText.replaceAll('{{ haptonomische hulpvraag }}', dossier.textHaptonomischeHulpvraag || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ afspraken }}', dossier.textAfspraken || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ afsluiting }}', dossier.textAfsluiting || 'niet gevonden')

            convertedText = convertedText.replaceAll('{{ ft hulpvraag }}', dossier.hulpvraag || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft ontstaan }}', dossier.ontstaan || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft omgevingsdeterminanten }}', dossier.omgevings_determinanten || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft persoonlijke determinanten }}', dossier.persoonlijke_determinanten || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft aangedane zijde }}', dossier.aangedane_zijde || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft diagnostische verrichtingen }}', dossier.diagnostische_verrichtingen || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft fysio werkdiagnose }}', dossier.werkdiagnose || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft hoofddoel }}', dossier.hoofddoel || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft diagnosecode }}', dossier.diagnosecode || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft afsluiting }}', dossier.afsluiting || 'niet gevonden')
            convertedText = convertedText.replaceAll('{{ ft afspraken }}', dossier.afspraken || 'niet gevonden')

        } catch(e) {
            console.log(e)
        }

        // employee data
        if (dossier.employee_id !== '') {
            const employee = await EmployeeDataService.GetEmployee(dossier.employee_id)
            convertedText = convertedText.replaceAll('{{ medewerker }}', employee.first_name + ' ' + employee.last_name)
            convertedText = convertedText.replaceAll('{{ medewerker agb }}', employee.agb)
            convertedText = convertedText.replaceAll('{{ medewerker big }}', employee.big)
            convertedText = convertedText.replaceAll('{{ medewerker specialisme }}', employee.speciality)
        }

        // goals
        try {
            let targetsText = ''
            const targets = await TargetDataService.GetTargetsFromDossier(dossierId)
            targets.forEach((target) => {
                targetsText += '- ' + target.goal + ' - ' + target.score + '/' + target.target_score + '\n'
            })
            convertedText = convertedText.replaceAll('{{ doelen }}', targetsText)
        } catch(e) {
            console.log(e)
        }

        // third party
        if (thirdPartyId !== '' && receiver !== 'client') {
            const thirdParty = await ThirdPartyDataService.GetThirdParty(thirdPartyId)
            convertedText = convertedText.replaceAll('{{ aanhef }}', thirdParty.salutation)
        }

        // institute
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        convertedText = convertedText.replaceAll('{{ praktijk }}', institute.name)
        convertedText = convertedText.replaceAll('{{ praktijk plaats }}', institute.city)
        convertedText = convertedText.replaceAll('{{ praktijk agb }}', institute.agb)
        convertedText = convertedText.replaceAll('{{ praktijk kvk }}', institute.kvk)

        // permissions
        const dossierPermissions = await DossierDataService.GetPermissions(dossierId)
        if (dossierPermissions !== undefined) {
            const dossierPermissionsHtml = this.getDossierPermissionsHtml(dossierPermissions)
            convertedText = convertedText.replaceAll('{{ toestemmingen }}', dossierPermissionsHtml)
        }


        // other
        convertedText = convertedText.replaceAll('{{ datum }}', new Date().toLocaleDateString())

        return convertedText
    }
    async ConvertInvoiceTemplate(text, invoice, invoiceSpecification) {
        let convertedText = String(text)

        // client data
        const client = await ClientDataService.GetClient(invoice.client_id)
        convertedText = convertedText.replaceAll('{{ client }}', client.first_name + ' ' + client.last_name)
        convertedText = convertedText.replaceAll('{{ client voornaam }}', client.first_name)
        convertedText = convertedText.replaceAll('{{ client achternaam }}', client.last_name)
        convertedText = convertedText.replaceAll('{{ geboortedatum }}', new Date(client.birthdate).toLocaleDateString())
        convertedText = convertedText.replaceAll('{{ verzekerdenummer }}', client.insurer_number)

        //invoice data
        convertedText = convertedText.replaceAll('{{ factuurnummer }}', function() {
            const leadingNumber = store.state.settings.financialSettings.leading_number.padEnd(8, '0')
            return String(Number(leadingNumber) + invoice.invoice_number)
        })
        convertedText = convertedText.replaceAll('{{ factuurdatum }}', new Date(invoice.invoice_date).toLocaleDateString())
        convertedText = convertedText.replaceAll('{{ factuuromschrijving }}', invoice.description)

        // employee data
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)
        convertedText = convertedText.replaceAll('{{ medewerker }}', employee.first_name + ' ' + employee.last_name)
        convertedText = convertedText.replaceAll('{{ medewerker agb }}', employee.agb)
        convertedText = convertedText.replaceAll('{{ medewerker big }}', employee.big)
        convertedText = convertedText.replaceAll('{{ medewerker specialisme }}', employee.speciality)

        // institute
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        convertedText = convertedText.replaceAll('{{ praktijk }}', institute.name)
        convertedText = convertedText.replaceAll('{{ praktijk plaats }}', institute.city)
        convertedText = convertedText.replaceAll('{{ praktijk agb }}', institute.agb)
        convertedText = convertedText.replaceAll('{{ praktijk kvk }}', institute.kvk)

        // invoice specification
        if (invoice.total_amount_vat === 0) {
            convertedText = convertedText.replaceAll('{{ factuurspecificatie }}', this.getInvoiceSpecificationWithoutVatHtml(invoice, invoiceSpecification))
        } else {
            convertedText = convertedText.replaceAll('{{ factuurspecificatie }}', this.getInvoiceSpecificationHtml(invoice, invoiceSpecification))
        }


        return convertedText
    }
    async ConvertInvoiceEmailTemplate(text, invoice) {
        let convertedText = String(text)

        const client = await ClientDataService.GetClient(invoice.client_id)
        convertedText = convertedText.replaceAll('{{ client }}', invoice.client_name)
        convertedText = convertedText.replaceAll('{{ client voornaam }}', client.first_name)
        convertedText = convertedText.replaceAll('{{ client achternaam }}', client.last_name)

        // invoice data
        convertedText = convertedText.replaceAll('{{ factuurnummer }}', String("00000000" + invoice.invoice_number).slice(-8))
        convertedText = convertedText.replaceAll('{{ factuurdatum }}', new Date(invoice.invoice_date).toLocaleDateString())
        convertedText = convertedText.replaceAll('{{ factuuromschrijving }}', invoice.description)

        // employee data
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)
        convertedText = convertedText.replaceAll('{{ medewerker }}', employee.first_name + ' ' + employee.last_name)
        convertedText = convertedText.replaceAll('{{ medewerker agb }}', employee.agb)
        convertedText = convertedText.replaceAll('{{ medewerker big }}', employee.big)
        convertedText = convertedText.replaceAll('{{ medewerker specialisme }}', employee.speciality)

        // institute
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        convertedText = convertedText.replaceAll('{{ praktijk }}', institute.name)
        convertedText = convertedText.replaceAll('{{ praktijk plaats }}', institute.city)
        convertedText = convertedText.replaceAll('{{ praktijk agb }}', institute.agb)
        convertedText = convertedText.replaceAll('{{ praktijk kvk }}', institute.kvk)

        return convertedText
    }
    async ConvertExercisesTemplate(text, clientId, clientExercises) {
        let convertedText = String(text)

        // client data
        const client = await ClientDataService.GetClient(clientId)
        convertedText = convertedText.replaceAll('{{ client }}', client.first_name + ' ' + client.last_name)
        convertedText = convertedText.replaceAll('{{ client voornaam }}', client.first_name)
        convertedText = convertedText.replaceAll('{{ client achternaam }}', client.last_name)
        convertedText = convertedText.replaceAll('{{ geboortedatum }}', new Date(client.birthdate).toLocaleDateString())
        convertedText = convertedText.replaceAll('{{ verzekerdenummer }}', client.insurer_number)

        // employee data
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)
        convertedText = convertedText.replaceAll('{{ medewerker }}', employee.first_name + ' ' + employee.last_name)
        convertedText = convertedText.replaceAll('{{ medewerker agb }}', employee.agb)
        convertedText = convertedText.replaceAll('{{ medewerker big }}', employee.big)
        convertedText = convertedText.replaceAll('{{ medewerker specialisme }}', employee.speciality)

        // oefeningen
        convertedText = convertedText.replaceAll('{{ oefeningen }}', await this.getClientExercisesHtml(clientExercises))

        return convertedText
    }
    async ConvertExercisesEmailTemplate(text, clientId) {
        let convertedText = String(text)

        const client = await ClientDataService.GetClient(clientId)
        convertedText = convertedText.replaceAll('{{ client }}', client.first_name + ' ' + client.last_name)
        convertedText = convertedText.replaceAll('{{ client voornaam }}', client.first_name)
        convertedText = convertedText.replaceAll('{{ client achternaam }}', client.last_name)

        // employee data
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)
        convertedText = convertedText.replaceAll('{{ medewerker }}', employee.first_name + ' ' + employee.last_name)
        convertedText = convertedText.replaceAll('{{ medewerker agb }}', employee.agb)
        convertedText = convertedText.replaceAll('{{ medewerker big }}', employee.big)
        convertedText = convertedText.replaceAll('{{ medewerker specialisme }}', employee.speciality)

        // institute
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        convertedText = convertedText.replaceAll('{{ praktijk }}', institute.name)
        convertedText = convertedText.replaceAll('{{ praktijk agb }}', institute.agb)
        convertedText = convertedText.replaceAll('{{ praktijk kvk }}', institute.kvk)

        return convertedText
    }
    async ConvertAppointmentTemplate(text, sessionObj) {
        let convertedText = String(text)

        if (sessionObj.location_id !== '') {
            const location = await LocationsDataService.GetLocation(sessionObj.location_id)

            convertedText = convertedText.replaceAll('{{ locatie }}', location.description)
            convertedText = convertedText.replaceAll('{{ locatie email }}', location.email)

            convertedText = convertedText.replaceAll('{{ lokatie }}', location.description)
            convertedText = convertedText.replaceAll('{{ lokatie email }}', location.email)
        }


        convertedText = convertedText.replaceAll('{{ client }}', String(sessionObj.patient_name))
        convertedText = convertedText.replaceAll('{{ afspraak_datum }}', new Date(sessionObj.startDate).toLocaleDateString())
        convertedText = convertedText.replaceAll('{{ afspraak_tijd }}', new Date(sessionObj.startDate).toLocaleTimeString().slice(0, 5))
        convertedText = convertedText.replaceAll('{{ afspraak_medewerker }}', String(sessionObj.employee_name))

        return convertedText
    }
    async ConvertRequestTemplate(text, onlineRequestId, client, employee) {
        let convertedText = String(text)

        convertedText = convertedText.replaceAll('{{ client }}', String(client.first_name + ' ' + client.last_name))
        convertedText = convertedText.replaceAll('{{ medewerker }}', String(employee.first_name + ' ' + employee.last_name))
        convertedText = convertedText.replaceAll('{{ verzoek_link }}', '<a href="' + window.location.origin + '/client_clinimetrics_screen?id=' + onlineRequestId + '">' +
            'Klik hier om het verzoek te openen</a>')

        return convertedText
    }
    async ConvertSecureMessageTemplate(text, secureMailId, receiver) {
        let convertedText = String(text)

        // employee data
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)
        convertedText = convertedText.replaceAll('{{ medewerker }}', employee.first_name + ' ' + employee.last_name)
        convertedText = convertedText.replaceAll('{{ medewerker agb }}', employee.agb)
        convertedText = convertedText.replaceAll('{{ medewerker big }}', employee.big)
        convertedText = convertedText.replaceAll('{{ medewerker specialisme }}', employee.speciality)

        // institute
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        convertedText = convertedText.replaceAll('{{ praktijk }}', institute.name)
        convertedText = convertedText.replaceAll('{{ praktijk plaats }}', institute.city)
        convertedText = convertedText.replaceAll('{{ praktijk agb }}', institute.agb)
        convertedText = convertedText.replaceAll('{{ praktijk kvk }}', institute.kvk)

        convertedText = convertedText.replaceAll('{{ ontvanger }}', String(receiver.salutation))
        convertedText = convertedText.replaceAll('{{ verzoek_link }}', '<a href="' + window.location.origin + '/mail?id=' + secureMailId + '">' +
            'Klik hier om het bericht te openen</a>')

        return convertedText
    }
    getInvoiceSpecificationHtml(invoice, invoiceSpecification) {
        let html = '<table style="width: 99%; border: 1px solid #F5F5F5; border-radius: 5px;"><tbody>'

        // first row
        html += '<tr>'
        html += '<td style="width: 1%;"></td>'
        html += '<td style="width: 44%; text-align: left;"><strong>Omschrijving</strong></td>'
        html += '<td style="width: 18%; text-align: right;"><strong>Bedrag excl.</strong></td>'
        html += '<td style="width: 18%; text-align: right;"><strong>Bedrag BTW</strong></td>'
        html += '<td style="width: 18%; text-align: right;"><strong>Bedrag incl.</strong></td>'
        html += '<td style="width: 1%;"></td>'
        html += '</tr>'

        // specification
        invoiceSpecification.forEach((spec) => {
            html += '<tr>'
            html += '<td style="width: 1%;"></td>'
            html += '<td style="width: 44%; text-align: left;">' + spec.description + '</td>'
            html += '<td style="width: 18%; text-align: right;">' + this.showAmount(spec.amount_excl_vat) + '</td>'
            html += '<td style="width: 18%; text-align: right;">' + this.showAmount(spec.amount_vat) + '</td>'
            html += '<td style="width: 18%; text-align: right;">' + this.showAmount(spec.amount_incl_vat) + '</td>'
            html += '<td style="width: 1%;"></td>'
            html += '</tr>'
        })
        html += '<tr style="height: 16px;"><td></td></tr>'

        // total
        html += '<tr style="background-color: #F5F5F5; font-weight: bold;">'
        html += '<td style="width: 1%;"></td>'
        html += '<td style="width: 44%; text-align: left;">Totaal</td>'
        html += '<td style="width: 18%; text-align: right;">' + this.showAmount(invoice.total_amount_excl_vat) + '</td>'
        html += '<td style="width: 18%; text-align: right;">' + this.showAmount(invoice.total_amount_vat) + '</td>'
        html += '<td style="width: 18%; text-align: right;">' + this.showAmount(invoice.total_amount_incl_vat) + '</td>'
        html += '<td style="width: 1%;"></td>'
        html += '</tr>'

        html += '</tbody></table>'

        return html
    }
    getInvoiceSpecificationWithoutVatHtml(invoice, invoiceSpecification) {
        let html = '<table style="width: 99%; border: 1px solid #F5F5F5; border-radius: 5px;"><tbody>'

        // first row
        html += '<tr>'
        html += '<td style="width: 1%;"></td>'
        html += '<td style="width: 68%; text-align: left;"><strong>Omschrijving</strong></td>'
        html += '<td style="width: 30%; text-align: right;"><strong>Bedrag</strong></td>'
        html += '<td style="width: 1%;"></td>'
        html += '</tr>'

        // specification
        invoiceSpecification.forEach((spec) => {
            html += '<tr>'
            html += '<td style="width: 1%;"></td>'
            html += '<td style="width: 68%; text-align: left;">' + spec.description + '</td>'
            html += '<td style="width: 30%; text-align: right;">' + this.showAmount(spec.amount_incl_vat) + '</td>'
            html += '<td style="width: 1%;"></td>'
            html += '</tr>'
        })
        html += '<tr style="height: 16px;"><td></td></tr>'

        // total
        html += '<tr style="background-color: #F5F5F5; font-weight: bold;">'
        html += '<td style="width: 1%;"></td>'
        html += '<td style="width: 68%; text-align: left;">Totaal</td>'
        html += '<td style="width: 30%; text-align: right;">' + this.showAmount(invoice.total_amount_incl_vat) + '</td>'
        html += '<td style="width: 1%;"></td>'
        html += '</tr>'

        html += '</tbody></table>'

        return html
    }
    getDossierPermissionsHtml(dossierPermissions) {
        if (dossierPermissions === undefined) {
            return ''
        }

        let text = ''

        text += '<p><span>' + (dossierPermissions.permissionGetDossierData ? '&#9745;' : '&#9746;') + '</span><span style="width: 50%;"> De cliënt gaat na mondeling overleg akkoord dat eventueel informatie bij de (huis)arts, behandeld ' +
            'specialist of therapeut wordt ingewonnen.</span></p>'

        text += '<p><span>' + (dossierPermissions.permissionSendDossierData ? '&#x2611;' : '&#x2612;') + '</span><span style="width: 50%;"> De cliënt gaat ermee akkoord dat gedurende de behandeling of achteraf verslag wordt gedaan aan de ' +
            '(huis)arts, behandelend specialist of therapeut.</span></p>'
        /*
        text += '<div><div style="80%;">De cliënt is op de hoogte van het tarief voor de behandeling en de betalingsvoorwaarden.</div></div>'
        text += '<div><div style="80%;">De cliënt is geïnformeerd over de behandelwijze en is hiermee akkoord.</div></div>'
        text += '<div><div style="80%;">De cliënt en de therapeut hebben overeenstemming over het doel van de behandeling en de cliënt is hiermee akkoord.</div></div>'
        text += '<div><div style="80%;">De cliënt geeft toestemming voor de behandeling.</div></div>'
        text += '<div><div style="80%;">De cliënt is op de hoogte van de beroepscode waar de therapeut aan is gehouden.</div></div>'
        text += '<div><div style="80%;">De cliënt is op de hoogte van de klachtenprocedure.</div></div>'
        text += '<div><div style="80%;">De therapeut is aanspreekbaar op de behandelwijze en het gevoerde beleid.</div></div>'
        text += '<div><div style="80%;">De toestemmingen zijn uitgevraagd aan de cliënt</div></div>'
*/
        return text
    }
    showAmount(amount) {
        return '€ ' + (amount / 100).toFixed(2).replace('.',',')
    }
    async getClientExercisesHtml(clientExercises) {
        let html = ''

        for (let i = 0; i < clientExercises.length; i++) {
            const exerciseData = await ExercisesDataService.GetExercise(clientExercises[i].exercise_id)

            html += '<table style="width: 99%; border: 1px solid #F5F5F5; border-radius: 5px; font-size: 10px; color: #6C757D;"><tbody>'

            // first row
            html += '<tr style="background-color: #BFD9F1FF; border-radius: 5px;">'
            html += '<td style="width: 1%;"></td>'
            html += '<td style="width: 98%; text-align: center;"><strong>' + clientExercises[i].exercise_description + '</strong></td>'
            html += '<td style="width: 1%;"></td>'
            html += '</tr>'

            // content
            html += '<tr>'
            html += '<td style="width: 1%;"></td>'
            html += '<td style="width: 98%; text-align: center; white-space: break-spaces;">' + exerciseData.content + '</td>'
            html += '<td style="width: 1%;"></td>'
            html += '</tr>'


            /* total
            html += '<tr style="background-color: #F5F5F5; font-weight: bold;">'
            html += '<td style="width: 1%;"></td>'
            html += '<td style="width: 44%; text-align: left;">Totaal</td>'
            html += '<td style="width: 18%; text-align: right;">' + this.showAmount(invoice.total_amount_excl_vat) + '</td>'
            html += '<td style="width: 18%; text-align: right;">' + this.showAmount(invoice.total_amount_vat) + '</td>'
            html += '<td style="width: 18%; text-align: right;">' + this.showAmount(invoice.total_amount_incl_vat) + '</td>'
            html += '<td style="width: 1%;"></td>'
            html += '</tr>' */

            html += '</tbody></table><br>'


        }

        return html
    }
    getMessageHeaderHtml(message) {
        let html = ''

        html += '<table style="width: 99%; border: 1px solid #F5F5F5; border-radius: 5px; font-size: 10px; color: #000000;"><tbody>'

        // first row
        html += '<tr style="border-radius: 5px;">'
        html += '<td style="width: 2%; padding-top: 5px;"></td>'
        html += '<td style="width: 25%; text-align: left; padding-top: 8px;"><strong>Datum</strong></td>'
        html += '<td style="width: 65%; text-align: left; padding-top: 8px;">' + new Date(message.report_date).toLocaleDateString() + '</td>'
        html += '<td style="width: 2%; padding-top: 5px;"></td>'
        html += '</tr>'

        html += '<tr style="border-radius: 5px;">'
        html += '<td style="width: 2%;"></td>'
        html += '<td style="width: 25%; text-align: left;"><strong>Afzender</strong></td>'
        html += '<td style="width: 65%; text-align: left;">' + message.sender_name + '</td>'
        html += '<td style="width: 2%;"></td>'
        html += '</tr>'

        html += '<tr style="border-radius: 5px;">'
        html += '<td style="width: 2%;"></td>'
        html += '<td style="width: 25%; text-align: left; padding-bottom: 8px;"><strong>Onderwerp</strong></td>'
        html += '<td style="width: 65%; text-align: left; padding-bottom: 8px;">' + message.subject + '</td>'
        html += '<td style="width: 2%;"></td>'
        html += '</tr>'

        html += '</tbody></table><br>'

        return html
    }
}

export default new TemplateDataService()