// ./services/ClientDataService

import {
    addDoc,
    collection, deleteDoc,
    doc,
    getDoc, getDocs, orderBy, query, setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import DossierDataService from "@/services/DossierDataService";

class ClientDataService {
    async GetClients() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const clientsRef = collection(db, store.state.customer + 'patients', )
                const clientsQuery = query(clientsRef, orderBy('last_name'))
                const snapShot = await getDocs(clientsQuery);

                let clients = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    clients.push(data)
                })

                resolve(clients)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClient(clientId) {
        function getAge(birthdate) {
            try {
                if (birthdate === '') {
                    return ''
                }

                const splittedDate = birthdate.split('-');
                const date = [splittedDate[0], splittedDate[1], splittedDate[2]].join('/');

                const dateOfBirth = new Date(date)

                //calculate month difference from current date in time
                const month_diff = Date.now() - dateOfBirth.getTime();

                //convert the calculated difference in date format
                const age_dt = new Date(month_diff);

                //extract year from date
                const year = age_dt.getUTCFullYear();

                //now calculate the age of the user
                return '(' + Math.abs(year - 1970) + ' jaar)';
            } catch {
                return ''
            }
        }

        if (clientId === '') {
            return ''
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'patients', clientId))
                let data = docSnap.data()

                if (data !== undefined && data.birthdate !== '') {
                    data.age = getAge(data.birthdate) || ''
                }

                resolve(data)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClientPortalSettingsData(clientId) {
        if (clientId === '') {
            return ''
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'client_portal_settings', clientId))
                let data = docSnap.data()

                if (data === undefined) {
                    data = {
                        status: 'not_active',
                    }
                    const docRef = doc(db, store.state.customer + 'client_portal_settings', clientId)

                    await setDoc(docRef, data, { merge: true })
                        .catch((e) => {
                            console.log(e);
                        });
                }


                resolve(data)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async SaveClientPortalSettingsData(clientId, dataObj) {
        const docRef = doc(db, store.state.customer + 'client_portal_settings', clientId)

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async AddClient(dataObj) {
        const colRef = collection(db, store.state.customer + 'patients')

        let clientId = ''
        await addDoc(colRef, dataObj)
            .then((docRef) => {
                clientId = docRef.id
            })
            .catch(e => {
                console.log(e)
                clientId = ''
            })

        return clientId
    }
    async UpdateClient(clientId, dataObj) {
        const docRef = doc(db, store.state.customer + 'patients', clientId)

        await setDoc(docRef, dataObj, { merge: true })
           .catch((e) => {
                console.log(e);
            });
    }
    async GetDossiersFromClient(clientId) {

        let dossiers = [];
        const querySnap = await getDocs(query(collection(db, store.state.customer + 'dossiers'), where('patient_id', '==', clientId)));
        querySnap.forEach((doc) => {

            let data = doc.data();

            dossiers.push({
                'id': doc.id,
                'dossier_type': data.dossier_type,
                'active': data.active,
                'description': DossierDataService.GetDossierTypeDescription(data.dossier_type) + ' - ' + DossierDataService.GetComplaintDescription(data.complaint_id),
            });
        })

        return dossiers
    }
    async RemoveClient(clientId) {

        // Client klachten
        const clientComplaintQuery = await getDocs(query(collection(db, store.state.customer + 'client_complaints'), where('client_id', '==', clientId)));
        clientComplaintQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        // Client contacten
        const clientContactQuery = await getDocs(query(collection(db, store.state.customer + 'client_contacts'), where('client_id', '==', clientId)));
        clientContactQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        // Client incidenten
        const clientIncidentQuery = await getDocs(query(collection(db, store.state.customer + 'client_incidents'), where('client_id', '==', clientId)));
        clientIncidentQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        // Dossiers verwijderen
        const querySnap = await getDocs(query(collection(db, store.state.customer + 'dossiers'), where('patient_id', '==', clientId)));
        querySnap.forEach((doc) => {
            DossierDataService.RemoveDossier(doc.id)
        })

        // Client verwijderen
        const docRef = doc(db, store.state.customer + 'patients', clientId)
        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });

        // Als de client geselecteerd was dan
        if (store.state.patientId === clientId) {
            await store.dispatch('setPatientId', '')
        }
    }
}

export default new ClientDataService();