// ./services/ThirdPartyDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class ThirdPartyDataService {
    async AddThirdParty(dataObj) {
        const colRef = collection(db, store.state.customer + 'third_parties');

        let thirdPartyId = ''
        await addDoc(colRef, dataObj)
            .then((docRef) => {
                thirdPartyId = docRef.id
            })
            .catch(e => {
                console.log(e);
                thirdPartyId = ''
            })
        return thirdPartyId
    }
    async UpdateThirdParty(thirdPartyId, dataObj) {
        const docRef = doc(db, store.state.customer + 'third_parties', thirdPartyId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('ThirdParty updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveThirdParty(thirdPartyId) {
        console.log('id to be removed: ' + thirdPartyId)
        const docRef = doc(db, store.state.customer + 'third_parties', thirdPartyId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('ThirdParty deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetThirdParty(thirdPartyId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'third_parties', thirdPartyId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetThirdParties() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const thirdPartyRef = collection(db, store.state.customer + 'third_parties', )
                const thirdPartyQuery = query(thirdPartyRef, orderBy('last_name'), orderBy('first_name'))
                const snapShot = await getDocs(thirdPartyQuery);

                let thirdParties = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    thirdParties.push(data)
                })

                resolve(thirdParties)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    GetThirdPartyTypeDescription(type) {
        switch(type) {
            case '1': return 'Huisarts'
            case '2': return 'Apotheker'
            case '3': return 'Medisch specialist'
            case '4': return 'Fysiotherapeut'
            case '5': return 'Logopedist'
            case '6': return 'Ziekenhuis'
            case '7': return 'Oefentherapeut'
            case '8': return 'Verloskundige'
            case '94': return 'Psychotherapeut'
            case '99': return 'Anders'
            default: return ''
        }
    }
}

export default new ThirdPartyDataService();