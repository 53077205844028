const questionnaire_gpe_dv_v1 = {
    'id': 'GPE-DV_v1',
    'description': 'Global Percieved Effect (GPE-DV)',
    'short': 'GPE-DV',
    'questions': [
        {
            'number': 1,
            'info': 'Wij willen u verzoeken de onderstaande 2 vragen te beantwoorden waarmee we uw ' +
                'mening met betrekking tot de verkregen behandeling willen meten.',
            'description': 'In welke mate bent u sinds het begin van de behandeling hersteld van uw klachten?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Heel veel beter',
                },
                {
                    'value': 2,
                    'description': 'Veel beter',
                },
                {
                    'value': 3,
                    'description': 'Iets (enigszins) beter',
                },
                {
                    'value': 4,
                    'description': 'Hetzelfde',
                },
                {
                    'value': 5,
                    'description': 'Iets (enigszins) slechter',
                },
                {
                    'value': 6,
                    'description': 'Veel slechter',
                },
                {
                    'value': 7,
                    'description': 'Heel veel slechter',
                },
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': 'Wij willen u verzoeken de onderstaande 2 vragen te beantwoorden waarmee we uw ' +
                'mening met betrekking tot de verkregen behandeling willen meten.',
            'description': 'Mijn spieren voelen stijf en pijnlijk',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Absoluut tevreden',
                },
                {
                    'value': 2,
                    'description': 'Zeer tevreden',
                },
                {
                    'value': 3,
                    'description': 'Enigszins tevreden',
                },
                {
                    'value': 4,
                    'description': 'Niet tevreden noch ontevreden',
                },
                {
                    'value': 5,
                    'description': 'Enigszins ontevreden',
                },
                {
                    'value': 6,
                    'description': 'Ernstig ontevreden',
                },
                {
                    'value': 7,
                    'description': 'Absoluut ontevreden',
                },
            ],
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Mate van herstel',
            'formule': 'S1',
            'min': 1,
            'max': 7,
            'score_conclusion': 'low',
            'score': '',
            'treatment': '',
            'goal': '',
            'operation': '',
            'goalscore': 0,
        },
        {
            'description': 'Tevredenheid',
            'formule': 'S2',
            'min': 1,
            'max': 7,
            'score_conclusion': 'low',
            'score': '',
            'treatment': '',
            'goal': '',
            'operation': '',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_gpe_dv_v1