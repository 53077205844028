import { reactive } from 'vue'

const state = reactive({
    active: false,
    id: '',
})

// -----------------------------------
// Private Methods
// -----------------------------------
let close // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = () => {
    state.active = true
    return dialogPromise()
}
const reset = () => {
    state.active = false
}

// -----------------------------------
// Public interface
// -----------------------------------

const customerOverviewModal = {
    get state() {
        return state
    },
    show(id) {
        state.id = id
        return open()
    },
    cancel() {
        close(false)
        reset()
    },
}

export default customerOverviewModal