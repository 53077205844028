// ./services/DebtorDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class DebtorDataService {
    async AddDebtor(dataObj) {
        const colRef = collection(db, store.state.customer + 'debtors');

        let debtorId
        await addDoc(colRef, dataObj)
            .then((docRef) => {
                debtorId = docRef.id
            })
            .catch(e => {
                console.log(e);
                debtorId = ''
            })
        return debtorId
    }
    async UpdateDebtor(debtorId, dataObj) {
        const docRef = doc(db, store.state.customer + 'debtors', debtorId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveDebtor(debtorId) {
        const docRef = doc(db, store.state.customer + 'debtors', debtorId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetDebtor(debtorId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'debtors', debtorId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetDebtors() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const debtorRef = collection(db, store.state.customer + 'debtors', )
                const debtorQuery = query(debtorRef, orderBy('name'))
                const snapShot = await getDocs(debtorQuery);

                let debtors = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    debtors.push(data)
                })

                resolve(debtors)

            } catch(e) {
                console.log(e)
                reject([])
            }
        })
    }
    async GetDebtorIdFromClientId(clientId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const debtorRef = collection(db, store.state.customer + 'debtors', )
                const debtorQuery = query(debtorRef, where('client_id','==', clientId))
                const snapShot = await getDocs(debtorQuery);

                let debtorId = ''
                snapShot.forEach(doc => {
                    //let data = doc.data()
                    debtorId = doc.id
                })

                resolve(debtorId)

            } catch(e) {
                console.log(e)
                reject('')
            }
        })
    }
}

export default new DebtorDataService();