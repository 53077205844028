<template>
  <div v-if="dialog.state.active" class="container card" style="z-index: 9999">
    <div class="card-body text-secondary dialog-inner">
      <h5 class="card-title text-secondary mb-3" v-if="dialog.state.title">
        <img class="img-fluid" src="@/assets/blue/information-24.png" alt="delete"/>
        {{dialog.state.title}}
      </h5>
      <hr>

      <p v-if="dialog.state.html" v-html="dialog.state.message"></p>
      <p v-else>{{ dialog.state.message }}</p>

      <input v-if="dialog.state.type === 'prompt'" :type="dialog.state.inputType" v-model="userInput">

      <div>
        <button type="button" class="btn btn-primary opacity-75 m-2 button" @click="dialog.ok(userInput)" style="width: 120px;">{{dialog.state.okText}}</button>
        <button type="button" class="btn btn-secondary opacity-75 m-2 button" v-if="dialog.state.type !== 'alert'" @click="dialog.cancel()" style="width: 120px;">{{dialog.state.cancelText}}</button>
      </div>

    </div>
    <div class="dialog-bg" @click="dialog.cancel()"></div>
  </div>
</template>

<script>
import dialog from '@/services/DialogService'

export default {
  name: 'show_dialog',
  data() {
    return {
      userInput: '',
      dialog
    }
  },
}
</script>

<style>
.dialog-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 98;
  backdrop-filter: blur(5px);
}
.dialog-inner {
  background: white;
  z-index: 99;
  position: fixed;
  top: 200px;
  width: 600px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}
.button {
  width: 64px;
}
</style>