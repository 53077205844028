// ./services/DossierMessagesDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    where,
    query,
    setDoc,
    orderBy, getDoc
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import SessionDataService from "@/services/SessionDataService";
import OnlineRequestsDataService from "@/services/OnlineRequestsDataService";
import OnlineRequestsService from "@/services/OnlineRequestsService";
import ClientStoreDataService from "@/services/ClientStoreDataService";

class DossierMessagesDataService {
    async GetDossierMessage(dossierMessageId) {
        if (dossierMessageId === '') {
            return null
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'dossier_messages', dossierMessageId))
                const data = docSnap.data()
                ClientStoreDataService.GetEmployeeDescription(data.employee_id).then((result) => {
                    data.employee_description = String(result)
                })
                resolve(data)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetDossierMessages(dossierId) {
        let dossierMessages = []
        const dossierMessagesQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_messages'), where('dossier_id', '==', dossierId), orderBy('creation_date')));
        dossierMessagesQuery.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            ClientStoreDataService.GetEmployeeDescription(data.employee_id).then((result) => {
                data.employee_description = String(result)
            })

            dossierMessages.push(data);
        })

        return dossierMessages
    }
    async GetDossierMessagesFromType(dossierId, messageType) {
        let dossierMessages = []
        const dossierMessagesQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_messages'), where('dossier_id', '==', dossierId), where('message_type', '==', messageType), orderBy('creation_date')));
        dossierMessagesQuery.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            ClientStoreDataService.GetEmployeeDescription(data.employee_id).then((result) => {
                data.employee_description = String(result)
            })

            dossierMessages.push(data);
        })

        return dossierMessages
    }
    async GetAllMessagesFromType(messageType) {
        let dossierMessages = []
        const dossierMessagesQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_messages'), where('message_type', '==', messageType), orderBy('creation_date')));
        dossierMessagesQuery.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            ClientStoreDataService.GetEmployeeDescription(data.employee_id).then((result) => {
                data.employee_description = String(result)
            })

            dossierMessages.push(data);
        })

        return dossierMessages
    }
    async DeleteDossierMessagesFromType(dossierId, messageType) {
        const dossierMessagesQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_messages'), where('dossier_id', '==', dossierId), where('message_type', '==', messageType), orderBy('creation_date')));
        dossierMessagesQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })
    }
    async DeleteDossierMessagesWithClinimetricsId(dossierId, messageType, clinimetricsId) {
        const dossierMessagesQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_messages'), where('dossier_id', '==', dossierId), where('message_type', '==', messageType), orderBy('creation_date')));
        dossierMessagesQuery.forEach((doc) => {
            const data = doc.data()
            if (data.clinimetrics_id === clinimetricsId) {
                deleteDoc(doc.ref)
            }
        })
    }
    async AddDossierMessage(dataObj) {
        const colRef = collection(db, store.state.customer + 'dossier_messages');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateDossierMessage(dossierMessageId, dataObj) {
        const docRef = doc(db, store.state.customer + 'dossier_messages', dossierMessageId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveDossierMessage(dossierMessageId) {
        const docRef = doc(db, store.state.customer + 'dossier_messages', dossierMessageId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async RemoveDossierMessageWithOnlineRequestId(onlineRequestId) {
        const dossierMessageQuery = await getDocs(query(collection(db, store.state.customer + 'dossier_messages'), where('online_request_id', '==', onlineRequestId)));
        dossierMessageQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })
    }
    async CheckOfClinimetricMomentExists(dossierId, object) {
        let result = false
        const clinimetricMoments = await this.GetDossierMessagesFromType(dossierId,'clinimetric_moment')

        clinimetricMoments.forEach((item) => {
            if (object.clinimetrics_id === item.clinimetrics_id && object.t_moment === item.t_moment) {
                result = true
            }
        })

        return result
    }
    async CheckOfTaskExists(dossierId, object) {
        let result = false
        const tasks = await this.GetDossierMessagesFromType(dossierId,'task')
        tasks.forEach((item) => {
            if (object.task_description === item.task_description && object.session === item.session) {
                result = true
            }
        })
        return result
    }
    async UpdateClinimetricMoment(dossierId, object, status) {
        const dossierMessagesQuery = await getDocs(
            query(collection(db, store.state.customer + 'dossier_messages'),
                where('message_type', '==', 'clinimetric_moment'),
                where('dossier_id', '==', object.dossier_id),
                where('clinimetrics_id', '==', object.clinimetric_id),
                where('t_moment', '==', object.t_moment),
                orderBy('creation_date')))
        let dossierMessagesIds = []
        dossierMessagesQuery.forEach((doc) => {
            dossierMessagesIds.push(doc.id)
        })

        for (const id of dossierMessagesIds) {
            const updateObject = {
                status: status,
                clinimetric_data_id: object.clinimetric_data_id,
            }
            console.log(updateObject)
            await this.UpdateDossierMessage(id, updateObject)
        }
    }
    async CheckTasks(dossierId) {
        if (dossierId === undefined) {
            return
        }

        let sessions = await SessionDataService.GetSessionsFromDossier(dossierId)
        sessions = sessions.filter(item => new Date(item.startDate.seconds * 1000) < new Date())

        const tasks = await this.GetDossierMessagesFromType(dossierId, 'task')

        tasks.forEach((item) => {
            if ((item.status === 'open' || item.status === 'pending') && ((Number(item.session) + 2) <= sessions.length)) {
                const dataObj = {
                    status: 'expired',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
            else if ((item.status === 'open') && Number(item.session) <= sessions.length) {
                const dataObj = {
                    status: 'pending',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
            else if ((item.status === 'pending' || item.status === 'expired') && Number(item.session) > sessions.length) {
                const dataObj = {
                    status: 'open',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
            else if ((item.status === 'expired') && ((Number(item.session) + 2) > sessions.length)) {
                const dataObj = {
                    status: 'pending',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
        })

        // deze doet echt gek
        //await store.dispatch('updateDossierMessages')
    }
    async CheckClinimetrics(dossierId) {
        if (dossierId === undefined) {
            return
        }

        let sessions = await SessionDataService.GetSessionsFromDossier(dossierId)
        sessions = sessions.filter(item => item.startDate.seconds < (Date.now() / 1000))
        const clinimetrics = await this.GetDossierMessagesFromType(dossierId, 'clinimetric_moment')

        clinimetrics.forEach((item) => {
            if ((item.status === 'open' || item.status === 'pending') && ((Number(item.session) + 2) <= sessions.length)) {
                const dataObj = {
                    status: 'expired',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
            else if ((item.status === 'open') && Number(item.session) <= sessions.length) {
                const dataObj = {
                    status: 'pending',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
            else if ((item.status === 'pending' || item.status === 'expired') && Number(item.session) > sessions.length) {
                const dataObj = {
                    status: 'open',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
            else if ((item.status === 'expired') && ((Number(item.session) + 2) > sessions.length)) {
                const dataObj = {
                    status: 'pending',
                }
                this.UpdateDossierMessage(item.id, dataObj)
            }
        })
    }
    async ProcessPermissionTask(dossierMessageId) {
        const dossierMessage = await this.GetDossierMessage(dossierMessageId)

        const onlineRequestId = dossierMessage.online_request_id
        if (onlineRequestId !== '') {
            const onlineRequest = await OnlineRequestsDataService.GetOnlineRequest(onlineRequestId)

            let permissionItem = null
            onlineRequest.permissions.forEach((item) => {
                if (item.dossier_message_id === dossierMessageId) {
                    permissionItem = item
                }
            })

            if (permissionItem !== null) {
                await OnlineRequestsService.processPermissionsRequest(permissionItem, onlineRequestId)
            }
        }
    }
}

export default new DossierMessagesDataService();