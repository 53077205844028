<template>
  <div v-if="noraInfoModal.state.active" class="modal show" id="noraInfoModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!--div class="modal-header gradient">
          <div class="row text-center">
            <h5 class="modal-title text-center ps-3" id="todoModalLabel">
              Info
            </h5>
          </div>
          <button type="button" class="btn-close" @click="hideModal()"></button>
        </div-->

        <div class="modal-body" @click="hideModal()">
          <div class="container">
            <div class="row mt-2 mb-5">
              <div class="col">
                <img src="@/assets/nora-logo.png" alt="NORA" width="280px;"/>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col text-secondary">
                <img src="@/assets/blue/mail-24.png" alt="mail" class="img-fluid"/>
                info@nora-software.nl
              </div>
            </div>
            <div class="row mt-2">
              <div class="col text-secondary">
                <img src="@/assets/blue/phone-24.png" alt="mail" class="img-fluid"/>
                06 27 23 74 19
              </div>
            </div>
            <div class="row mt-4">
              <div class="col text-end fw-light text-secondary">
                Versie: 1.0.0
              </div>
            </div>
          </div>
        </div>
        <!--div class="modal-footer bg-light pt-1 pb-1">
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">Sluiten</button>
        </div-->
      </div>
    </div>
  </div>

  <div v-show="noraInfoModal.state.active" class="dialog-bg"></div>
</template>

<script>
import noraInfoModal from "@/services/modals/NoraInfoModalService";

export default {
  name: "NoraInfoModal",
  data() {
    return {
      noraInfoModal,
    };
  },
  methods: {
    hideModal() {
      noraInfoModal.cancel()
    },
  },
}
</script>

<style scoped>

</style>