<template>
  <div v-if="clientBasketModal.state.active" class="modal show" id="clientBasketModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header gradient">
          <div class="row ps-2">
            <div class="col">
              <h5 class="modal-title text-secondary text-center" id="todoModalLabel">
                <img src="@/assets/blue/basket-24.png" alt="clients" class="img-fluid"/>
                Winkelwagen - <b>{{ client.first_name + ' ' + client.last_name }}</b>
              </h5>
            </div>
          </div>
          <button type="button" class="btn-close" @click="hideModal()"></button>
        </div>

        <div class="modal-body" style="min-height: 420px; max-height: 420px;">
          <div class="container">
            <div class="row">
              <div class="col-6">
                <ClientHeader :client="client" :dossier="dossier"/>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <ul class="list-group">
                  <li class="list-group-item text-secondary" style="background-color: rgba(25, 159, 214, 0.10);">
                    <div class="row">
                      <div class="col-1"/>
                      <div class="col text-start">
                        Product
                      </div>
                      <div class="col-1">
                        Aantal
                      </div>
                      <div class="col-2 text-end">
                        Bedrag
                      </div>
                      <div class="col-1"/>
                    </div>
                  </li>
                  <li v-show="filteredProductInvoicing.length === 0" class="list-group-item text-secondary">
                    <div class="row m-3">
                      <div class="col">
                        De winkelwagen is leeg
                      </div>
                    </div>
                  </li>
                  <li v-for="(item, index) in filteredProductInvoicing" :key="item.id" class="list-group-item text-secondary">
                    <div class="row">
                      <div class="col-1">
                        <div class="form-check">
                          <input v-show="(!item.postponed)" class="form-check-input opacity-50" type="checkbox" value="" :id="'flexCheck_'+index" v-model="filteredProductInvoicing[index].selected">
                        </div>
                      </div>
                      <div class="col text-start" @click="showProductInvoicing(item.id)">
                        <div class="d-inline-block">
                          {{ item.product_description }}
                        </div>
                        <div v-if="item.postponed" class="alert alert-primary d-inline-block ms-2 mb-0 pt-0 pb-0 ps-1 pe-1" role="alert">
                          Uitgesteld
                        </div>
                      </div>
                      <div class="col-1" @click="showProductInvoicing(item.id)">
                        {{ item.amount }}
                      </div>
                      <div class="col-2 text-end" @click="showProductInvoicing(item.id)">
                        {{ NoraUtils.showAmount(item.amount_incl_vat) }}
                      </div>
                      <div class="col-1">
                        <div class="col text-end p-0">
                          <div class="dropdown">
                            <button class="btn p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="@/assets/menu-vertical-24.png" class="img-fluid mt-0 me-0">
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" style="background-color: rgb(245,252,253);">
                              <li>
                                <a class="dropdown-item me-3" v-on:click="showProductInvoicing(item.id)">
                                  <img src="@/assets/blue/pencil-16.png" class="img-fluid me-2">
                                  Wijzig product
                                </a>
                              </li>
                              <li>
                                <hr class="dropdown-divider ms-2 me-2">
                              </li>
                              <li>
                                <a class="dropdown-item me-3" v-on:click="removeProductInvoicing(item.id)">
                                  <img src="@/assets/blue/trash-can-16.png" class="img-fluid me-2">
                                  Verwijderen product
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-start">
                <button type="button" class="btn btn-primary opacity-50" @click="showProductInvoicing('')">
                  <i class="bi bi-plus-circle me-2" style="font-size: 1rem; color: whitesmoke"></i>
                  Toevoegen
                </button>
                <button v-show="readyToInvoice" type="button" class="btn btn-primary ms-3 opacity-50" @click="invoiceBasket()">
                  <i class="bi bi-currency-euro me-2" style="font-size: 1rem; color: whitesmoke"></i>
                  Factureer
                </button>
                <button v-show="!readyToInvoice" type="button" class="btn btn-secondary ms-3 opacity-50 disabled">
                  <i class="bi bi-currency-euro me-2" style="font-size: 1rem; color: whitesmoke"></i>
                  Factureer
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer bg-light pt-2 pb-2">
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">
            <i class="bi bi-x-circle me-2" style="font-size: 1rem; color: whitesmoke"></i>
            Sluiten
          </button>
        </div>
      </div>
    </div>
  </div>

  <ProductInvoicingModal/>

  <div v-show="clientBasketModal.state.active" class="dialog-bg"></div>
</template>

<script>
import clientBasketModal from "@/services/modals/ClientBasketModalService";
import NoraUtils from "@/services/NoraUtils";
import productInvoicingModal from "@/services/modals/ProductInvoicingModalService";
import ClientHeader from "@/components/client/ClientHeader";
import ProductInvoicingDataService from "@/services/ProductInvoicingDataService";
import ProductInvoicingModal from "@/components/financial/ProductInvoicingModal";
import InvoicingDataService from "@/services/InvoicingDataService";

export default {
  name: "ClientBasketModal",
  components: {ProductInvoicingModal, ClientHeader},
  data() {
    return {
      clientBasketModal,
      NoraUtils,
    };
  },
  methods: {
    hideModal() {
      clientBasketModal.cancel()
    },
    async invoiceBasket() {
      let invoiceObj = []
      this.filteredProductInvoicing.forEach((item) => {
        if (item.selected) {
          invoiceObj.push({
            'amount_excl_vat': item.amount_excl_vat,
            'amount_incl_vat': item.amount_incl_vat,
            'amount_vat': item.amount_vat,
            'amount': item.amount,
            'client_id': item.patient_id,
            'client_name': item.patient_name,
            'date': item.date,
            'id': item.id,
            'product_description': item.product_description,
            'product_id': item.product_id,
            'product_type': item.product_type,
            'session_id': item.session_id ? item.session_id : '',
            'vat_percentage': item.vat_percentage,
          })
        }
      })

      InvoicingDataService
          .CreateInvoice(invoiceObj)
          .then((message) => {
            alert(message)
            this.hideModal()
          })
          .catch((message) => {
            alert(message)
          })


    },
    async showProductInvoicing(id) {
      await productInvoicingModal.show(id, this.clientId)
      clientBasketModal.state.productInvoicing = await ProductInvoicingDataService.GetProductInvoicingFromClient(this.clientId)
    },
    async removeProductInvoicing(id) {
      await ProductInvoicingDataService.RemoveProductInvoicing(id)
      clientBasketModal.state.productInvoicing = await ProductInvoicingDataService.GetProductInvoicingFromClient(this.clientId)
    },
    async saveClientContact() {
      //const dataObj = {

      //}

      if (clientBasketModal.state.id === '') {
        console.log('') //ClientContactDataService.AddClientContact(dataObj)
      } else {
        console.log('') //ClientContactDataService.UpdateClientContact(clientContactModal.state.id, dataObj)
      }

      this.hideModal()
    },
  },
  watch: {

  },
  computed: {
    employees() {
      return this.$store.state.ClientsStore.employees
    },
    clientId() {
      return clientBasketModal.state.clientId
    },
    client() {
      return this.$store.state.patient
    },
    dossier() {
      return this.$store.state.dossier
    },
    productInvoicing() {
      return clientBasketModal.state.productInvoicing
    },
    filteredProductInvoicing() {
      let filtered = []
      this.productInvoicing.forEach((item) => {
        if ((item.product_type === 'product') && (item.financial_status === 'not_invoiced')) {
          item.selected = (item.postponed === false)
          filtered.push(item)
        }
      })

      return filtered
    },
    readyToInvoice() {
      let ready = false
      this.filteredProductInvoicing.forEach((item) => {
        if (item.selected) {
          ready = true
        }
      })
      return ready
    },
  },
}
</script>

<style scoped>

</style>