const questionnaire_smsk_v1 = {
    'id': 'SMSK_v1',
    'description': 'STarT MSK tool',
    'short': 'SMSK',
    'online': true,
    'questions': [
        {
            'number': 1,
            'info': '',
            'description': 'Gemiddeld genomen, hoe hevig was uw pijn?',
            'question_type': 'slider_min_max',
            'min_value': 0,
            'min_description': 'Geen enkele pijn',
            'max_value': 10,
            'max_description': 'Ergst denkbare pijn',
            'score': '',
            'required': true,
        },
        {
            'number': 2,
            'info': '',
            'description': 'Voelt u zich vaak onzeker over hoe u met uw pijn moet omgaan?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 3,
            'info': '',
            'description': 'Bent u in de laatste 2 weken veel gehinderd door uw pijn?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 4,
            'info': '',
            'description': 'Bent u door uw pijn alleen in staat geweest korte afstanden te lopen?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 5,
            'info': '',
            'description': 'Heeft u hinderlijke gewrichts- of spierpijn gehad in meer dan één lichaamsdeel?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 6,
            'info': '',
            'description': 'Denkt u dat uw klacht lang zal aanhouden?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 7,
            'info': '',
            'description': 'Heeft u andere belangrijke gezondheidsproblemen?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 8,
            'info': '',
            'description': 'Heeft u zich in de laatste 2 weken somber of depressief gevoeld door uw pijn?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 9,
            'info': '',
            'description': 'Heeft u het gevoel dat het voor iemand met uw klacht onveilig is om lichamelijk actief te zijn?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },
        {
            'number': 10,
            'info': '',
            'description': 'Heeft u uw huidige pijnklacht sinds 6 maanden of langer?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Ja',
                },
                {
                    'value': 0,
                    'description': 'Nee',
                },
            ],
            'score': '',
            'required': true,
        },

    ],
    'scores': [
        {
            'description': 'Totaalscore',
            'formule': '((S1<5) ? 0 : ((S1<7) ? 1 : ((S1<9) ? 2 : 3)))+S2+S3+S4+S5+S6+S7+S8+S9+S10',
            'min': 0,
            'max': 12,
            'score_conclusion': 'low',
            'score': '',
            'treatment': '',
            'goal': '',
            'operation': '',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_smsk_v1