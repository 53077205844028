import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import router from './router'
import store from './store'
import PrimeVue from "primevue/config";
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css'
import VueCryptojs from "vue-cryptojs";
import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import "tippy.js/themes/light.css"
import "tippy.js/themes/light-border.css"
import "tippy.js/themes/translucent.css"

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'
import { getAuth } from 'firebase/auth';
import { clear, setCurrentEmployeeAndCustomer } from '@/services/Auth';

// Require Froala Editor js file.
require('froala-editor/js/froala_editor.pkgd.min.js')

// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')

const init = () => createApp(App)
    .use(router)
    .use(store)
    .use(VueFroala)
    .use(PrimeVue)
    .use(VueCryptojs)
    .use(VueTippy, {
        directive: 'tippy',
        component: 'tippy',
    })
    .mount('#app')

const unsubscribe = getAuth().onAuthStateChanged(user => {
    if (user) {
        setCurrentEmployeeAndCustomer(user.email)
            .then(() => init())
            .then(() => unsubscribe())
    } else {
        clear()
            .then(() => init())
            .then(() => unsubscribe())
    }
})

