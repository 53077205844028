<template>
  <meta meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
  <div v-if="clientListModal.state.active" class="modal modal-lg show" id="clientListModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header gradient p-2">
          <div class="row ps-2">
            <div class="col">
              <h5 class="modal-title text-center text-secondary" id="todoModalLabel">
                <img src="@/assets/blue/employees-32.png" alt="clients" class="img-fluid"/>
                Cliëntenlijst
              </h5>
            </div>

          </div>
          <button type="button" class="btn-close me-2" @click="hideModal()"></button>
        </div>

        <div class="modal-body" style="min-height: 600px; max-height: 600px;">
          <div class="container text-secondary" style="height: 480px;">
            <div class="row mt-2 mb-4">
              <div :class="isMobile ? 'col-2' : 'col-1 text-end'">
                <img src="@/assets/blue/search-24.png" alt="search" class="img-fluid pt-2" @click="focusSearch()"/>
              </div>
              <div class="col">
                <input type="text" class="form-control text-secondary" ref="searchText" placeholder="Zoek..." v-model="searchText"/>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <ul class="list-group overflow-auto scrollbar" style="max-height: 480px;">
                  <li class="list-group-item sticky-top" style="background-color: rgb(203,240,255);">
                    <div class="row text-secondary fw-semibold">
                      <div v-if="!isMobile" class="col-1">

                      </div>
                      <div v-if="isMobile" class="col text-start">
                        Naam
                      </div>
                      <div v-if="!isMobile" class="col">
                        Achternaam
                      </div>
                      <div v-if="!isMobile" class="col">
                        Voornaam
                      </div>
                      <div class="col text-truncate">
                        Geboortedatum
                      </div>
                      <div class="col-1 text-end">

                      </div>
                    </div>
                  </li>
                  <li
                      v-for="(client) in filteredClients"
                      v-bind:key="client.id"
                      class="list-group-item"
                      :class="client.archive === true ? 'archive' : ''"
                  >
                    <div class="row text-secondary">
                      <div v-if="!isMobile" class="col-1">
                        <img v-if="client.archive === false" src="@/assets/blue/circle-16.png" alt="active" class="img-fluid"/>
                        <img v-if="client.archive === true" src="@/assets/blue/circle-16.png" alt="active" class="img-fluid gray"/>
                      </div>
                      <div v-if="isMobile" class="col text-start" @click="selectClient(client.id)">
                        {{ client.last_name }}, {{ client.first_name }}
                      </div>
                      <div v-if="!isMobile" class="col" @click="selectClient(client.id)">
                        {{ client.last_name }}
                      </div>
                      <div v-if="!isMobile" class="col" @click="selectClient(client.id)">
                        {{ client.first_name}}
                      </div>
                      <div class="col" :class="isMobile ? 'text-start' : ''" @click="selectClient(client.id)">
                        {{ client.birthdate && client.birthdate !== '' ? new Date(client.birthdate).toLocaleDateString() : '' }}
                      </div>
                      <div class="col-1 text-end p-0">
                        <div class="dropdown">
                          <button class="btn p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="@/assets/menu-vertical-24.png" alt="menu" class="img-fluid mt-0 me-0">
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end" style="background-color: rgb(245,252,253);">
                            <li>
                              <a class="dropdown-item me-3 text-secondary" v-on:click="changeClientStatus(client.id, false)">
                                <img src="@/assets/blue/checkbox-16.png" alt="edit" class="img-fluid me-2">
                                Activeer cliënt
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item me-3 text-secondary" v-on:click="changeClientStatus(client.id, true)">
                                <img src="@/assets/blue/uncheckbox-16.png" alt="edit" class="img-fluid me-2">
                                Archiveer cliënt
                              </a>
                            </li>
                            <li><hr class="dropdown-divider ms-2 me-2"></li>
                            <li>
                              <a class="dropdown-item me-3 text-secondary" v-on:click="removeClient(client.id)">
                                <img src="@/assets/blue/delete-16.png" alt="remove client" class="img-fluid me-2">
                                Verwijder cliënt
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item sticky-bottom">
                    <div class="row text-secondary" @click="newClient()">
                      <div class="col">
                        <img src="@/assets/add-24.png" alt="Nieuw" class="img-fluid"/>
                        Nieuwe client
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col text-start">
                <input type="checkbox" class="form-check-input me-2 opacity-75"  id="checkShowAll" v-model="showAll" checked>
                <label class="form-check-label text-start" for="checkShowAll">
                  Toon ook de cliënten in het archief
                </label>
              </div>
            </div>
          </div>
        </div>

       <div class="modal-footer bg-light pt-1 pb-1">
          <button type="button" class="btn btn-secondary opacity-75" @click="hideModal()">Sluiten</button>
        </div>
      </div>
    </div>
  </div>

  <ShowDialog/>

  <div v-show="clientListModal.state.active" class="dialog-bg"></div>
</template>

<script>
import clientListModal from "@/services/modals/ClientListModalService";
import router from "@/router";
import ClientModalService from "@/services/modals/ClientModalService";
import ClientDataService from "@/services/ClientDataService";
import dialog from "@/services/DialogService";
import ShowDialog from '@/components/ShowDialog'

export default {
  name: "ClientListModal",
  components: { ShowDialog },
  data() {
    return {
      searchText: '',
      showAll: false,
      clientListModal,
    };
  },
  methods: {
    hideModal() {
      this.searchText = ''
      clientListModal.cancel()
    },
    selectClient(clientId) {
      this.$store.dispatch('resetPatient')
      this.$store.dispatch('setPatientId', clientId)

      this.searchText = ''
      clientListModal.cancel()
      router.push('/main');
    },
    newClient() {
      this.searchText = ''
      this.hideModal()
      ClientModalService.show('')
    },
    async changeClientStatus(clientId, status) {
      const updateObj = {
        archive: status,
      }

      await ClientDataService.UpdateClient(clientId, updateObj)
      this.$store.dispatch('ClientsStore/getClients')
    },
    async removeClient(clientId) {
      const confirmed = await dialog
          .title('Verwijder client')
          .okText('Ja')
          .cancelText('Annuleren')
          .confirm('Weet je zeker dat deze cliënt verwijderd moet worden?')

      if (confirmed) {
        await ClientDataService.RemoveClient(clientId).then(() => {
          this.$store.dispatch('ClientsStore/getClients')
        })
      }

    },
    focusSearch() {
      if (this.$refs.searchText) {
        this.$refs.searchText.focus()
      }
    }
  },
  computed: {
    clients() {
      return this.$store.state.ClientsStore.clients.filter(f => {
        return f.archive === false || this.showAll === true
      })
    },
    filteredClients() {
      let filteredClients = []
      this.clients.forEach((client) => {
        if ((client.last_name.toLowerCase().includes(this.searchText.toLowerCase())) ||
            (client.phone.replace(/\s+/g,'').includes(this.searchText.toLowerCase().replace(/\s+/g,''))) ||
            (client.mobile.replace(/\s+/g,'').includes(this.searchText.toLowerCase().replace(/\s+/g, ''))) ||
            (client.first_name.toLowerCase().includes(this.searchText.toLowerCase()))) {
          filteredClients.push(client)
        }
      })
      return filteredClients
    },
    isMobile() {
      return this.$store.state.mobileDevice
    },
  },
  updated() {
    this.focusSearch()
  },
}
</script>

<style scoped>
.archive {
  background-color: rgba(243, 242, 239, 0.64);
}
</style>