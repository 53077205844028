import { reactive } from 'vue'
import ThirdPartyDataService from "@/services/ThirdPartyDataService";

const state = reactive({
    active: false,
    id: '',
    thirdParty: null,
})

// -----------------------------------
// Private Methods
// -----------------------------------
let close // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = () => {
    state.active = true
    return dialogPromise()
}
const reset = () => {
    state.active = false
}

// -----------------------------------
// Public interface
// -----------------------------------

const thirdPartyModal = {
    get state() {
        return state
    },
    show(id) {
        state.id = id
        if (id !== '') {
            ThirdPartyDataService.GetThirdParty(id).then((result) => {
                state.thirdParty = result
            })
        } else {
            state.thirdParty = null
        }

        return open()
    },
    cancel() {
        close(false)
        reset()
    },
}

export default thirdPartyModal