const questionnaire_hoos_ps_v1 = {
    'id': 'HOOS_PS_v1',
    'description': 'Hip Injury and Osteoarthritis Outcome Score (HOOS-PS)',
    'short': 'HOOS-PS',
    'questions': [
        {
            'number': 1,
            'info': 'Wilt u voor elk van de onderstaande activiteiten aangeven hoeveel moeite u de afgelopen week ' +
                'heeft ervaren tijdens deze activiteiten vanwege uw heup.',
            'description': 'Trap aflopen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'In/uit bad of douche gaan',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Zitten',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Hardlopen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'Draaien op een belast been',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 0,
                    'description': 'Geen',
                },
                {
                    'value': 1,
                    'description': 'Gering',
                },
                {
                    'value': 2,
                    'description': 'Matig',
                },
                {
                    'value': 3,
                    'description': 'Veel',
                },
                {
                    'value': 4,
                    'description': 'Erg veel',
                },
            ],
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Totaalscore',
            'formule': 'S1+S2+S3+S4+S5',
            'min': 0,
            'max': 100,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Beperkingen',
            'goal': 'Verminderen van beperkingen in ADL',
            'operation': 'Actieve en passieve mobilisatie',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_hoos_ps_v1