import ClientDataService from "@/services/ClientDataService";
import ProductDataService from "@/services/ProductDataService";
import EmployeeDataService from "@/services/EmployeeDataService";
import AppointmentTypeDataService from "@/services/AppointmentTypeDataService";
import ComplaintDataService from "@/services/ComplaintDataService";
import ThirdPartyDataService from "@/services/ThirdPartyDataService";
import LocationsDataService from "@/services/LocationsDataService";
import InstituteDataService from "@/services/InstituteDataService";
import store from "@/store";
import TasksDataService from "@/services/TasksDataService";
import ExercisesDataService from "@/services/ExercisesDataService";

export default {
    namespaced: true,
    state: {
        clients: [],
        employees: [],
        thirdParties: [],
        locations: [],
        products: [],
        tasks: [],
        appointments: [],
        appointmentTypes: [],
        complaints: [],
        institute: {
            module: 'health',
            client_portal: false,
            module_administrative: false,
        },
        exercises: [],
    },
    mutations: {
        async GET_CLIENTS(state) {
            state.clients = await ClientDataService.GetClients()
        },
        async GET_EMPLOYEES(state) {
            state.employees = await EmployeeDataService.GetEmployees()
        },
        async GET_THIRD_PARTIES(state) {
            state.thirdParties = await ThirdPartyDataService.GetThirdParties()
        },
        async GET_LOCATIONS(state) {
            state.locations = await LocationsDataService.GetLocations()
        },
        async GET_PRODUCTS(state, productType) {
            state.products = await ProductDataService.GetProducts(productType)
        },
        async GET_TASKS(state) {
            state.tasks = await TasksDataService.GetTasks()
        },
        async GET_APPOINTMENTS(state, productType) {
            state.appointments = await ProductDataService.GetProducts(productType)
        },
        async GET_APPOINTMENT_TYPES(state) {
            state.appointmentTypes = await AppointmentTypeDataService.GetAppointmentTypes()
        },
        async GET_COMPLAINTS(state) {
            state.complaints = await ComplaintDataService.GetComplaints()
        },
        async GET_INSTITUTE(state) {
            await InstituteDataService.GetInstitute(store.state.currentInstituteId).then((result) => {
                if (result === null) {
                    state.institute = {
                        module_haptotherapie: true,
                        module: 'health',
                        client_portal: false,
                    }
                } else {
                    state.institute = result
                }

                if (('module_haptotherapie' in state.institute) === false) {
                    state.institute.module_haptotherapie = true
                }
                if (('module' in state.institute) === false) {
                    state.institute.module = 'health'
                }
                if (('client_portal' in state.institute) === false) {
                    state.institute.client_portal = false
                }
                state.institute.module_administrative = false
            })
        },
        async GET_EXERCISES(state) {
            state.exercises = await ExercisesDataService.GetExercises()
        },
    },
    actions: {
        getClients(context) {
            context.commit('GET_CLIENTS')
        },
        getEmployees(context) {
            context.commit('GET_EMPLOYEES')
        },
        getThirdParties(context) {
            context.commit('GET_THIRD_PARTIES')
        },
        getLocations(context) {
            context.commit('GET_LOCATIONS')
        },
        getProducts(context, productType) {
            context.commit('GET_PRODUCTS', productType)
        },
        getTasks(context) {
            context.commit('GET_TASKS')
        },
        getAppointments(context, productType) {
            context.commit('GET_APPOINTMENTS', productType)
        },
        getAppointmentTypes(context) {
            context.commit('GET_APPOINTMENT_TYPES')
        },
        getComplaints(context, dossierType) {
            context.commit('GET_COMPLAINTS', dossierType)
        },
        getInstitute(context) {
            context.commit('GET_INSTITUTE')
        },
        getExercises(context) {
            context.commit('GET_EXERCISES')
        },
    },
}