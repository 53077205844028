// ./services/SessionDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    where,
    query,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import ProductInvoicingDataService from "@/services/ProductInvoicingDataService";
import ClientDataService from "@/services/ClientDataService";
import MailService from "@/services/MailService";
import DossierMessagesDataService from "@/services/DossierMessagesDataService";
import LocationsDataService from "@/services/LocationsDataService";

class SessionDataService {
    async AddSession(dataObj, productInvoicingObj) {
        const colRef = collection(db, store.state.customer + 'sessions');

        await addDoc(colRef, dataObj)
            .then((docRef) => {
                if (productInvoicingObj !== null) {
                    // product invoicing
                    productInvoicingObj.session_id = docRef.id
                    ProductInvoicingDataService.AddProductInvoicing(productInvoicingObj)
                }

                // tasks
                DossierMessagesDataService.CheckTasks(dataObj.dossier_id)

                // clinimetrics
                DossierMessagesDataService.CheckClinimetrics(dataObj.dossier_id)

                /*
                const timelineObject = {
                    'patient_id': dataObj.patient_id,
                    'dossier_id': dataObj.dossier_id,
                    'session_id': docRef.id,
                    'date': dataObj.startDate,
                    'timeline_type': 'session',
                }
                TimelineDataService.AddTimelineItem(timelineObject)

                 */
            })
            .catch(e => {
                console.log(e);
            });
    }
    async AddAppointment(dataObj) {
        const colRef = collection(db, store.state.customer + 'appointments');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Appointment added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateSession(sessionId, sessionObj, productInvoicingObj) {
        // Update product_invoicing
        if (productInvoicingObj !== null) {
            const productInvoicing = await ProductInvoicingDataService.GetProductInvoicingFromSession(sessionId)
            if (productInvoicing.length === 0) {
                productInvoicingObj.session_id = sessionId
                await ProductInvoicingDataService.AddProductInvoicing(productInvoicingObj)
            } else {
                for (const doc1 of productInvoicing) {
                    await ProductInvoicingDataService.UpdateProductInvoicing(doc1.id, productInvoicingObj)
                }
            }
        } else {
            const productInvoicing = await ProductInvoicingDataService.GetProductInvoicingFromSession(sessionId)
            for (const doc2 of productInvoicing) {
                await ProductInvoicingDataService.RemoveProductInvoicing(doc2.id)
            }
        }

        // Update session
        const docRef = doc(db, store.state.customer + 'sessions', sessionId);
        await setDoc(docRef, sessionObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });

        // tasks
        await DossierMessagesDataService.CheckTasks(sessionObj.dossier_id)

        // clinimetrics
        await DossierMessagesDataService.CheckClinimetrics(sessionObj.dossier_id)
    }
    async UpdateSessionFields(sessionId, object) {
        const docRef = doc(db, store.state.customer + 'sessions', sessionId)
        await updateDoc(docRef, object)

        if (object.dossier_id !== undefined) {
            // tasks
            await DossierMessagesDataService.CheckTasks(object.dossier_id)

            // clinimetrics
            await DossierMessagesDataService.CheckClinimetrics(object.dossier_id)
        }
    }
    async UpdateAppointmentFields(appointmentId, object) {
        const docRef = doc(db, store.state.customer + 'appointments', appointmentId)

        await updateDoc(docRef, object)
    }
    async RemoveSession(sessionId) {
        const session = await this.GetSession(sessionId)
        const client = await ClientDataService.GetClient(session.patient_id)
        const dossierId = session.dossier_id

        // Send cancelation mail
        if (client.email_appointment_confirmation === true) {
            const location = await LocationsDataService.GetLocation(session.location_id)

            const sessionObj = {
                startDate: new Date(session.textStartDatum),
                patient_name: session.patient_name,
                employee_name: session.employee_name,
                location_id: session.location_id,
            }
            await MailService.SendAppointmentCancelationEmail(sessionObj, client, location.appointment_cancelation_template_id || '')
        }

        // Delete corresponding product invoicing records
        const productInvoicingQuery = await getDocs(query(collection(db, store.state.customer + 'product_invoicing'), where('session_id', '==', sessionId)));
        productInvoicingQuery.forEach((doc) => {
            deleteDoc(doc.ref)
        })

        // Delete session
        const docRef = doc(db, store.state.customer + 'sessions', sessionId)
        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });

        await DossierMessagesDataService.CheckTasks(dossierId)

        await DossierMessagesDataService.CheckClinimetrics(dossierId)
    }
    async RemoveAppointment(appointmentId) {
        const docRef = doc(db, store.state.customer + 'appointments', appointmentId)
        await deleteDoc(docRef)
            .then(() => {
                console.log('Appointment deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetSession(sessionId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'sessions', sessionId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetAppointment(appointmentId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'appointments', appointmentId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetSessionsFromDossier(dossierId) {
        if (dossierId === undefined) {
            return
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let sessions = []
                const querySnap = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('dossier_id', '==', dossierId)));

                querySnap.forEach((doc) => {
                    sessions.push(doc.data())
                })

                resolve(sessions)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetSessionsFromEmployee(employeeId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let sessions = []
                const querySnap = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('employee_id', '==', employeeId)));

                querySnap.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    sessions.push(data)
                })

                resolve(sessions)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetAppointmentsFromEmployee(employeeId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let sessions = []
                const querySnap = await getDocs(query(collection(db, store.state.customer + 'appointments'), where('employee_id', '==', employeeId)));

                querySnap.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    sessions.push(data)
                })

                resolve(sessions)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetAllSessions() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let sessions = []
                const querySnap = await getDocs(query(collection(db, store.state.customer + 'sessions')));

                querySnap.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    sessions.push(data)
                })

                resolve(sessions)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetAllAppointments() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let sessions = []
                const querySnap = await getDocs(query(collection(db, store.state.customer + 'appointments')));

                querySnap.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    sessions.push(data)
                })

                resolve(sessions)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClientData(patientId) {

        let dossierId = ''

        const querySnap = await getDocs(query(collection(db, store.state.customer + 'dossiers'), where('patient_id', '==', patientId)));

        querySnap.forEach((doc) => {
            //let data = doc.data();
            dossierId = doc.id;
        })

        const docRef = doc(db, store.state.customer + 'patients', patientId)
        const docSnap = await getDoc(docRef)
        const patientName = docSnap.data().first_name + ' ' + docSnap.data().last_name

        return {
            'dossierId': dossierId,
            'patientId': patientId,
            'patientName': patientName,
            'patientEmail': docSnap.data().email,
            'email': docSnap.data().email,
        }
    }
    async GetRecurringAppointments(recurringId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let sessions = []
                const querySnap = await getDocs(query(collection(db, store.state.customer + 'appointments'), where('recurring_id', '==', recurringId)))

                querySnap.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    sessions.push(data)
                })

                resolve(sessions)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetRecurringSessions(recurringId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let sessions = []
                const querySnap = await getDocs(query(collection(db, store.state.customer + 'sessions'), where('recurring_id', '==', recurringId)))

                querySnap.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    sessions.push(data)
                })

                resolve(sessions)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }

}

export default new SessionDataService();