import ClientContactDataService from "@/services/ClientContactDataService";
import SessionDataService from "@/services/SessionDataService";
import DossierMessagesDataService from "@/services/DossierMessagesDataService";
import ClientComplaintsDataService from "@/services/ClientComplaintsDataService";
import PlannerDataService from "@/services/PlannerDataService";
import ClientPortalDataService from "@/firebase/ClientPortalDataService";

export default {
    namespaced: true,
    state: {
        clientContacts: [],
        clientComplaints: [],
        clientPortalAlerts: [],
        employeeSessions: [],
        dossierMessages: [],
        onlinePlannedSessions: [],
    },
    mutations: {
        async GET_CLIENT_CONTACTS(state, employeeId) {
            state.clientContacts = await ClientContactDataService.GetClientContacts(employeeId)
        },
        async GET_EMPLOYEE_SESSIONS(state, employeeId) {
            const employeeSessions = await SessionDataService.GetSessionsFromEmployee(employeeId)

            const employeeAppointments = await SessionDataService.GetAppointmentsFromEmployee(employeeId)

            let allTogether = []
            employeeSessions.forEach((item) => {
                allTogether.push(item)
            })
            employeeAppointments.forEach((item) => {
                allTogether.push(item)
            })

            state.employeeSessions = allTogether.sort((b, a) => new Date(b.startDate.seconds) - new Date(a.startDate.seconds))

            state.onlinePlannedSessions = await PlannerDataService.GetOnlineSessionsFromEmployee(employeeId)
        },
        async GET_DOSSIER_MESSAGES(state) {

            let dossierMessages = []

            // Get dossier permissions
            const dossierPermissionMessages = await DossierMessagesDataService.GetAllMessagesFromType('dossier_permissions')
            dossierPermissionMessages.forEach((item) => {
                dossierMessages.push(item)
            })

            // Get clinimetric moments
            const dossierClinimetricMoments = await DossierMessagesDataService.GetAllMessagesFromType('clinimetric_moment')
            dossierClinimetricMoments.forEach((item) => {
                dossierMessages.push(item)
            })

            // Get tasks
            const dossierTasks = await DossierMessagesDataService.GetAllMessagesFromType('task')
            dossierTasks.forEach((item) => {
                dossierMessages.push(item)
            })

            state.dossierMessages = dossierMessages
        },
        async GET_CLIENT_COMPLAINTS(state, employeeId) {
            state.clientComplaints = await ClientComplaintsDataService.GetClientComplaints(employeeId)
        },
        async GET_CLIENT_PORTAL_ALERTS(state, employeeId) {
            state.clientPortalAlerts = await ClientPortalDataService.GetEmployeeAlerts(employeeId)
        },
    },
    actions: {
        getClientContacts(context, employeeId) {
            context.commit('GET_CLIENT_CONTACTS', employeeId)
        },
        getEmployeeSessions(context, employeeId) {
            context.commit('GET_EMPLOYEE_SESSIONS', employeeId)
        },
        getDossierMessages(context) {
            context.commit('GET_DOSSIER_MESSAGES')
        },
        getClientComplaints(context, employeeId) {
            context.commit('GET_CLIENT_COMPLAINTS', employeeId)
        },
        getClientPortalAlerts(context, employeeId) {
            context.commit('GET_CLIENT_PORTAL_ALERTS', employeeId)
        },
    },
}