// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const secondFirebaseConfig = {
    apiKey: "AIzaSyDFSuSq9l0WpxhGKm_inhCwtoLa9U6zk-M",
    authDomain: "nora-client-portal.firebaseapp.com",
    projectId: "nora-client-portal",
    storageBucket: "nora-client-portal.appspot.com",
    messagingSenderId: "659980217994",
    appId: "1:659980217994:web:3896248cfc057769468773",
    measurementId: "G-8TJ0RYFQM4"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const secondaryApp = initializeApp(secondFirebaseConfig, 'clientPortalDb');

export const db = getFirestore(app)
export const clientPortalDb = getFirestore(secondaryApp)

export const clientPortalAuth = getAuth(secondaryApp)

// export default db
