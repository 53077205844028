// ./services/InstituteDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class InstituteDataService {
    async AddInstitute(dataObj) {
        const colRef = collection(db, store.state.customer + 'institutes');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Institute added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateInstitute(instituteId, dataObj) {
        const docRef = doc(db, store.state.customer + 'institutes', instituteId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveInstitute(instituteId) {
        const docRef = doc(db, store.state.customer + 'institutes', instituteId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetInstitute(instituteId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'institutes', instituteId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetInstitutes() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const instituteRef = collection(db, store.state.customer + 'institutes', )
                const instituteQuery = query(instituteRef)
                const snapShot = await getDocs(instituteQuery);

                let institutes = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    institutes.push(data)
                })
                resolve(institutes)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new InstituteDataService();