const questionnaire_q_dash_dlv_v1 = {
    'id': 'Q_DASH_DLV_v1',
    'description': 'Quick dash DLV (Q-DASH-DLV)',
    'short': 'Q-DASH-DLV',
    'questions': [
        {
            'number': 1,
            'info': 'Beoordeel bij de volgende vragen wat uw mogelijkheden zijn geweest om de volgende activiteiten ' +
                'te verrichten in de afgelopen week door het meest passende antwoord te selecteren.',
            'description': 'Openen van een vastgedraaide pot',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'Zwaar huidhoudelijk werk verrichten (bv. stofzuigen en/of vloeren soppen)',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Boodschappentas of aktetas dragen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Rug wassen',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'Een mes gebruiken om eten te snijden',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': 'Vrijetijdsbesteding waarbij enige kracht of belasting uitgeoefend wordt op de arm, ' +
                'schouder of hand (golfen, doe het zelf, tennissen, etc.)',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': 'In hoeverre heeft uw arm, schouder of hand, problemen gegeven met de normale sociale ' +
                'activiteiten met familie, vrienden, buren of groepen in de afgelopen week?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 8,
            'info': '',
            'description': 'In hoeverre bent u beperkt geweest in uw werk of andere dagelijkse activiteiten ten ' +
                'gevolge van uw arm, schouder of hand probleem, gedurende de afgelopen week?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Absoluut niet beperkt',
                },
                {
                    'value': 2,
                    'description': 'Enigszins beperkt',
                },
                {
                    'value': 3,
                    'description': 'Matig beperkt',
                },
                {
                    'value': 4,
                    'description': 'Veel beperkt',
                },
                {
                    'value': 5,
                    'description': 'Onmogelijk',
                },
            ],
            'score': '',
        },
        {
            'number': 9,
            'info': 'Geef de ernst van de klachten aan gedurende de afgelopen week.',
            'description': 'Pijn in arm, schouder of hand',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen',
                },
                {
                    'value': 2,
                    'description': 'Mild',
                },
                {
                    'value': 3,
                    'description': 'Matig',
                },
                {
                    'value': 4,
                    'description': 'Ernstig',
                },
                {
                    'value': 5,
                    'description': 'Extreem',
                },
            ],
            'score': '',
        },
        {
            'number': 10,
            'info': '',
            'description': 'Tintelingen (spelden prikken) in arm, schouder of hand',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen',
                },
                {
                    'value': 2,
                    'description': 'Mild',
                },
                {
                    'value': 3,
                    'description': 'Matig',
                },
                {
                    'value': 4,
                    'description': 'Ernstig',
                },
                {
                    'value': 5,
                    'description': 'Extreem',
                },
            ],
            'score': '',
        },
        {
            'number': 11,
            'info': '',
            'description': 'Hoeveel moeite heeft u gehad met slapen ten gevolge van pijn in uw arm, schouder of hand, ' +
                'gedurende de afgelopen week?',
            'question_type': 'multiple_choice_vertical',
            'items': [
                {
                    'value': 1,
                    'description': 'Geen probleem',
                },
                {
                    'value': 2,
                    'description': 'Gering probleem',
                },
                {
                    'value': 3,
                    'description': 'Probleem',
                },
                {
                    'value': 4,
                    'description': 'Ernstig probleem',
                },
                {
                    'value': 5,
                    'description': 'Niet mogelijk',
                },
            ],
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Totaalscore',
            'formule': '(((S1+S2+S3+S4+S5+S6+S7+S8+S9+S10+S11)/11)-1)*25',
            'min': 0,
            'max': 100,
            'score_conclusion': 'low',
            'score': '',
            'treatment': 'Beperkingen',
            'goal': 'Verminderen van beperkingen in ADL',
            'operation': 'Actieve en passieve mobilisatie',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_q_dash_dlv_v1