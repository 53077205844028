// ./services/CustomerDataService

import {
    doc,
    getDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";

class CustomerDataService {
    async CheckPassword(customer, password) {
        const docSnap = await getDoc(doc(db, 'customers', customer));
        if (docSnap.exists()) {
            const customerData = docSnap.data();

            if (customerData.simple_password === password) {
                return 'valid'
            } else {
                return 'Wrong password'
            }

        } else {
            return 'No valid username'
        }
    }
}

export default new CustomerDataService();