// ./services/SettingsDataService

import {doc, getDoc, updateDoc} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class SettingsDataService {
    async StoreSettings(dataObj) {
        const docRef = doc(db, store.state.customer)

        await updateDoc(docRef, dataObj)
            .catch((e) => {
                console.log(e)
            })
    }
    async GetSettings() {
        const customer = await getDoc(doc(db, store.state.customer))

        store.state.settings.financialSettings = customer.get('financial_settings')
        store.state.settings.calendarSettings = customer.get('calendar_settings')
    }
}

export default new SettingsDataService();