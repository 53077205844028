// ./services/OnlineRequestsDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, query, setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import DossierMessagesDataService from "@/services/DossierMessagesDataService";
import GeneralOnlineRequestsDataService from "@/services/GeneralOnlineRequestsDataService";

class OnlineRequestsDataService {
    async AddOnlineRequest(dataObj) {
        // Customer collection
        const colRef = collection(db, store.state.customer + 'online_requests');

        let onlineRequestId = ''
        await addDoc(colRef, dataObj)
            .then((docRef) => {
                onlineRequestId = docRef.id
            })
            .catch(e => {
                console.log(e)
                onlineRequestId = ''
            });

        // General
        const onlineRequestObj = {
            clinimetrics: dataObj.clinimetrics,
            permissions: dataObj.permissions,
        }
        await GeneralOnlineRequestsDataService.AddGeneralOnlineRequest(onlineRequestObj, onlineRequestId)

        return onlineRequestId
    }
    async UpdateOnlineRequest(dataObject, onlineRequestId) {
        const docRef = doc(db, store.state.customer + 'online_requests', onlineRequestId);

        await setDoc(docRef, dataObject, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveOnlineRequest(onlineRequestId) {
        const docRef = doc(db, store.state.customer + 'online_requests', onlineRequestId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetOnlineRequest(onlineRequestId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db,store.state.customer +  'online_requests', onlineRequestId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetOnlineRequestsFromDossier(dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve) {
            let requests = [];
            const requestQuery = await getDocs(query(collection(db,store.state.customer + 'online_requests'), where('dossier_id', '==', dossierId)));
            requestQuery.forEach((doc) => {
                const data = doc.data()
                data.id = doc.id

                requests.push(data);
            })

            // Online status ophalen
            for (let i = 0; i < requests.length; i++) {

                const generalOnlineRequest = await GeneralOnlineRequestsDataService.GetGeneralOnlineRequest(requests[i].id)

                if (requests[i].clinimetrics) {
                    requests[i].clinimetrics.forEach((item, index) => {
                        item.status = generalOnlineRequest.clinimetrics[index].status
                        if (item.status === 'done') {
                            item.answers = generalOnlineRequest.clinimetrics[index].answers
                            item.scores = generalOnlineRequest.clinimetrics[index].scores
                        }
                    })

                    // Update local online request with general data
                    const updateObj = {
                        clinimetrics: requests[i].clinimetrics,
                    }
                    await OnlineRequestsDataService.UpdateOnlineRequest(updateObj, requests[i].id)

                    // Update dossier messages
                    for (let j = 0; j < requests[i].clinimetrics.length; j++) {
                        if (requests[i].clinimetrics[j].status === 'done') {
                            const dossierMessageObj = {
                                status: 'ready',
                            }
                            await DossierMessagesDataService.UpdateDossierMessage(requests[i].clinimetrics[j].dossier_message_id, dossierMessageObj)

                        }
                    }
                }

                if ('permissions' in requests[i]) {
                    requests[i].permissions.forEach((item, index) => {
                        item.status = generalOnlineRequest.permissions[index].status
                        if (item.status === 'done') {
                            item.answers = generalOnlineRequest.permissions[index].answers
                            item.scores = generalOnlineRequest.permissions[index].scores
                        }
                    })

                    // Update local online request with general data
                    const updateObj = {
                        permissions: requests[i].permissions,
                    }
                    await OnlineRequestsDataService.UpdateOnlineRequest(updateObj, requests[i].id)

                    // Update dossier messages
                    for (let j = 0; j < requests[i].permissions.length; j++) {
                        if (requests[i].permissions[j].status === 'done') {
                            const dossierMessageObj = {
                                status: 'ready',
                            }
                            await DossierMessagesDataService.UpdateDossierMessage(requests[i].permissions[j].dossier_message_id, dossierMessageObj)

                        }
                    }
                }
            }

            resolve(requests)
        })

    }
    async GetOnlineRequests() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve) {
            let requests = [];
            const requestQuery = await getDocs(query(collection(db,store.state.customer + 'online_requests')));
            requestQuery.forEach((doc) => {
                const data = doc.data()
                data.id = doc.id

                requests.push(data);
            })

            // Online status ophalen
            for (let i = 0; i < requests.length; i++) {

                const generalOnlineRequest = await GeneralOnlineRequestsDataService.GetGeneralOnlineRequest(requests[i].id)

                if (requests[i].clinimetrics && generalOnlineRequest !== undefined) {
                    requests[i].clinimetrics.forEach((item, index) => {
                        item.status = generalOnlineRequest.clinimetrics[index].status
                        if (item.status === 'done') {
                            item.answers = generalOnlineRequest.clinimetrics[index].answers
                            item.scores = generalOnlineRequest.clinimetrics[index].scores
                        }
                    })
                }

                if (requests[i].permissions && generalOnlineRequest !== undefined) {
                    requests[i].permissions.forEach((item, index) => {
                        item.status = generalOnlineRequest.permissions[index].status
                        if (item.status === 'done') {
                            item.answers = generalOnlineRequest.permissions[index].answers
                            item.scores = generalOnlineRequest.permissions[index].scores
                        }
                    })
                }

                // Update local online request with general data
                const updateObj = {
                    clinimetrics: requests[i].clinimetrics || [],
                    permissions: requests[i].permissions || [],
                }
                await OnlineRequestsDataService.UpdateOnlineRequest(updateObj, requests[i].id)

                // Update dossier messages
                for (let j = 0; j < requests[i].clinimetrics.length; j++) {
                    if (requests[i].clinimetrics[j].status === 'done') {
                        const dossierMessageObj = {
                            status: 'ready',
                        }
                        await DossierMessagesDataService.UpdateDossierMessage(requests[i].clinimetrics[j].dossier_message_id, dossierMessageObj)

                    }
                }
            }

            resolve(requests)
        })

    }
    /*async GetOnlineRequestsFromDossier(dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve) {
            let requests = [];
            const requestQuery = await getDocs(query(collection(db,store.state.customer + 'online_requests'), where('dossier_id', '==', dossierId)));
            requestQuery.forEach((doc) => {
                const data = doc.data()
                data.id = doc.id

                requests.push(data);
            })

            resolve(requests)
        })
    }*/
    static async UpdateOnlineRequest(dataObject, onlineRequestId) {
        const docRef = doc(db, store.state.customer + 'online_requests', onlineRequestId);

        await setDoc(docRef, dataObject, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
}

export default new OnlineRequestsDataService()