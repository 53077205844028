// ./firebase/migrations

import {
    addDoc,
    collection,
    doc,
    setDoc,
    query,
    getDocs,
    orderBy, where, deleteDoc,
} from "firebase/firestore";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from 'firebase/auth';
import {clientPortalDb, clientPortalAuth} from "@/firebase/init";
import ClientDataService from "@/services/ClientDataService";
import SessionDataService from "@/services/SessionDataService";
import ExercisesDataService from "@/services/ExercisesDataService";
import TargetDataService from "@/services/TargetDataService";
import InvoicingDataService from "@/services/InvoicingDataService";
import store from '@/store'
import MailService from "@/services/MailService";

async function LoginToClientPortal() {
    await signInWithEmailAndPassword(clientPortalAuth, 'portal@nora-software.nl', process.env.VUE_APP_PORTAL_PASSWORD)
}

class ClientPortalDataService {
    async CreateClientPortalUser(email, clientId) {
        const password = this.generatePassword(24)
        console.log(password)

        await createUserWithEmailAndPassword(clientPortalAuth, email, password)
            .catch((error) => {
                console.log(error.code, error.message)
            })
            .finally(() => {
                console.log('user created')
            })

        await setDoc(doc(clientPortalDb,  'users/', email), {})
        await setDoc(doc(clientPortalDb,  'users/' + email + '/clients', clientId), {
            client_id: clientId,
            customer_id: 'testje',
        })
    }
    async CreateInvitation(clientId) {
        const client = await ClientDataService.GetClient(clientId)

        const colRef = collection(clientPortalDb, 'invitations')

        const dataObj = {
            client_id: clientId,
            created: new Date(),
            customer_id: store.state.customer,
            email: client.email,
            employee_id: store.state.currentEmployeeId,
            employee_name: '',
            registration_code: this.generateRegistrationCode(),
            status: 'invited',
        }

        await addDoc(colRef, dataObj)
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                MailService.SendClientPortalRegistrationMail(dataObj.registration_code, client)
            })
    }
    async GetAllInvitations() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                await LoginToClientPortal()

                const invitationsRef = collection(clientPortalDb, '/invitations')
                const invitationsQuery = query(invitationsRef)

                let invitations = []
                let data = await getDocs(invitationsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    invitations.push(doc.data())
                })
                resolve(invitations)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClientInvitation(clientId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                await LoginToClientPortal()

                const invitationsRef = collection(clientPortalDb, '/invitations')
                const invitationsQuery = query(invitationsRef, where('client_id', '==', clientId), orderBy('created', 'desc'))

                let invitations = []
                let data = await getDocs(invitationsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    invitations.push(doc.data())
                })
                resolve(invitations[0])

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async StoreClientPortalData(clientId, dossierId) {

        const docRef = doc(clientPortalDb, 'clients/' + clientId + '/dossiers/' + dossierId)

        // Client data
        const client = await ClientDataService.GetClient(clientId)

        //await this.CreateClientPortalUser(client.email, clientId)
        //await this.CreateInvitation(clientId)

        const clientObj = {
            last_name: client.last_name,
            first_name: client.first_name,
        }

        // Client portal settings
        const clientPortalSettings = await ClientDataService.GetClientPortalSettingsData(clientId)

        // Session data
        let dossierSessions = []
        if (clientPortalSettings.showAppointments) {
            const sessions = await SessionDataService.GetSessionsFromDossier(dossierId)

            sessions.forEach((session) => {
                const sessionObj = {
                    start_date: session.startDate,
                    end_date: session.endDate,
                    product_description: session.product_description,
                    location_description: session.location_description || 'Onbekend',
                    employee_name: session.employee_name,
                    financial_status: session.financial_status,
                }
                dossierSessions.push(sessionObj)
            })
        }

        // Exercise data
        let dossierExercises = []
        if (clientPortalSettings.showExercises) {
            const exercises = await ExercisesDataService.GetExercisesFromClient(clientId)

            for (const exercise of exercises) {
                const exerciseObj = {
                    description: exercise.exercise_description,
                    type: exercise.exercise_type,
                    content: exercise.exercise_content || '',
                    series: exercise.series,
                    repetitions: exercise.repetitions,
                    exercise: await ExercisesDataService.GetExercise(exercise.exercise_id),
                }
                dossierExercises.push(exerciseObj)
            }
        }

        // Targets
        let dossierTargets = []
        if (clientPortalSettings.showDossier) {
            const targets = await TargetDataService.GetTargetsFromDossier(dossierId)

            targets.forEach((target) => {
                dossierTargets.push(target)
            })
        }

        // Invoices
        let clientInvoices = []
        if (clientPortalSettings.showInvoices) {
            const invoices = await InvoicingDataService.GetInvoicesForClient(clientId)

            invoices.forEach((invoice) => {
                clientInvoices.push(invoice)
            })
        }

        const dataObj = {
            client: clientObj,
            client_id: clientId,
            clientPortalSettings: clientPortalSettings,
            sessions: dossierSessions,
            exercises: dossierExercises,
            targets: dossierTargets,
            invoices: clientInvoices,
            employee_id: store.state.currentEmployeeId,
        }

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            })
    }
    async GetClientAlerts(employeeId, clientId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                await LoginToClientPortal()

                const alertsRef = collection(clientPortalDb, '/employees/' + employeeId + '/alerts')
                const alertsQuery = query(alertsRef, where('client_id', '==', clientId), where('status', 'in', ['open']))

                let alerts = []
                let data = await getDocs(alertsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    alerts.push(doc.data())
                })
                resolve(alerts)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetEmployeeAlerts(employeeId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                await LoginToClientPortal()

                const alertsRef = collection(clientPortalDb, '/employees/' + employeeId + '/alerts')
                const alertsQuery = query(alertsRef, where('status', 'in', ['open', 'done']))

                let alerts = []
                let data = await getDocs(alertsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    alerts.push(doc.data())
                })
                resolve(alerts)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClientPortalMessages(clientId, dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                await LoginToClientPortal()

                const messagesRef = collection(clientPortalDb, '/clients/' + clientId + '/dossiers/' + dossierId + '/messages')
                const messagesQuery = query(messagesRef, orderBy('created'))

                let messages = []
                let data = await getDocs(messagesQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    messages.push(doc.data())
                })
                resolve(messages)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async AddClientPortalMessage(clientId, dossierId, messageText) {
        const colRef = collection(clientPortalDb, 'clients/' + clientId + '/dossiers/' + dossierId + '/messages')

        const dataObj = {
            text: messageText,
            sender: store.state.currentEmployeeId,
            sender_name: 'Arnold',
            type: 'therapist',
            created: new Date(),
            read: false,
        }
        await addDoc(colRef, dataObj)
            .catch((error) => {
                console.log(error)
            })
    }
    async AddClinimetricsToClientPortal(clientId, dossierId, dataObj) {
        const colRef = collection(clientPortalDb, 'clients/' + clientId + '/dossiers/' + dossierId + '/requests')

        // check of clinimetrics is already send otherwise send it
        const requestsQuery = query(colRef, where('clinimetrics_id', '==', dataObj.clinimetrics_id), where('t_moment', '==', dataObj.t_moment))
        let data = await getDocs(requestsQuery)
        let exists = false
        data.forEach(() => {
            exists = true
        })

        if (exists === false) {
            await addDoc(colRef, dataObj)
                .finally(() => {
                    console.log('request send to client portal')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    async GetClientExerciseComments(clientId, dossierId, exerciseId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let comments = []

                if (clientId === '' || dossierId === '' || exerciseId === '') {
                    resolve(comments)
                }

                await LoginToClientPortal()

                const commentsRef = collection(clientPortalDb, '/clients/' + clientId + '/dossiers/' + dossierId + '/exercises/' + exerciseId + '/comments')
                const commentsQuery = query(commentsRef)

                let data = await getDocs(commentsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    comments.push(doc.data())
                })
                resolve(comments)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async AddExerciseToClientPortal(clientId, dossierId, dataObj) {
        const docRef = doc(clientPortalDb,'clients/' + clientId + '/dossiers/' + dossierId + '/exercises/', dataObj.id)

        await setDoc(docRef, dataObj, { merge: true, })
            .finally(() => {
                console.log('exercise send to client portal')
            })
            .catch((error) => {
                console.log(error)
            })
    }
    async RemoveExerciseFromClientPortal(clientId, dossierId, exerciseId) {
        const docRef = doc(clientPortalDb, 'clients/' + clientId + '/dossiers/' + dossierId + '/exercises/', exerciseId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    generatePassword (strengthLevel) {
        let charactersArray = 'a-z'.split(',')
        let characterSet = ''
        let password = ''
        let size = 8

        switch (strengthLevel) {
            case 12:
                size = 10
                charactersArray = 'a-z,A-Z'.split(',')
                break
            case 24:
                size = 12
                charactersArray = 'a-z,A-Z,0-9'.split(',')
                break
            case 36:
                size = 14
                charactersArray = 'a-z,A-Z,0-9,#'.split(',')
                break
            case 48:
                size = 16
                charactersArray = 'a-z,A-Z,0-9,#'.split(',')
                break
        }
        if (charactersArray.indexOf('a-z') >= 0) {
            characterSet += 'abcdefghijklmnopqrstuvwxyz'
        }
        if (charactersArray.indexOf('A-Z') >= 0) {
            characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        }

        if (charactersArray.indexOf('0-9') >= 0) {
            characterSet += '0123456789'
        }
        if (charactersArray.indexOf('#') >= 0) {
            characterSet += '![]{}()%&*$#^<>~@|'
        }
        for (let i = 0; i < size; i++) {
            password += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
        }

        return password
    }
    generateRegistrationCode() {
        let characterSet = '123456789'
        let registrationCode = ''
        let size = 6

        for (let i = 0; i < size; i++) {
            registrationCode += characterSet.charAt(Math.floor(Math.random() * characterSet.length))
        }

        console.log(registrationCode)
        return registrationCode
    }
}

export default new ClientPortalDataService()