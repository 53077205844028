// ./services/ProductDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class ProductDataService {
    async AddProduct(dataObj) {
        const colRef = collection(db, store.state.customer + 'products');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Product added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async AddRate(productId, dataObj) {
        const colRef = collection(db, store.state.customer + 'products/' + productId + '/rates');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Product rate added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateProduct(productId, dataObj) {
        const docRef = doc(db, store.state.customer + 'products', productId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Product updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveProduct(productId) {
        console.log('id to be removed: ' + productId)
        const docRef = doc(db, store.state.customer + 'products', productId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Product deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async RemoveRate(productId, rateId) {
        console.log('id to be removed: ' + rateId)
        const docRef = doc(db, store.state.customer + 'products/' +  productId + '/rates', rateId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Rate deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetProduct(productId) {
        if (productId === '') {
            return []
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'products', productId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetProductRates(productId) {
        if (productId === '') {
            return []
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const ratesRef = collection(db, store.state.customer + 'products/' + productId + '/rates')
                const ratesQuery = query(ratesRef, orderBy('start_date', 'desc'))
                const snapShot = await getDocs(ratesQuery)

                let rates = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    rates.push(data)
                })

                resolve(rates)
            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetProducts(productType) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const productRef = collection(db, store.state.customer + 'products', )
                let productQuery = null
                if (productType === '') {
                    productQuery = query(productRef, orderBy('product_category'), orderBy('description'))
                } else {
                    productQuery = query(productRef, where('product_type', '==', productType), orderBy('product_category'), orderBy('description'))
                }
                const snapShot = await getDocs(productQuery);

                let products = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    products.push(data)
                })

                resolve(products.sort())

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new ProductDataService();