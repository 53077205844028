const ics = require('ics')

class IcsService {
    createIcsFile(session, institute, settings) {
        let alarms = []

        if (settings.alarm_1) {
            alarms.push({
                action: 'display',
                description: session.product_description,
                trigger: {
                    hours: settings.alarm_1_setting ? settings.alarm_1_setting : 1,
                    minutes: 0,
                    before: true,
                },
                repeat: 2,
                // attachType: 'VALUE=URI',
                // attach: 'Glass',
            })
        }
        if (settings.alarm_2) {
            alarms.push({
                action: 'display',
                description: session.product_description,
                trigger: {
                    hours: settings.alarm_2_setting ? settings.alarm_2_setting : 24,
                    minutes: 0,
                    before: true,
                },
                repeat: 2,
                attachType: 'VALUE=URI',
                attach: 'Glass',
            })
        }



        const event = {
            start: Math.floor(new Date(session.textStartDatum).getTime()),
            // duration: { hours: 6, minutes: 30 },
            // end: session.endDate.seconds * 1000,
            end: Math.floor(new Date(session.textEindDatum).getTime()),
            title: session.product_description,
            description: '',
            location: '',
            status: 'CONFIRMED',
            busyStatus: 'BUSY',
            organizer: { name: session.employee_name, email: institute.email },
            productId: 'Nora Software',
            alarms: alarms,
        }


        return ics.createEvent(event, (error, value) => {
            if (error) {
                console.log(error)
                return
            }
            return value
        })
    }
}

export default new IcsService()