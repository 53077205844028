import { reactive } from 'vue'
import ProductInvoicingDataService from "@/services/ProductInvoicingDataService";
import store from "@/store"

const state = reactive({
    active: false,
    clientId: '',
    productInvoicing: null,
})

// -----------------------------------
// Private Methods
// -----------------------------------
let close // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = () => {
    state.active = true
    return dialogPromise()
}
const reset = () => {
    state.active = false
}

// -----------------------------------
// Public interface
// -----------------------------------

const clientBasketModal = {
    get state() {
        return state
    },
    async show(clientId) {
        store.dispatch('ClientsStore/getEmployees')

        state.clientId = clientId
        state.productInvoicing = await ProductInvoicingDataService.GetProductInvoicingFromClient(clientId)
        return open()
    },
    cancel() {
        close(false)
        reset()
    },
}

export default clientBasketModal