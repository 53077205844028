import DossierDataService from "@/services/DossierDataService";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "@/firebase/init";
import TargetDataService from "@/services/TargetDataService";
import TimelineDataService from "@/services/TimelineDataService";
import DossierMessagesDataService from "@/services/DossierMessagesDataService";
import InvoicingDataService from "@/services/InvoicingDataService";
import OnlineRequestsDataService from "@/services/OnlineRequestsDataService";
import ExercisesDataService from "@/services/ExercisesDataService";
import ClientPortalDataService from "@/firebase/ClientPortalDataService";

class StoreDataService {
    async SetDossierId(state, id) {
        state.dossierId = id;

        // Dossier vullen
        if (state.dossierId === '') {
            state.dossier = {
                dossier_type: '',
                authentication: true,
            }
        } else {
            state.dossier = await DossierDataService.GetDossier(id)
        }

        // Authentication
        state.dossier.authentication = (state.dossier.employee_id === '' || state.dossier.employee_id === state.currentEmployeeId || state.dossier.employee_id === undefined)

        // Sessies bij de indicatie zoeken
        await this.getSessions(state, id)

        // Dagen sinds eerste sessie
        if (state.sessions.length > 0) {
            const date1 = new Date(state.sessions[state.sessions.length - 1].startDate.seconds * 1000)
            const date2 = new Date()

            const differenceInTime = date2.getTime() - date1.getTime()
            const differenceInDays = differenceInTime / (1000 * 3600 * 24)

            state.dossier.treatmentDays = Math.floor(differenceInDays / 7)
        } else {
            state.dossier.treatmentDays = 0
        }

        // Targets bij de indicatie zoeken
        await this.getTargets(state, id)

        // Reports
        await this.getReports(state, id)

        // Dingen te doen zoeken
        state.todos = [];

        const todoQuery = await getDocs(query(collection(db, state.customer + 'todos'), where('dossier_id', '==', state.dossierId)));
        todoQuery.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;

            state.todos.push(data);
        })

        // Timeline
        state.timeline = []
        const items = await TimelineDataService.GetTimelineItems(state.dossierId)
        items.forEach((item) => {
            item.dateText = new Date(item.date.seconds * 1000).toLocaleDateString()

            state.timeline.push(item)
        })

        // Online requests
        await OnlineRequestsDataService.GetOnlineRequestsFromDossier(state.dossierId)

        // Dossier messages
        state.dossierMessages = []
        const dossierMessages = await DossierMessagesDataService.GetDossierMessages(state.dossierId)
        dossierMessages.forEach((item) => {
            state.dossierMessages.push(item)
        })

        // Exercises
        state.exercises = await ExercisesDataService.GetExercisesFromClient(state.patientId)

        // Client portal alerts
        state.clientPortalAlerts = await ClientPortalDataService.GetClientAlerts(state.currentEmployeeId, state.patientId)
    }
    async getSessions(state, dossierId) {
        state.sessions = [];
        const sessionQuery = await getDocs(query(collection(db, state.customer + 'sessions'), where('dossier_id', '==', dossierId), orderBy('startDate', 'desc')));
        sessionQuery.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;

            if (data.startDate !== null) {
                data.textDatum = new Date(data.startDate.seconds * 1000).toLocaleDateString()
                data.textTijd = new Date(data.startDate.seconds * 1000).toLocaleTimeString().slice(0, 5) + ' - ' + new Date(data.endDate.seconds * 1000).toLocaleTimeString().slice(0, 5)
            }

            data.planned = data.startDate.seconds > (Date.now() / 1000);

            state.sessions.push(data);
        })
    }
    async getTargets(state, dossierId) {
        state.targets = [];
        const targetQuery = await getDocs(query(collection(db, state.customer + 'targets'), where('dossier_id', '==', dossierId), orderBy('main_goal', 'desc')));
        targetQuery.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id
            data.goal_reached = (Number(data.score) >= Number(data.target_score))
            data.history = []

            TargetDataService.GetTargetHistory(doc.id).then((result) => {
                result.forEach((doc) => {
                    data.history.push(doc)
                })
            })

            state.targets.push(data)

            if (data.main_goal) {
                state.target = data
                //targetOverviewModal.state.currentChartId = data.id
                state.mainTargetId = data.id
            }
        })

    }
    async getReports(state, dossierId) {
        state.reports = [];
        const reportQuery = await getDocs(query(collection(db, state.customer + 'reports'), where('dossier_id', '==', dossierId), orderBy('report_date', 'desc')));
        reportQuery.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;

            state.reports.push(data);
        })
    }
    async getProductInvoicing(state, patientId) {
        state.productInvoicing = []
        state.financial.sessionsNotInvoiced = 0
        state.financial.sessionsInvoiced = 0
        state.financial.sessionsNotPaid = 0
        state.financial.sessionsPaid = 0
        state.financial.amountNotInvoiced = 0
        state.financial.amountInvoiced = 0
        state.financial.amountNotPaid = 0
        state.financial.amountPaid = 0
        state.financial.amountExpired = 0
        state.financial.productsNotInvoiced = 0

        const productInvoicingQuery = await getDocs(query(collection(db, state.customer + 'product_invoicing'), where('patient_id', '==', patientId), orderBy('creation_date')));
        productInvoicingQuery.forEach((doc) => {
            const data = doc.data()

                data.id = doc.id
                data.incomplete = []

                if (data.financial_status === 'invoiced') {
                    state.financial.sessionsInvoiced++
                    state.financial.amountInvoiced += data.amount_incl_vat
                } else {
                    state.financial.sessionsNotInvoiced++
                    state.financial.amountNotInvoiced += data.amount_incl_vat
                }
                if (data.payment_status === 'paid') {
                    state.financial.sessionsPaid++
                    state.financial.amountPaid += data.amount_incl_vat
                }
                if (data.product_type === 'product' && data.financial_status === 'not_invoiced') {
                    state.financial.productsNotInvoiced++
                }
                //if (data.financial_status === 'invoiced' && data.payment_status !== 'paid') {
                //    state.financial.sessionsNotPaid++
                //    state.financial.amountNotPaid += data.amount_incl_vat
                //}

                if (data.postponed === true) {
                    data.financial_status = 'postponed'
                }

                state.productInvoicing.push(data)


        })

        const clientInvoices = await InvoicingDataService.GetInvoicesForClient(patientId)
        clientInvoices.forEach((invoice) => {
            if (this.daysBetween(invoice.invoice_date) && (invoice.payment_status === 'not_paid')) {
                if (invoice.total_amount_incl_vat) {
                    state.financial.amountExpired += invoice.total_amount_incl_vat
                }
            } else if (invoice.payment_status === 'not_paid') {
                state.financial.amountNotPaid += invoice.total_amount_incl_vat
            }
        })
    }
    daysBetween(invoiceDate) {
        const timeDifference = new Date().getTime() - new Date(invoiceDate).getTime()
        const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        return (dayDifference > 7)
    }
}

export default new StoreDataService()