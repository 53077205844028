// ./services/ProductDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class AppointmentTypeDataService {
    async AddAppointmentType(dataObj) {
        const colRef = collection(db, store.state.customer + 'appointment_types');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateAppointmentType(appointmentTypeId, dataObj) {
        const docRef = doc(db, store.state.customer + 'appointment_types', appointmentTypeId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveAppointmentType(appointmentTypeId) {
        const docRef = doc(db, store.state.customer + 'appointment_types', appointmentTypeId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetAppointmentType(appointmentTypeId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'appointment_types', appointmentTypeId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetAppointmentTypes() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const appointmentTypeRef = collection(db, store.state.customer + 'appointment_types', )
                const appointmentTypeQuery = query(appointmentTypeRef, orderBy('description'))
                const snapShot = await getDocs(appointmentTypeQuery);

                let appointmentTypes = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    appointmentTypes.push(data)
                })

                resolve(appointmentTypes)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new AppointmentTypeDataService();