// ./services/ExercisesDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import ClientPortalDataService from "@/firebase/ClientPortalDataService";

class ExercisesDataService {
    async AddExercise(dataObj) {
        const colRef = collection(db, store.state.customer + 'exercises');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateExercise(exerciseId, dataObj) {
        const docRef = doc(db, store.state.customer + 'exercises', exerciseId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveExercise(exerciseId) {
        const docRef = doc(db, store.state.customer + 'exercises', exerciseId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetExercise(exerciseId) {
        if (exerciseId === '') {
            return null
        }

        if (exerciseId === 'free') {
            return {
                id: 'free',
                description: 'Vrije oefening',
                content: '',
                exercise_link: '',
                exercise_type: 'free',
            }
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'exercises', exerciseId))
                let data = docSnap.data()
                data.id = exerciseId

                resolve(data)
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetExercises() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const exercisesRef = collection(db, store.state.customer + 'exercises', )
                const exercisesQuery = query(exercisesRef, orderBy('description'))
                const snapShot = await getDocs(exercisesQuery);

                let exercises = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    exercises.push(data)
                })

                exercises.push({
                    id: 'free',
                    description: 'Vrije oefening',
                    content: '',
                    exercise_link: '',
                    exercise_type: 'free',
                })

                resolve(exercises)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetExercisesFromClient(clientId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function(resolve, reject) {
            try {
                const clientPortalAlerts = await ClientPortalDataService.GetClientAlerts(store.state.currentEmployeeId, clientId)

                const clientExercisesRef = collection(db, store.state.customer + 'client_exercises')
                const clientExercisesQuery = query(clientExercisesRef,
                    where('client_id', '==', clientId))
                const snapShot = await getDocs(clientExercisesQuery)

                let clientExercises = []
                snapShot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id

                    // Commentaar checken
                    let hasComment = false
                    let dossierId = ''
                    clientPortalAlerts.forEach((alert) => {
                        if (alert.exercise_id === data.id) {
                            hasComment = true
                            dossierId = alert.dossier_id
                        }
                    })
                    data.hasComment = hasComment
                    data.dossierId = dossierId

                    clientExercises.push(data)
                })

                resolve(clientExercises)
            } catch(e) {
                reject(e)
            }
        })
    }
}

export default new ExercisesDataService();