const questionnaire_psg_v1 = {
    'id': 'PSG_v1',
    'description': 'Patiënt Specifieke Goal-setting methode',
    'short': 'PSG',
    'questions': [
        {
            'number': 1,
            'info': 'Vul de activiteiten in die u belangrijk vind, maar moeilijk vind om uit te voeren vanwege uw klachten. Geef per activiteit aan met de schuifbalk hoe goed u deze activiteit kan uitvoeren.',
            'description': 'Activiteit 1',
            'question_type': 'slider_min_max_with_text',
            'min_value': 0,
            'min_description': 'Onmogelijk',
            'max_value': 10,
            'max_description': 'Geen enkele moeite',
            'score': 0,
            'text': '',
            'required': true,
        },
        {
            'number': 2,
            'info': '',
            'description': 'Activiteit 2',
            'question_type': 'slider_min_max_with_text',
            'min_value': 0,
            'min_description': 'Onmogelijk',
            'max_value': 10,
            'max_description': 'Geen enkele moeite',
            'score': '0',
            'text': '',
            'required': false,
        },
        {
            'number': 3,
            'info': '',
            'description': 'Activiteit 3',
            'question_type': 'slider_min_max_with_text',
            'min_value': 0,
            'min_description': 'Onmogelijk',
            'max_value': 10,
            'max_description': 'Geen enkele moeite',
            'score': '0',
            'text': '',
            'required': false,
        },
        {
            'number': 4,
            'info': '',
            'description': 'Activiteit 4',
            'question_type': 'slider_min_max_with_text',
            'min_value': 0,
            'min_description': 'Onmogelijk',
            'max_value': 10,
            'max_description': 'Geen enkele moeite',
            'score': '0',
            'text': '',
            'required': false,
        },
        {
            'number': 5,
            'info': '',
            'description': 'Activiteit 5',
            'question_type': 'slider_min_max_with_text',
            'min_value': 0,
            'min_description': 'Onmogelijk',
            'max_value': 10,
            'max_description': 'Geen enkele moeite',
            'score': '0',
            'text': '',
            'required': false,
        },
    ],
    'scores': [
        {
            'description': 'Score activiteit 1',
            'formule': 'S1',
            'min': 0,
            'max': 10,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Activiteiten',
            'goal': 'Verbeteren van de activiteit "{{ T1 }}"',
            'operation': '',
            'goalscore': 0,
        },
        {
            'description': 'Score activiteit 2',
            'formule': 'S2',
            'min': 0,
            'max': 10,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Activiteiten',
            'goal': 'Verbeteren van de activiteit "{{ T2 }}"',
            'operation': '',
            'goalscore': 0,
        },
        {
            'description': 'Score activiteit 3',
            'formule': 'S3',
            'min': 0,
            'max': 10,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Activiteiten',
            'goal': 'Verbeteren van de activiteit "{{ T3 }}"',
            'operation': '',
            'goalscore': 0,
        },
        {
            'description': 'Score activiteit 4',
            'formule': 'S4',
            'min': 0,
            'max': 10,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Activiteiten',
            'goal': 'Verbeteren van de activiteit "{{ T4 }}"',
            'operation': '',
            'goalscore': 0,
        },
        {
            'description': 'Score activiteit 5',
            'formule': 'S5',
            'min': 0,
            'max': 10,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Activiteiten',
            'goal': 'Verbeteren van de activiteit "{{ T5 }}"',
            'operation': '',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_psg_v1