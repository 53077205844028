const permission_hapto_v1 = {
    'id': 'permission_hapto_v1',
    'description': 'Toestemmingen haptotherapie',
    'short': '',
    'online': true,
    'questions': [
        {
            'number': 1,
            'info': 'Selecteer de toestemmingen waarmee u akkoord gaat.',
            'name': 'permissionGetDossierData',
            'description': 'De cliënt gaat na mondeling overleg akkoord dat eventueel informatie bij de (huis)arts, behandeld specialist of therapeut wordt ingewonnen.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 2,
            'info': '',
            'name': 'permissionSendDossierData',
            'description': 'De cliënt gaat ermee akkoord dat gedurende de behandeling of achteraf verslag wordt gedaan aan de (huis)arts, behandelend specialist of therapeut.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 3,
            'info': '',
            'name': 'permissionTermsOfPayment',
            'description': 'De cliënt is op de hoogte van het tarief voor de behandeling en de betalingsvoorwaarden.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 4,
            'info': '',
            'name': 'permissionMethodOfTreatment',
            'description': 'De cliënt is geïnformeerd over de behandelwijze en is hiermee akkoord.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 5,
            'info': '',
            'name': 'permissionGoalOfTreatment',
            'description': 'De cliënt en de therapeut hebben overeenstemming over het doel van de behandeling en de cliënt is hiermee akkoord.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 6,
            'info': '',
            'name': 'permissionTreatment',
            'description': 'De cliënt geeft toestemming voor de behandeling.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 7,
            'info': '',
            'name': 'permissionProfessionCode',
            'description': 'De cliënt is op de hoogte van de beroepscode waar de therapeut aan is gehouden.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 8,
            'info': '',
            'name': 'permissionComplaintsPolicy',
            'description': 'De cliënt is op de hoogte van de klachtenprocedure.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 9,
            'info': '',
            'name': 'permissionAccountableForPolicy',
            'description': 'De therapeut is aanspreekbaar op de behandelwijze en het gevoerde beleid.',
            'question_type': 'permission',
            'score': false,
            'required': true,
        },
        {
            'number': 10,
            'info': '',
            'name': 'signatureDate',
            'description': 'Datum',
            'question_type': 'date',
            'score': new Date().toLocaleString().substr(0,10),
            'required': true,
        },
        {
            'number': 11,
            'info': '',
            'name': 'signaturePlace',
            'description': 'Plaats',
            'question_type': 'text',
            'score': '',
            'required': true,
        },
    ],
    'scores': [

    ],
}

export default permission_hapto_v1