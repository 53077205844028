// ./services/ClientComplaintDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

function GetComplaintTypeDescription(complaintType) {
    switch(complaintType) {
        case 'ct_01': return '(Niet) professioneel handelen'
        case 'ct_02': return 'Het niet eens zijn met een behandeling'
        case 'ct_03': return 'Het missen van een diagnose'
        case 'ct_04': return 'Veiligheid'
        case 'ct_05': return 'Onveilige situaties'
        case 'ct_06': return 'Klachten over een medisch hulpmiddel'
        case 'ct_07': return 'Slechte communicatie'
        case 'ct_08': return 'Klachten over inzage, of het bijhouden van een medisch dossier'
        case 'ct_09': return 'Slechte voorlichting, of informatie over een behandeling'
        case 'ct_99': return 'Anders'
        default: return ''
    }
}

class ClientComplaintsDataService {
    async AddClientComplaint(dataObj) {
        let complaintId = ''
        const colRef = collection(db, store.state.customer + 'client_complaints')

        await addDoc(colRef, dataObj)
            .then((docRef) => {
                complaintId = docRef.id
            })
            .catch(e => {
                console.log(e)
                return null
            })

        return complaintId
    }
    async UpdateClientComplaint(clientComplaintId, dataObj) {
        const docRef = doc(db, store.state.customer + 'client_complaints', clientComplaintId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Client complaint updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveClientComplaint(clientComplaintId) {
        console.log('id to be removed: ' + clientComplaintId)
        const docRef = doc(db, store.state.customer + 'client_complaints', clientComplaintId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Client complaint deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetClientComplaint(clientComplaintId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'client_complaints', clientComplaintId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClientComplaints(employeeId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const clientComplaintsRef = collection(db, store.state.customer + 'client_complaints', )
                let clientComplaintsQuery = null
                if (employeeId === '') {
                    clientComplaintsQuery = query(clientComplaintsRef, orderBy('complaint_date'))
                } else {
                    clientComplaintsQuery = query(clientComplaintsRef, where('employee_id', '==', employeeId), orderBy('complaint_date'))
                }

                //const clientContactQuery = query(clientContactRef, orderBy('contact_date'))
                const snapShot = await getDocs(clientComplaintsQuery);

                let clientComplaints = []
                snapShot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    data.complaint_type_description = GetComplaintTypeDescription(data.complaint_type)

                    clientComplaints.push(data)
                })

                resolve(clientComplaints.sort())

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }

}

export default new ClientComplaintsDataService()