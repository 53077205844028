import { reactive } from 'vue'
import ClientDataService from "@/services/ClientDataService";

const state = reactive({
    active: false,
    id: '',
    client: null,
})

// -----------------------------------
// Private Methods
// -----------------------------------
let close // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = () => {
    state.active = true
    return dialogPromise()
}
const reset = () => {
    state.active = false
}

// -----------------------------------
// Public interface
// -----------------------------------

const clientModal = {
    get state() {
        return state
    },
    async show(id) {
        state.id = id
        if (id !== '') {
            state.client = await ClientDataService.GetClient(id)
        } else {
            state.client = {
                last_name: '',
                first_name: '',
                archive: false,
                email: '',
            }
        }
        return open()
    },
    cancel() {
        close(false)
        reset()
    },
}

export default clientModal