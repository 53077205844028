// ./services/ClientContactDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc, where,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import ClientStoreDataService from "@/services/ClientStoreDataService";

class ClientContactDataService {
    async AddClientContact(dataObj) {
        const colRef = collection(db, store.state.customer + 'client_contacts');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateClientContact(clientContactId, dataObj) {
        const docRef = doc(db, store.state.customer + 'client_contacts', clientContactId);

        await setDoc(docRef, dataObj, { merge: true })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveClientContact(clientContactId) {
        const docRef = doc(db, store.state.customer + 'client_contacts', clientContactId)

        await deleteDoc(docRef)
            .catch((e) => {
                console.log(e)
            });
    }
    async GetClientContact(clientContactId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'client_contacts', clientContactId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClientContacts(employeeId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const clientContactRef = collection(db, store.state.customer + 'client_contacts', )
                let clientContactQuery = query(clientContactRef, where('employee_id', '==', employeeId), orderBy('contact_date'))
                if (employeeId === '') {
                    clientContactQuery = query(clientContactRef, orderBy('contact_date'))
                }
                //const clientContactQuery = query(clientContactRef, orderBy('contact_date'))
                const snapShot = await getDocs(clientContactQuery);

                let clientContacts = []
                snapShot.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id

                    ClientStoreDataService.GetEmployeeDescription(data.employee_id).then((result) => {
                        data.employee_name = result
                    })

                    if (data.contact_type === 'call_back' && data.status === 'open' && new Date() > new Date(data.contact_date.seconds * 1000)) {
                        data.status = 'expired'
                    }

                    if (data.contact_type === 'register' && data.status === 'open') {
                        const dateNow = new Date()
                        const dateClientContact = new Date(data.contact_date.seconds * 1000)

                        if (Math.round((dateNow.getTime() - dateClientContact.getTime()) / (1000 * 3600 * 24)) > 7) {
                            data.status = 'expired'
                        }
                    }

                    clientContacts.push(data)
                })

                resolve(clientContacts.sort())

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new ClientContactDataService()