// ./services/TimelineDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    where,
    query,
    setDoc,
    orderBy
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class TimelineDataService {
    async GetTimelineItems(dossierId) {
        let timelineItems = []
        const timelineQuery = await getDocs(query(collection(db, store.state.customer + 'timeline'), where('dossier_id', '==', dossierId), orderBy('date', 'desc')));
        timelineQuery.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;

            timelineItems.push(data);
        })

        return timelineItems
    }
    async AddTimelineItem(dataObj) {
        const colRef = collection(db, store.state.customer + 'timeline');

        await addDoc(colRef, dataObj)
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateTimelineItem(timelineItemId, dataObj) {
        const docRef = doc(db, store.state.customer + 'timeline', timelineItemId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Timeline item updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveTimelineItem(timelineItemId) {
        const docRef = doc(db, store.state.customer + 'timeline', timelineItemId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Timeline item deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }

}

export default new TimelineDataService();