<template>
  <div v-if="productInvoicingModal.state.active" class="modal modal-lg show" id="productInvoicingModal" tabindex="-1" role="dialog" style="display:block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header gradient">
          <div class="row text-center text-secondary">
            <h5 class="modal-title text-center" id="clientModalLabel">
              <img src="@/assets/blue/product-24.png" alt="Session" class="img-fluid">
              {{ (productInvoicingId === '' ? 'Product toevoegen' : 'Product wijzigen') }} - <b>{{ client.first_name + ' ' + client.last_name }}</b>
            </h5>
          </div>
          <button type="button" class="btn-close" @click="closeModal()"></button>
        </div>
        <div class="modal-body overflow-y-auto" :style="isMobile ? '' : 'min-height: 420px; max-height: 420px;'">
          <div class="container">

            <div v-show="productInvoicingId === ''" class="row mt-3 text-secondary">
              <div :class="isMobile ? 'col-log-6' : 'col-lg'">
                <div class="row mb-3">
                  <label :class="isMobile ? 'col-4' : 'col-2'" class="col-form-label text-end">Product</label>
                  <div class="col">
                    <select class="form-select text-secondary" v-model="form.product_id" @change="onProductChange()">
                      <option :value="''"/>
                      <option v-for="(product, product_index) in products" :key="product_index" :value="product.id">
                        {{ product.description }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="productInvoicingId !== ''" class="row mt-3 text-secondary">
              <div :class="isMobile ? 'col-log-6' : 'col-lg'">
                <div class="row mb-3">
                  <label :class="isMobile ? 'col-4' : 'col-2'" class="col-form-label text-end">Product</label>
                  <div class="col">
                    <input type="text" class="form-control text-secondary" v-model="productInvoicing.product_description" disabled/>
                  </div>
                </div>
              </div>

            </div>

            <div class="row text-secondary">
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label class="col-4 col-form-label text-end">Aantal</label>
                  <div class="col-5">
                    <input v-show="productInvoicing.product_type === 'product' || productInvoicing.product_type === undefined" type="number" class="form-control text-secondary required" min="1" max="250" @change="onAmountChange()" v-model="form.amount"/>
                    <input v-show="productInvoicing.product_type === 'appointment'" type="number" class="form-control text-secondary required" min="1" max="250" @change="onAmountChange()" v-model="form.amount" disabled/>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <div class="row text-secondary">
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label for="inputAmountIncl" class="col-4 col-form-label text-end text-secondary">Bedrag inclusief</label>
                  <div class="col-8">
                    <div class="input-group">
                      <span class="input-group-text">€</span>
                      <input type="text" class="form-control text-secondary required" id="inputAmountIncl" placeholder="" v-on:keyup="onAmountInclChange()" v-model="form.amount_incl_vat"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label class="col-4 col-form-label text-end">BTW tarief</label>
                  <div class="col-8 mt-1 text-start">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input opacity-50" type="radio" name="inlineVatPercentage" id="inlineVatPercentage1" value="0" @change="onAmountInclChange()" v-model="form.vat_percentage" checked>
                      <label class="form-check-label" for="inlineVatPercentage1">0%</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input opacity-50" type="radio" name="inlineVatPercentage" id="inlineVatPercentage2" value="21" @change="onAmountInclChange()" v-model="form.vat_percentage">
                      <label class="form-check-label" for="inlineVatPercentage2">21%</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input opacity-50" type="radio" name="inlineVatPercentage" id="inlineVatPercentage3" value="9" @change="onAmountInclChange()" v-model="form.vat_percentage">
                      <label class="form-check-label" for="inlineVatPercentage3">9%</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row text-secondary">
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label for="inputAmountIncl" class="col-4 col-form-label text-end">Bedrag exclusief</label>
                  <div class="col-8">
                    <div class="input-group">
                      <span class="input-group-text">€</span>
                      <input type="text" class="form-control text-secondary required" id="inputAmountExcl" placeholder="" v-model="form.amount_excl_vat" disabled/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label for="inputAmountIncl" class="col-4 col-form-label text-end">Bedrag BTW</label>
                  <div class="col-8">
                    <div class="input-group">
                      <span class="input-group-text">€</span>
                      <input type="text" class="form-control text-secondary required" id="inputAmountVat" placeholder="" v-model="form.amount_vat" disabled/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row text-secondary">
              <div class="col-lg-6">
                <div class="row mb-3">
                  <div class="col-4"></div>
                  <div class="col-8 text-start">
                    <input type="checkbox" class="form-check-input me-2 opacity-50"  id="postponed" v-model="form.postponed" checked>
                    <label class="form-check-label text-start" for="postponed">
                      Facturatie uitstellen
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <hr>

            <div class="row text-secondary">
              <div class="col-lg-6">
                <div class="row mb-3">
                  <label class="col-4 col-form-label text-end" :for="form.employee_id">Medewerker</label>
                  <div class="col-8">
                    <select class="form-select text-secondary" v-model="form.employee_id">
                      <option :value="''"/>
                      <option v-for="(employee, employee_index) in employees" :key="employee_index" :value="employee.id">
                        {{ employee.first_name + ' ' + employee.last_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <label class="col-4 col-form-label text-end" :for="form.location_id">Locatie</label>
                  <div class="col-8">
                    <select class="form-select text-secondary" v-model="form.location_id">
                      <option :value="''"/>
                      <option v-for="(location, employee_index) in locations" :key="employee_index" :value="location.id">
                        {{ location.description }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>


        <div class="modal-footer bg-light pt-1 pb-1">
          <button @click="save()" class="btn btn-success opacity-75 align-middle">
            <i class="bi bi-check-circle me-2" style="font-size: 1rem; color: whitesmoke"></i>
            Opslaan
          </button>
          <button type="button" class="btn btn-secondary opacity-75" @click="closeModal()">
            <i class="bi bi-x-circle me-2" style="font-size: 1rem; color: whitesmoke"></i>
            Sluiten
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-show="productInvoicingModal.state.active" class="dialog-bg"></div>

</template>

<script>
import productInvoicingModal from "@/services/modals/ProductInvoicingModalService";
import ProductDataService from "@/services/ProductDataService";
import ProductInvoicingDataService from "@/services/ProductInvoicingDataService";

export default {
  name: "ProductInvoicingModal",
  data() {
    return {
      form: {
        amount_excl_vat: 0,
        amount_incl_vat: 0,
        amount_vat: 0,
        amount: 1,
        product_description: '',
        product_id: '',
        postponed: false,
        vat_percentage: 0,
        employee_id: '',
        location_id: '',
      },
      selectedProduct: null,
      productInvoicingModal,
    };
  },
  methods: {
    closeModal() {
      productInvoicingModal.state.id = 'x'
      productInvoicingModal.cancel()
    },
    async save() {

      if (this.productInvoicingId === '') {
        const productInvoicingObj = {
          amount: this.form.amount,
          amount_incl_vat: parseFloat(this.form.amount_incl_vat.replace(',', '.')) * 100,
          amount_excl_vat: parseFloat(this.form.amount_excl_vat.replace(',', '.')) * 100,
          amount_vat: parseFloat(this.form.amount_vat.replace(',', '.')) * 100,
          creation_date: new Date(Date.now()),
          employee_id: this.form.employee_id,
          location_id: this.form.location_id,
          financial_status: 'not_invoiced',
          patient_id: this.clientId,
          patient_name: this.client.first_name + ' ' + this.client.last_name,
          postponed: false,
          product_description: this.selectedProduct.description,
          product_id: this.form.product_id,
          product_type: 'product',
          vat_percentage: this.form.vat_percentage,
        }
        await ProductInvoicingDataService.AddProductInvoicing(productInvoicingObj)

      } else {
        const updateObj = {
          postponed: this.form.postponed,
          amount: this.form.amount,
          amount_incl_vat: parseFloat(this.form.amount_incl_vat.replace(',', '.')) * 100,
          amount_excl_vat: parseFloat(this.form.amount_excl_vat.replace(',', '.')) * 100,
          amount_vat: parseFloat(this.form.amount_vat.replace(',', '.')) * 100,
          employee_id: this.form.employee_id,
          location_id: this.form.location_id,
          product_id: this.form.product_id,
          vat_percentage: this.form.vat_percentage,
        }
        await ProductInvoicingDataService.UpdateProductInvoicing(this.productInvoicingId, updateObj)
      }

      this.$store.state.invoicingUpdateTrigger = !this.$store.state.invoicingUpdateTrigger

      this.closeModal()
    },
    async setProductId() {
      //this.productId = id

      await ProductDataService.GetProduct(this.form.product_id).then((result) => {
        this.selectedProduct = result
      })
      await ProductDataService.GetProductRates(this.form.product_id).then((result) => {
        this.selectedProduct.rate = result[0]
      })

    },
    getBirthdate(field) {
      try {
        if (field != null) {
          return new Date(field).toLocaleDateString()
        } else {
          return ''
        }
      } catch {
        return ''
      }
    },
    async onProductChange() {
      await this.setProductId()

      this.form.amount = 1
      this.form.vat_percentage = this.selectedProduct.rate.vat_percentage
      this.onAmountChange()
    },
    onAmountChange() {
      const amountIncl = Number(this.selectedProduct.rate.amount_incl_vat) * Number(this.form.amount) / 100
      this.form.amount_incl_vat = parseFloat(amountIncl).toFixed(2).replace('.', ',')

      let amountExcl = 0
      let amountVat = 0
      const vatPercentage = Number(this.form.vat_percentage)

      amountExcl = amountIncl / (1 + (vatPercentage / 100))
      amountVat = amountIncl - amountExcl

      this.form.amount_excl_vat = parseFloat(String(amountExcl)).toFixed(2).replace('.',',')
      this.form.amount_vat = parseFloat(String(amountVat)).toFixed(2).replace('.',',')
    },
    onAmountInclChange() {
      const amountIncl = Number(this.form.amount_incl_vat.replace(',','.'))
      let amountExcl = 0
      let amountVat = 0
      const vatPercentage = Number(this.form.vat_percentage)

      amountExcl = amountIncl / (1 + (vatPercentage / 100))
      amountVat = amountIncl - amountExcl

      this.form.amount_excl_vat = parseFloat(String(amountExcl)).toFixed(2).replace('.',',')
      this.form.amount_vat = parseFloat(String(amountVat)).toFixed(2).replace('.',',')
    },
  },
  watch: {
    productInvoicingId() {
      if (this.productInvoicingId === '') {
        this.form.employee_id = this.$store.state.currentEmployeeId
      }
    },
    productInvoicing: function(productInvoicing) {
      //this.form = { ...productInvoicing }
      const formatter = new Intl.NumberFormat('nl', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })

      if (this.productInvoicingId !== '') {
        this.setProductId(this.form.product_id)
      }

      this.form.amount = productInvoicing.amount
      this.form.amount_incl_vat = formatter.format(productInvoicing.amount_incl_vat / 100)
      this.form.amount_excl_vat = formatter.format(productInvoicing.amount_excl_vat / 100)
      this.form.amount_vat = formatter.format(productInvoicing.amount_vat / 100)
      this.form.employee_id = productInvoicing.employee_id || this.$store.state.currentEmployeeId
      this.form.location_id = productInvoicing.location_id || ''
      this.form.product_id = productInvoicing.product_id || ''
      this.form.vat_percentage = productInvoicing.vat_percentage
      this.form.postponed = productInvoicing.postponed
    }
  },
  computed: {
    clientId() {
      return productInvoicingModal.state.patientId
    },
    client() {
      return productInvoicingModal.state.patient
    },
    productInvoicingId() {
      return productInvoicingModal.state.id
    },
    productInvoicing() {
      return productInvoicingModal.state.productInvoicing
    },
    employees() {
      return this.$store.state.ClientsStore.employees
    },
    locations() {
      return this.$store.state.ClientsStore.locations
    },
    products() {
      return this.$store.state.ClientsStore.products
    },
    isMobile() {
      return this.$store.state.mobileDevice
    },
  },
  mounted() {

  },
}
</script>

<style scoped>

</style>