//import PlannerDataService from "@/services/PlannerDataService";

export default {
    namespaced: true,
    state: {
        onlineEmployees: [],
        onlineProductCategories: [],
        onlineProducts: [],
    },
    mutations: {
        async GET_ONLINE_PRODUCT_CATEGORIES(state, customerId) {
            state.onlineProductCategories = []
            let resp = await fetch('/.netlify/functions/getProductCategories?customerId=' + customerId)
            const onlineProducts = (await resp.json())
            //const onlineProducts = await PlannerDataService.GetOnlineProductCategories(customerId)

            onlineProducts.forEach((product) => {
                const productCategoryIndex = state.onlineProductCategories.findIndex(e => e.description === product.product_category)
                if (productCategoryIndex === -1) {
                    state.onlineProductCategories.push({
                        description: product.product_category,
                    })
                }

                const productIndex = state.onlineProducts.findIndex(e => e.id === product.id)
                if (productIndex === -1) {
                    state.onlineProducts.push(product)
                }
            })
        },
        async GET_ONLINE_EMPLOYEES(state, customerId) {
            state.onlineEmployees = []
            let resp = await fetch('/.netlify/functions/getEmployees?customerId=' + customerId)
            const employees = (await resp.json())
            //const employees = await PlannerDataService.GetOnlineEmployees(customerId)

            employees.forEach((employee) => {
                const employeeIndex = state.onlineEmployees.findIndex(e => e.id === employee.id)
                if (employeeIndex === -1) {
                    state.onlineEmployees.push(employee)
                }
            })
        }
    },
    actions: {
        getOnlineProductCategories(context, customerId) {
            context.commit('GET_ONLINE_PRODUCT_CATEGORIES', customerId)
        },
        getOnlineEmployees(context, customerId) {
            context.commit('GET_ONLINE_EMPLOYEES', customerId)
        },
    },
}