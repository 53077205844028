// ./services/EmployeeDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class EmployeeDataService {
    async AddEmployee(dataObj) {
        const colRef = collection(db, store.state.customer + 'employees');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Employee added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateEmployee(employeeId, dataObj) {
        const docRef = doc(db, store.state.customer + 'employees', employeeId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Employee updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveEmployee(employeeId) {
        const docRef = doc(db, store.state.customer + 'employees', employeeId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Employee deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetEmployee(employeeId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'employees', employeeId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetEmployees() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const employeeRef = collection(db, store.state.customer + 'employees', )
                const employeeQuery = query(employeeRef, orderBy('last_name'), orderBy('first_name'))
                const snapShot = await getDocs(employeeQuery);

                let employees = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    employees.push(data)
                })

                resolve(employees)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async SetUserInterfaceSettings(employeeId) {
        const uiSettings = {
            showSaturday: store.state.mainScreen.calendar.showSaturday,
            showSunday: store.state.mainScreen.calendar.showSunday,
        }

        await this.UpdateEmployee(employeeId, uiSettings)
    }
    async GetUserInterfaceSettings(employeeId) {
        const employee = await this.GetEmployee(employeeId)

        store.state.mainScreen.calendar.showSaturday = (employee.showSaturday === undefined) ? true : employee.showSaturday
        store.state.mainScreen.calendar.showSunday = (employee.showSunday === undefined) ? true : employee.showSunday
    }
    async GetAllEmployeeSchedules() {
        const employeeSchedules = []
        const employees = await this.GetEmployees()

        employees.forEach((employee) => {
            employee.schedule.forEach((schedulePart) => {
                const startTime = new Date(1900, 1, 1, Number(schedulePart.startTime.slice(0, 2)), Number(schedulePart.startTime.slice(3,2)), 0, 0)
                const endTime = new Date(1900, 1, 1, Number(schedulePart.endTime.slice(0, 2)), Number(schedulePart.endTime.slice(3,2)), 0, 0)
                const diffMs = (endTime - startTime)
                const minutes = Math.round(diffMs / 60000)

                employeeSchedules.push({
                    employee_id: employee.id,
                    days_of_week: schedulePart.daysOfWeek,
                    minutes: minutes,
                })
            })
        })
        console.log(employeeSchedules)
        return employeeSchedules
    }
}

export default new EmployeeDataService();