// ./services/TasksDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc, getDoc, getDocs, orderBy, query,
    setDoc,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";

class TasksDataService {
    async AddTask(dataObj) {
        const colRef = collection(db, store.state.customer + 'tasks');

        await addDoc(colRef, dataObj)
            .then(() => {
                console.log('Task added successfully');
            })
            .catch(e => {
                console.log(e);
            });
    }
    async UpdateTask(taskId, dataObj) {
        const docRef = doc(db, store.state.customer + 'tasks', taskId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Task updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    async RemoveTask(taskId) {
        const docRef = doc(db, store.state.customer + 'tasks', taskId)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Task deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    async GetTask(taskId) {
        if (taskId === '') {
            return null
        }

        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'tasks', taskId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetTasks() {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const tasksRef = collection(db, store.state.customer + 'tasks', )
                const tasksQuery = query(tasksRef, orderBy('description'))
                const snapShot = await getDocs(tasksQuery);

                let tasks = []
                snapShot.forEach(doc => {
                    let data = doc.data()
                    data.id = doc.id

                    tasks.push(data)
                })

                resolve(tasks)

            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
}

export default new TasksDataService();