class NoraUtils {
    showAmount(amount) {
        return '€ ' + (amount / 100).toFixed(2).replace('.',',')
    }
    getColor(index) {
        switch(index) {
            case 0: return '#33608c'
            case 1: return '#9768a5'
            case 2: return '#e7718a'
            case 3: return '#f6ba57'
            case 4: return '#ed7846'
            case 5: return '#d54c45'
            case 6: return '#b81840'
            case 99: return 'rgba(141,141,141,0.64)'
            default: return '#2b5c8a'
        }
    }
    getStatusColor(status) {
        switch(status) {
            case 'open': return 'rgba(255, 115, 0, 0.5)'
            case 'expired': return 'rgba(243, 17, 17, 0.5)'
            case 'done': return 'rgba(48, 133, 57, 0.5)'
            case 'active': return 'rgba(48, 133, 57, 0.5)'
            case 'created': return 'rgba(18, 160, 215, 0.5)'
            case 'invited': return 'rgba(18, 160, 215, 0.5)'
            default: return '#2b5c8a'
        }
    }
    isValidEmail(email) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }
}

export default new NoraUtils()