import { createWebHistory, createRouter } from "vue-router";
import store from '@/store';

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("./components/auth/LoginScreen"),
        meta: {
            guest: true,
        }
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("./components/auth/ResetScreen.vue"),
        meta: {
            guest: true,
        }
    },
    {
        path: "/main",
        name: "main",
        component: () => import("./components/MainScreen"),
    },
    {
        path: "/construction",
        name: "construction",
        component: () => import("./components/ConstructionScreen"),
    },
    {
        path: "/financial",
        name: "financial",
        component: () => import("./components/FinancialScreen"),
    },
    {
        path: "/overview",
        name: "overview",
        component: () => import("./components/OverviewScreen"),
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("./components/SettingsScreen"),
    },
    {
        path: "/calendar",
        name: "calendar",
        component: () => import("./components/CalendarScreen"),
    },
    {
        path: "/report",
        name: "report",
        component: () => import("./components/ReportScreen"),
    },
    {
        path: "/tutorials",
        alias: "/tutorials",
        name: "tutorials",
        component: () => import("./components/TutorialsList"),
    },
    {
        path: "/add",
        name: "add",
        component: () => import("./components/AddTutorial"),
    },
    {
        path: "/patients",
        name: "patients",
        component: () => import("./components/PatientScreen"),
    },
    {
        path: "/patient_view",
        name: "patient_view",
        component: () => import("./components/patient/PatientView"),
    },
    {
        path: "/session",
        name: "session",
        component: () => import("./components/health/SessionForm"),
    },
    {
        path: "/patient_list_tile",
        name: "patient_list_tile",
        component: () => import("./components/patient/PatientListTile"),
    },
    {
        path: "/patient_tile",
        name: "patient_tile",
        component: () => import("./components/patient/PatientTile"),
    },
    {
        path: "/client_clinimetrics_screen",
        name: "client_clinimetrics_screen",
        component: () => import("./components/ClientClinimetricsScreen"),
        meta: {
            guest: true,
            hideNavbar: true,
        },
    },
    {
        path: "/employee_screen",
        name: "employee_screen",
        component: () => import("./components/EmployeeScreen"),
    },
    {
        path: "/employee_screen_mobile",
        name: "employee_screen_mobile",
        component: () => import("./components/EmployeeScreenMobile"),
    },
    {
        path: "/planner_screen",
        name: "planner_screen",
        component: () => import("./components/PlannerScreen"),
        meta: {
            guest: true,
            hideNavbar: true,
        },
    },
    {
        path: "/mail",
        name: "mail",
        component: () => import("./components/SecureMailScreen"),
        meta: {
            guest: true,
            hideNavbar: true,
        }
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.guest)) {
        return next();
    }

    if (store.state.currentEmployeeId) {
        return next();
    }

    return next({ name: 'login' });
});

export default router;
