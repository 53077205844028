<template>
  <button class="btn btn-primary opacity-75" @click.prevent="handleLogout()">Log out</button>
</template>

<script>

import { logout } from '@/services/Auth';

export default {
  name: 'LogoutButton',
  data() {
    return {}
  },
  methods: {
    async handleLogout() {
      await logout();

      window.location.href = process.env.VUE_APP_LOGOUT_URL;
    }
  },
}
</script>

<style scoped>

</style>
