<template>
  <div id="app">
    <body>



    <!-- Navigatie -->
    <nav v-if="isAuthenticated && !$route.meta.hideNavbar"
         class="navbar navbar-expand-lg sticky-top pt-0 pb-0"
         style="font-family: 'Nunito', sans-serif;"
         :style="isMobile() ? 'background-color: rgb(219,236,255);' : ''"
    >
      <div class="container-fluid">
        <a class="navbar-brand mb-0 h1" href="#" style="color: #003C82">
          <div class="row p-0 m-0">
            <div class="col">
              <img src="./assets/nora-logo.png" alt="Nora" width="120" class="d-inline-block align-text-bottom"
                   @click="showNoraInfoModal()">

            </div>
            <div v-show="isMobile()" class="col align-content-end ms-5">
              <router-link :to="isMobile() ? '/employee_screen_mobile' : '/employee_screen'" class="nav-link d-inline-block">
                <img src="@/assets/blue/dashboard-24.png" alt="" class="img-fluid ms-3 align-top"/>
              </router-link>
              <!--img src="@/assets/blue/person-24.png" alt="" class="img-fluid ms-3 align-top d-inline-block" @click="showClientListModal"/-->
              <router-link to="/calendar" class="nav-link d-inline-block">
                <img src="@/assets/blue/calendar-24.png" alt="" class="img-fluid ms-3 align-top"/>
              </router-link>
              <router-link to="/main" class="nav-link d-inline-block">
                <img src="@/assets/blue/dossier-24.png" alt="" class="img-fluid ms-3 align-top"/>
              </router-link>
              <div class="dropdown ms-2 d-inline-block">
                <button class="btn p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="@/assets/menu-vertical-24.png" class="img-fluid mt-0 me-0">
                </button>
                <ul class="dropdown-menu dropdown-menu-end" style="background-color: rgb(245,252,253);">
                  <li>
                    <a class="dropdown-item me-3 text-secondary" v-on:click="showClientListModal()">
                      <img src="@/assets/blue/person-24.png" class="img-fluid me-1">
                      Cliënten
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item me-3">
                      <router-link to="/financial" class="nav-link">
                        <img src="@/assets/blue/money-bag-24.png" alt="" class="img-fluid align-top d-inline-block"/>
                        <div class="text-secondary pt-1 ms-2 d-inline-block">
                          Factureren
                        </div>
                      </router-link>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item me-3">
                      <router-link to="/overview" class="nav-link">
                        <img src="@/assets/blue/chart-24.png" alt="" class="img-fluid align-top d-inline-block"/>
                        <div class="text-secondary pt-1 ms-2 d-inline-block">
                          Overzichten
                        </div>
                      </router-link>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item me-3">
                      <router-link to="/settings" class="nav-link">
                        <img src="@/assets/blue/gears-24.png" alt="" class="img-fluid align-top d-inline-block"/>
                        <div class="text-secondary pt-1 ms-2 d-inline-block">
                          Instellingen
                        </div>
                      </router-link>
                    </a>
                  </li>
                  <li>
                    <hr class="dropdown-divider ms-2 me-2">
                  </li>
                  <li>
                    <a class="dropdown-item me-3 text-secondary" v-on:click="handleLogout()">
                      <img src="@/assets/blue/logoff-24.png" class="img-fluid me-1">
                      Uitloggen
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a>
        <button v-show="!isMobile()" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-show="!isMobile()" class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto mb-0 mb-lg-0">
            <li class="nav-item fw-bold">
              <router-link :to="isMobile() ? '/employee_screen_mobile' : '/employee_screen'" class="nav-link">
                Dashboard
                <span v-show="numberOfNoraMessages > 0" class="badge rounded-pill badge-on-nav-item opacity-75 ">
                  {{ numberOfNoraMessages }}
                </span>
              </router-link>
            </li>
            <li class="nav-item fw-bold">
              <div class="nav-link" @click="showClientListModal">Cliënten</div>
            </li>
            <li class="nav-item fw-bold">
              <router-link to="/calendar" class="nav-link">Agenda</router-link>
            </li>
            <li class="nav-item fw-bold">
              <router-link to="/main" class="nav-link">Dossier</router-link>
            </li>
            <li class="nav-item fw-bold">
              <router-link to="/financial" class="nav-link">Financieel</router-link>
            </li>
            <li class="nav-item fw-bold">
              <router-link to="/overview" class="nav-link">Overzichten</router-link>
            </li>
            <li class="nav-item fw-bold">
              <router-link to="/settings" class="nav-link">Instellingen</router-link>
            </li>

          </ul>
          <span class="navbar-text align-content-end fst-italic me-3">
            Nora: software met een hart voor jou en je cliënt
          </span>
          <span v-if="isAuthenticated" class="navbar-text align-content-end fst-italic">
            <LogoutButton/>
          </span>

        </div>
      </div>
    </nav>

    <div class="container-fluid mt-3" style="font-family: 'Nunito';">
      <router-view/>
      <CustomerOverviewModal/>
      <NoraInfoModal/>
      <ClientModal/>
      <ClientListModal/>
      <ClientBasketModal/>
    </div>
    </body>


  </div>
</template>

<script>
import clientListModal from '@/services/modals/ClientListModalService';
import ClientListModal from '@/components/client/ClientListModal';
import ClientModal from '@/components/client/ClientModal';
import customerOverviewModalService from '@/services/modals/CustomerOverviewModalService';
import CustomerOverviewModal from '@/components/customers/CustomerOverviewModal';
import noraInfoModal from '@/services/modals/NoraInfoModalService';
import NoraInfoModal from '@/components/nora/NoraInfoModal';
import ClientBasketModal from '@/components/client/ClientBasketModal';
import LogoutButton from '@/components/auth/LogoutButton.vue';
import SettingsDataService from '@/services/SettingsDataService';
import store from '@/store';
import {logout} from "@/services/Auth";

export default {
  name: 'App',
  components: {
    LogoutButton,
    ClientBasketModal,
    CustomerOverviewModal,
    NoraInfoModal,
    ClientListModal,
    ClientModal
  },
  data() {
    return {
      clientListModal,
    }
  },
  methods: {
    showClientListModal() {
      clientListModal.show('')
    },
    showCustomersModal() {
      customerOverviewModalService.show()
    },
    showNoraInfoModal() {
      this.$store.state.mobileDevice = true
      noraInfoModal.show()
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    },
    async handleLogout() {
      await logout();

      window.location.href = process.env.VUE_APP_LOGOUT_URL;
    },
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.currentEmployeeId
    },
    numberOfNoraMessages() {
      return this.$store.state.countNoraMessages
    },
  },
  async mounted() {
    if (this.isAuthenticated) {
      store.state.mobileDevice = this.isMobile()
      await SettingsDataService.GetSettings()
      await store.dispatch('ClientsStore/getComplaints')
      await store.dispatch('ClientsStore/getEmployees')
      await store.dispatch('ClientsStore/getLocations')
      await store.dispatch('ClientsStore/getTasks')
      await store.dispatch('ClientsStore/getInstitute')
      await store.dispatch('ClientsStore/getThirdParties')
      await store.dispatch('ClientsStore/getExercises')
      await store.dispatch('updateTemplates')
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  height: 100%;
}

body {
  min-height: 100vh;
  background: url('./assets/steiger.jpg') center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

main {
  min-height: 100vh;
  font-family: 'Nunito';
}

.dialog-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 98;
  backdrop-filter: blur(5px);
}

.gradient {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.64) 0%, rgba(25, 159, 214, 0.5) 33%);
}

.gray {
  filter: grayscale(100%);
}

.circle-icon {
  background: rgb(249, 251, 255);
  border: 1px solid rgba(25, 159, 214, 0.3);
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(25, 159, 214, 0.3);
}

.yellow-circle-icon {
  background: rgb(255, 252, 245);
  border: 2px solid rgb(255, 236, 181);
  padding: 7px;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(255, 236, 181, 0.3);
}

.tab {
  background-color: white;
  border-color: lightgray;
  border-radius: 5px 5px 0px 0px;
  border-style: solid;
  border-width: 1px;
  border-bottom: 3px solid lightgray;
}

.tab:hover {
  background-color: rgba(55, 155, 237, 0.05);
}

.dropdown-item:hover {
  font-weight: bold;
  background-color: rgb(245,252,253);
}

.list-group-item:hover {
  background-color: rgb(243, 250, 253);
  cursor: pointer;
}

.selected-tab {
  background-color: rgba(25, 159, 214, 0.075);
  border-color: lightgray;
  border-radius: 5px 5px 0px 0px;
  border-style: solid;
  border-width: 1px;
  border-bottom: 3px solid #199fd6;
}

.read-only-tab {
  background-color: rgba(175, 175, 175, 0.08);
  border-color: lightgray;
  border-radius: 5px 5px 0px 0px;
  border-style: solid;
  border-width: 1px;
  border-bottom: 3px solid rgba(175, 175, 175, 0.4);
  color: rgba(175, 175, 175, 0.7);
}

.tab-content {
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  border-bottom: 1px solid lightgray;
  border-left: solid 1px lightgrey;
  border-right: solid 1px lightgray;
  min-height: 50px;
}

.nav-item {
  border-bottom: none 4px;
  padding-bottom: 4px;
}
.nav-item:hover {
  border-bottom: solid 4px rgba(55, 155, 237, 0.78);
  padding-bottom: 0px;
}

.selected-tab:hover {
  /*background-color: rgba(243, 242, 239, 0.64); */
  background-color: rgba(55, 155, 237, 0.05);
}

.badge-on-nav-item {
  position: relative;
  top: -10px;
  left: -2px;
  background-color: #003C82;
}

.badge-on-tab-item {
  position: relative;
  top: -2px;
  left: 0px;
  background-color: #12A0D7; /*#003C82;*/
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(55, 155, 237, 0.5);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.scrollbar::-webkit-scrollbar {
  width: 16px;
  border-radius: 0px 8px 8px 0px;
}

.scrollbar {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  background-color: #eeeeee;
  border-radius: 6px 6px 6px 6px;
}

html {
  font-size: 14px;
}
</style>
