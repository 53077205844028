// ./services/MailService

import {
    addDoc,
    collection,
} from "firebase/firestore";
import {db} from "@/firebase/init";
import OnlineRequestsDataService from "@/services/OnlineRequestsDataService";
import ClientDataService from "@/services/ClientDataService";
import InstituteDataService from "@/services/InstituteDataService";
import store from "@/store";
import TemplateDataService from "@/services/TemplateDataService";
import EmployeeDataService from "@/services/EmployeeDataService";
import ReportDataService from "@/services/ReportDataService";
import IcsService from "@/services/IcsService";

class MailService {
    async SendExerciseMail(filename, path, clientId, emailTemplateId) {
        const client = await ClientDataService.GetClient(clientId)
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)

        let templateText = ''
        let template = {
            text: '',
        }
        if (emailTemplateId !== '') {
            template = await TemplateDataService.GetTemplate(emailTemplateId)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            templateText = await TemplateDataService.ConvertExercisesEmailTemplate(templateText, clientId)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: client.email,
                from: institute.name + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: 'Oefeningen haptotherapie',
                    html: 'Beste ' + client.first_name + '<br><br>' +
                        'In de bijlage treft u de oefeningen aan.<br>' +
                        'Mocht je vragen of opmerkingen hebben, aarzel dan niet om contact op te nemen.<br><br>' +
                        'Met vriendelijke groeten,<br><br>',
                    attachments: [
                        {
                            filename: filename,
                            path: path,
                        },

                    ],
                }
            }
        } else {
            emailObj = {
                to: client.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                    attachments: [
                        {
                            filename: filename,
                            path: path,
                        },

                    ],
                },
            }
        }

        let mailId = ''

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                mailId = doc.id
                console.log('mail send ' + mailId)
            })
            .catch(e => {
                console.log(e);
            })

        return mailId
    }
    async SendInvoiceMail(filename, path, invoice, emailTemplateId) {
        const client = await ClientDataService.GetClient(invoice.client_id)
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)

        let templateText = ''
        let template = {
            text: '',
        }
        if (emailTemplateId !== '') {
            template = await TemplateDataService.GetTemplate(emailTemplateId)

            if (invoice.email_template_text !== '' && invoice.email_template_text !== undefined) {
                templateText = invoice.email_template_text
            } else {
                templateText = template.text
                templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
                templateText = templateText.replaceAll('</p>', '</div>')
                templateText = await TemplateDataService.ConvertInvoiceEmailTemplate(templateText, invoice)
            }
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: client.alternative_billing_address === true ? client.alt_email : client.email,
                from: institute.name + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: 'Factuur',
                    html: 'Beste ' + invoice.client_name + '<br><br>' +
                        'In de bijlage treft u de factuur voor uw behandeling(en) aan.<br>' +
                        'Mocht je vragen of opmerkingen hebben met betrekking tot deze factuur, aarzel dan niet om contact op te nemen.<br><br>' +
                        'Met vriendelijke groeten,<br><br>',
                    attachments: [
                        {
                            filename: filename,
                            path: path,
                        },

                    ],
                }
            }
        } else {
            emailObj = {
                to: client.alternative_billing_address === true ? client.alt_email : client.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                    attachments: [
                        {
                            filename: filename,
                            path: path,
                        },

                    ],
                },
            }
        }

        let mailId = ''

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                mailId = doc.id
                console.log('mail send ' + mailId)
            })
            .catch(e => {
                console.log(e);
            })

        return mailId
    }
    async SendAppointmentConfirmationEmail(sessionObj, clientObj, templateId) {

        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)

        const icsInfo = IcsService.createIcsFile(sessionObj, institute, store.state.settings.calendarSettings)

        let templateText = ''
        let template = {
            text: '',
        }
        const templates = await TemplateDataService.GetTemplates('appointment_confirmation')
        if (templates.length > 0) {
            template = await TemplateDataService.GetTemplate(templateId || templates[0].id)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            templateText = await TemplateDataService.ConvertAppointmentTemplate(templateText, sessionObj)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: clientObj.patientEmail,
                from: institute.name + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: 'Afspraak haptotherapie',
                    html: 'Beste ' + sessionObj.patient_name + ',<br><br>' +
                        'Hierbij bevestigen wij uw afspraak ' +
                        'op <b>' + new Date(sessionObj.startDate).toLocaleDateString() + '</b> ' +
                        'om <b>' + new Date(sessionObj.startDate).toLocaleTimeString().slice(0, 5) + '</b>.<br><br>' +
                        'Bent u verhinderd? Dan verzoeken wij u minimaal 24 uur voorafgaand aan de afspraak te annuleren.<br><br>' +
                        'Met vriendelijke groeten,<br><br>' +
                        sessionObj.employee_name,
                    // attachments: [
                    //     {
                    //         filename: 'afspraak.ics',
                    //         content: icsInfo,
                    //         contentType: 'text/calendar',
                    //     },
                    // ],
                },
            }
            if (store.state.settings.calendarSettings.send_ics) {
                emailObj.message.attachments = [
                    {
                        filename: 'afspraak.ics',
                        content: icsInfo,
                        contentType: 'text/calendar',
                    },
                ]
            }
        } else {
            emailObj = {
                to: clientObj.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                    // attachments: [
                    //     {
                    //         filename: 'afspraak.ics',
                    //         content: icsInfo,
                    //         contentType: 'text/calendar',
                    //     },
                    // ],
                },
            }
            if (store.state.settings.calendarSettings.send_ics) {
                emailObj.message.attachments = [
                    {
                        filename: 'afspraak.ics',
                        content: icsInfo,
                        contentType: 'text/calendar',
                    },
                ]
            }
        }

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                console.log('Email send ' + doc.id)
            })
            .catch(e => {
                console.log(e);
            });
    }
    async SendChangedAppointmentConfirmationEmail(sessionObj, clientObj, templateId) {

        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)

        let templateText = ''
        let template = {
            text: '',
        }
        const templates = await TemplateDataService.GetTemplates('changed_appointment_confirmation')
        if (templates.length > 0) {
            template = await TemplateDataService.GetTemplate(templates[0].id || templateId)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            templateText = await TemplateDataService.ConvertAppointmentTemplate(templateText, sessionObj)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: clientObj.email,
                from: institute.name + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: 'Gewijzigde afspraak haptotherapie',
                    html: 'Beste ' + sessionObj.patient_name + ',<br><br>' +
                        'Uw afspraak is gewijzigd ' +
                        'naar <b>' + new Date(sessionObj.startDate).toLocaleDateString() + '</b> ' +
                        'om <b>' + new Date(sessionObj.startDate).toLocaleTimeString().slice(0, 5) + '</b>.<br><br>' +
                        'Bent u verhinderd? Dan verzoeken wij u minimaal 24 uur voorafgaand aan de afspraak te annuleren.<br><br>' +
                        'Met vriendelijke groeten,<br><br>' +
                        sessionObj.employee_name,
                },
            }
        } else {
            emailObj = {
                to: clientObj.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                },
            }
        }

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                console.log('Email send ' + doc.id)
            })
            .catch(e => {
                console.log(e);
            });
    }
    async SendAppointmentCancelationEmail(sessionObj, clientObj, templateId) {
        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)

        let templateText = ''
        let template = {
            text: '',
        }
        const templates = await TemplateDataService.GetTemplates('appointment_cancelation')
        if (templates.length > 0) {
            template = await TemplateDataService.GetTemplate(templateId !== '' ? templateId : templates[0].id)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            templateText = await TemplateDataService.ConvertAppointmentTemplate(templateText, sessionObj)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: clientObj.email,
                from: institute.name + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: 'Geannuleerde afspraak haptotherapie',
                    html: 'Beste ' + sessionObj.patient_name + ',<br><br>' +
                        'Uw afspraak ' +
                        'van <b>' + new Date(sessionObj.startDate).toLocaleDateString() + '</b> ' +
                        'om <b>' + new Date(sessionObj.startDate).toLocaleTimeString().slice(0, 5) + '</b> ' +
                        'is geannuleerd.<br><br>' +
                        'Wilt u toch een afspraak? Neem dan contact op.<br><br>' +
                        'Met vriendelijke groeten,<br><br>' +
                        sessionObj.employee_name,
                },
            }
        } else {
            emailObj = {
                to: clientObj.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                },
            }
        }

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                console.log('Email send ' + doc.id)
            })
            .catch(e => {
                console.log(e);
            });
    }
    async SendOnlineRequestMail(onlineRequestId, client) {

        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)

        let templateText = ''
        let template = {
            text: '',
        }
        const templates = await TemplateDataService.GetTemplates('clinimetrics_request')
        if (templates.length > 0) {
            template = await TemplateDataService.GetTemplate(templates[0].id)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            templateText = await TemplateDataService.ConvertRequestTemplate(templateText, onlineRequestId, client, employee)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: client.email,
                from: institute.name + '<info@nora-software.nl>',
                message: {
                    subject: 'Er staat een vragenlijst klaar',
                    html: 'Geachte heer/mevrouw,<br><br>' +
                        'Hierbij het verzoek om één of meerdere vragenlijsten in te vullen.<br>' +
                        '<a href="' + window.location.origin + '/client_clinimetrics_screen?id=' + onlineRequestId + '">' +
                        'Klik hier om het verzoek te openen</a><br><br>' +
                        'Met vriendelijke groeten,<br><br>' +
                        employee.first_name + ' ' + employee.last_name,
                }
            }
        } else {
            emailObj = {
                to: client.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                },
            }
        }

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                const emailId = doc.id

                console.log('Clinimetric request send ' + emailId)

                const onlineRequestObj = {
                    mail_id: emailId
                }
                await OnlineRequestsDataService.UpdateOnlineRequest(onlineRequestObj, onlineRequestId)
            })
            .catch(e => {
                console.log(e);
            });
    }
    async SendSecureMailMail(secureMailId, reportId, receiver) {

        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)

        let templateText = ''
        let template = {
            text: '',
        }

        const templates = await TemplateDataService.GetTemplates('secure_message_mail')
        if (templates.length > 0) {
            template = await TemplateDataService.GetTemplate(templates[0].id)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            templateText = await TemplateDataService.ConvertSecureMessageTemplate(templateText, secureMailId, receiver)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: receiver.email,
                from: institute.name + '<info@nora-software.nl>',
                message: {
                    subject: 'Beveiligd bericht van ' + institute.name,
                    html: 'Geachte heer/mevrouw,<br><br>' +
                        institute.name + ' heeft u een beveiligd bericht verstuurd.<br>' +
                        '<a href="' + window.location.origin + '/mail?id=' + secureMailId + '">' +
                        'Klik hier om het bericht te openen</a><br><br>' +
                        'Met vriendelijke groeten,<br><br>' +
                        employee.first_name + ' ' + employee.last_name,
                }
            }
        } else {
            emailObj = {
                to: receiver.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                },
            }
        }

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                const emailId = doc.id

                console.log('Secure mail send ' + emailId)

                const reportObj = {
                    secure_mail_id: secureMailId
                }
                await ReportDataService.UpdateReport(reportId, reportObj)
            })
            .catch(e => {
                console.log(e);
            });
    }
    async SendSecureMailAccessCode(accessCode, emailObj) {

        emailObj.message.html = emailObj.message.html.replaceAll('{{ access code }}', accessCode)

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .catch(e => {
                console.log(e);
            });
    }
    async GetSecureMailAccessCodeObject(receiver) {

        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)

        let templateText = ''
        let template = {
            text: '',
        }

        const templates = await TemplateDataService.GetTemplates('secure_message_access_code_mail')
        if (templates.length > 0) {
            template = await TemplateDataService.GetTemplate(templates[0].id)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            templateText = await TemplateDataService.ConvertSecureMessageTemplate(templateText, '', receiver)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: receiver.email,
                from: institute.name + '<info@nora-software.nl>',
                message: {
                    subject: 'Toegangscode van ' + institute.name,
                    html: 'Geachte heer/mevrouw,<br><br>' +
                        institute.name + ' heeft u een beveiligd bericht verstuurd.<br>' +
                        'De toegangscode is: <b>{{ access code }}</b><br><br>' +
                        'Met vriendelijke groeten,<br><br>' +
                        employee.first_name + ' ' + employee.last_name,
                }
            }
        } else {
            emailObj = {
                to: receiver.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                },
            }
        }

        return emailObj
    }
    async SendTestMail() {
        const emailObj = {
            to: 'arnold@nora-software.nl',
            from: 'Praktijk voor Haptotherapie <info@nora-software.nl>',
            message: {
                subject: 'Er staat een vragenlijst klaar',
                html: 'Blah die blah',
                attachments: [
                    {
                        filename: 'bart.png',
                        path: 'https://firebasestorage.googleapis.com/v0/b/test-firebase-d1e96.appspot.com/o/bart.png?alt=media&token=41d3889d-f047-4233-a2a0-0f4f113d3bd1',
                    },

                ],
            }
        }

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                console.log('Mail klaargezet: ' + doc.id)
            })
            .catch(e => {
                console.log(e);
            })

    }
    async SendClientPortalRegistrationMail(registrationcode, client) {

        const institute = await InstituteDataService.GetInstitute(store.state.currentInstituteId)
        const employee = await EmployeeDataService.GetEmployee(store.state.currentEmployeeId)

        let templateText = ''
        let template = {
            text: '',
        }
        const templates = await TemplateDataService.GetTemplates('client_portal_registration')
        if (templates.length > 0) {
            template = await TemplateDataService.GetTemplate(templates[0].id)

            templateText = template.text
            templateText = templateText.replaceAll('<p>', '<div style="display: block;">')
            templateText = templateText.replaceAll('</p>', '</div>')
            //templateText = await TemplateDataService.ConvertRequestTemplate(templateText, onlineRequestId, client, employee)
        }

        let emailObj = ''
        if (templateText === '') {
            emailObj = {
                to: client.email,
                from: institute.name + '<info@nora-software.nl>',
                message: {
                    subject: 'Uitnodiging voor Nora cliëntportaal',
                    html: 'Geachte heer/mevrouw,<br><br>' +
                        'Wij nodigen u uit om toegang te krijgen tot het Nora cliëntportaal. Dit portaal biedt u ' +
                        'een eenvoudige manier om uw zorgdoelen te monitoren, vragenlijsten in te vullen, ' +
                        'uw facturen in te zien en veel meer.<br><br>' +
                        'Om u aan te melden, volgt u de onderstaande stappen:<br><br>' +
                        '1. Klik op deze link om naar het cliëntportaal te gaan: ' +
                        '<a href="https://portal.nora-software.nl">Link</a><br>' +
                        '2. Voer uw unieke registratiecode in: <b>' + registrationcode + '</b>.<br>' +
                        '3. Volg de verdere instructies om uw account aan te maken.<br><br>' +
                        'Wij kijken ernaar uit u te verwelkomen op ons cliëntportaal.<br><br>' +
                        'Met vriendelijke groeten,<br><br>' +
                        employee.first_name + ' ' + employee.last_name,
                }
            }
        } else {
            emailObj = {
                to: client.email,
                from: (template.from ? template.from : institute.name) + '<info@nora-software.nl>',
                replyTo: institute.email,
                message: {
                    subject: template.subject ? template.subject : template.description,
                    html: templateText,
                },
            }
        }

        const colRef = collection(db, 'mail');

        await addDoc(colRef, emailObj)
            .then(async (doc) => {
                const emailId = doc.id

                console.log('Client portal request send ' + emailId)
            })
            .catch(e => {
                console.log(e);
            });
    }
}

export default new MailService();