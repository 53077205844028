// ./services/ClinimetricsDataService

import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    where,
    query,
    orderBy, getDoc
} from "firebase/firestore";
import {db} from "@/firebase/init";
import store from "@/store";
import TimelineDataService from "@/services/TimelineDataService";
import questionnaire from "@/clinimetrics/hws_v1";
import questionnaire_4dkl_v1 from "@/clinimetrics/4dkl_v1";
import questionnaire_csi_v1 from "@/clinimetrics/csi_v1";
import questionnaire_psg_v1 from "@/clinimetrics/psg_v1";
import questionnaire_smsk_v1 from "@/clinimetrics/smsk_v1";
import questionnaire_qbpds_v1 from "@/clinimetrics/qbpds_v1";
import questionnaire_ysq_v1 from "@/clinimetrics/ysq-s3_v1";
import DossierMessagesDataService from "@/services/DossierMessagesDataService";
import questionnaire_gpe_dv_v1 from "@/clinimetrics/gpe-dv_v1";
import questionnaire_nprs_v1 from "@/clinimetrics/nprs_v1";
import questionnaire_hoos_ps_v1 from "@/clinimetrics/hoos_ps_v1";
import questionnaire_koos_ps_v1 from "@/clinimetrics/koos_ps_v1";
import questionnaire_q_dash_dlv_v1 from "@/clinimetrics/q_dash_dlv_v1";
import questionnaire_bps_v1 from "@/clinimetrics/bps_v1";

class ClinimetricsDataService {
    async GetClinimetricsFromDossier(dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                let clinimetricItems = []
                const clinimetricsQuery = await getDocs(query(collection(db, store.state.customer + 'clinimetrics'), where('dossier_id', '==', dossierId), orderBy('date')));
                clinimetricsQuery.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;

                    clinimetricItems.push(data);
                })

                resolve(clinimetricItems)

            } catch(e) {
                reject(e)
            }
        })
    }
    async GetClinimetrics(clinimetricsId) {
        if (clinimetricsId === '') {
            return
        }
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const docSnap = await getDoc(doc(db, store.state.customer + 'clinimetrics', clinimetricsId))
                resolve(docSnap.data())
            } catch(e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async AddClinimetrics(dataObj) {
        const colRef = collection(db, store.state.customer + 'clinimetrics');

        let clinimetricsId = ''
        await addDoc(colRef, dataObj)
            .then((docRef) => {
                clinimetricsId = docRef.id
            })
            .catch(e => {
                console.log(e)
                clinimetricsId = ''
            });

        const timelineObject = {
            'patient_id': dataObj.client_id,
            'dossier_id': dataObj.dossier_id,
            'clinimetrics_id': clinimetricsId,
            'date': new Date(Date.now()).getTime(),
            'timeline_type': 'clinimetrics',
            'short': dataObj.short,
        }
        await TimelineDataService.AddTimelineItem(timelineObject)

        return clinimetricsId
    }
    /*
    async UpdateTimelineItem(timelineItemId, dataObj) {
        const docRef = doc(db, store.state.customer + 'timeline', timelineItemId);

        await setDoc(docRef, dataObj, { merge: true })
            .then(() => {
                console.log('Timeline item updated')
            })
            .catch((e) => {
                console.log(e);
            });
    }
    */
    async RemoveClinimetrics(id) {
        console.log(id)

        // Reset corresponding dossier message data
        const clinimetricsData = await this.GetClinimetrics(id)
        console.log(clinimetricsData)
        try {
            await DossierMessagesDataService.UpdateClinimetricMoment(clinimetricsData.dossier_id, clinimetricsData, 'open')
        } catch(e) {
            console.log(e)
        }

        // Delete clinimetrics
        const docRef = doc(db, store.state.customer + 'clinimetrics', id)

        await deleteDoc(docRef)
            .then(() => {
                console.log('Clinimetric item deleted')
            })
            .catch((e) => {
                console.log(e)
            });
    }
    GetClinimetricsData(clinimetricsId) {
        switch (clinimetricsId) {
            case 'hws':
            case 'HWS_v1':
                return questionnaire
            case '4dkl':
            case '4DKL_v1':
                return questionnaire_4dkl_v1
            case 'csi':
            case 'CSI_v1':
                return questionnaire_csi_v1
            case 'GPE-DV_v1':
                return questionnaire_gpe_dv_v1
            case 'HOOS_PS_v1':
                return questionnaire_hoos_ps_v1
            case 'KOOS_PS_v1':
                return questionnaire_koos_ps_v1
            case 'NPRS_v1':
                return questionnaire_nprs_v1
            case 'psg':
            case 'PSG_v1':
                return questionnaire_psg_v1
            case 'QBPDS_v1':
                return questionnaire_qbpds_v1
            case 'Q_DASH_DLV_v1':
                return questionnaire_q_dash_dlv_v1
            case 'SMSK_v1':
                return questionnaire_smsk_v1
            case 'YSQ-S3_v1':
                return questionnaire_ysq_v1
            case 'BPS_v1':
                return questionnaire_bps_v1
            default:
                return null
        }
    }
    GetClinimetricsDescription(clinimetricsId) {
        switch (clinimetricsId) {
            case '4DKL_v1':
                return 'Vier Dimensionale KlachtenLijst (4DKL)'
            case 'CSI_v1':
                return 'Central Sensitization Inventory (CSI)'
            case 'GPE-DV_v1':
                return 'Global Perceived Effect (GPE-DV)'
            case 'NPRS_v1':
                return 'Numeric Pain Rating Scale (NPRS)'
            case 'HWS_v1':
                return 'Haptotherapeutische Welbevinden Schaal (HWS)'
            case 'HOOS_PS_v1':
                return 'HOOS (HOOS-PS)'
            case 'KOOS_PS_v1':
                return 'KOOS (KOOS-PS)'
            case 'PSG_v1':
                return 'Patiënt Specifieke Goal-setting methode'
            case 'QBPDS_v1':
                return 'Quebec Back Pain Disability Scale (QBPDS)'
            case 'Q_DASH_DLV_v1':
                return 'Quick dash (Q-DASH-DLV)'
            case 'SMSK_v1':
                return 'STarT MSK tool'
            case 'veldman':
                return 'Model Veldman'
            case 'BMI_v1':
                return 'Body Mass Index (BMI)'
            case 'BPS_v1':
                return 'Boundary Protection Scale (BPS)'
            default:
                return ''
        }
    }
}

export default new ClinimetricsDataService();